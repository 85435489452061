import { combineReducers, configureStore } from "@reduxjs/toolkit";
import alertReducer from "state/reducers/alert";
import loadingReducer from "state/reducers/loading";
import userReducer from "state/reducers/user";
import cameraReducer from "state/reducers/camera";

const rootReducer = combineReducers({
  loading: loadingReducer,
  alert: alertReducer,
  user: userReducer,
  camera: cameraReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
