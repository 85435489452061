import Typography from "@mui/material/Typography";
import { Variant } from "@mui/material/styles/createTypography";
import React from "react";

interface Props {
  title: string;
  variant?: Variant;
  fontSize?: number | string | undefined;
  fontWeight?: number | string | undefined;
  color?: string;
  lineHeight?: number;
}

const SectionTitle: React.FC<Props> = ({
  title,
  variant,
  fontSize,
  fontWeight,
  color,
  lineHeight,
}) => {
  return (
    <Typography
      variant={variant || "h2"}
      className="tw-capitalize"
      color={color || "black"}
      fontWeight={fontWeight || "bold"}
      lineHeight={lineHeight || 1.3}
      fontSize={{
        xs: 18,
        sm: 20,
        md: fontSize || 24,
      }}
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );
};

export default SectionTitle;
