import { OtpVerificationModel } from "_models/data/auth/data.otpVerification.model";

export const validateOtpVerification = (DATA: OtpVerificationModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.OTP) {
    valid = false;
    errors.push({
      name: "OTP",
      error: "Enter OTP.",
    });
  }

  return { valid, errors };
};
