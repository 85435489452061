import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";

interface Props {
  code: string;
  handleChange: (newValue: string) => void;
  maximumLength?: number;
}

const OtpInput: React.FC<Props> = ({
  code,
  handleChange,
  maximumLength = 6,
}) => {
  const { palette } = useTheme();

  const RE_DIGIT = new RegExp(/^\d+$/);

  const valueItems = useMemo(() => {
    const valueArray = code.split("");
    const items: string[] = [];

    for (let i = 0; i < maximumLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [code, maximumLength]);

  // Focus Next Input
  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling = target.nextElementSibling as HTMLElement;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  // Focus Prev Input
  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling = target.previousElementSibling as HTMLElement;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  // handle change
  const inputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    const nextInputEl = target.nextElementSibling as HTMLInputElement;
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== "") {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : " ";

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        code.substring(0, idx) + targetValue + code.substring(idx + 1);

      handleChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === maximumLength) {
      // on pasting
      handleChange(targetValue);
      target.blur();
    }
  };

  // handle inputs on arrow keys & backspace key
  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;

    if (key === "ArrowRight" || key === "ArrowDown") {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    target.setSelectionRange(0, targetValue.length);

    if (key !== "Backspace" || targetValue !== "") {
      return;
    }

    focusToPrevInput(target);
  };

  // Focus input
  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    const prevInputEl = target.previousElementSibling as HTMLInputElement;

    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <Box
      pt={5}
      className="tw-relative tw-flex tw-justify-center tw-items-center tw-w-[100%] tw-max-w-[360px] tw-gap-[10px] tw-m-auto"
    >
      {valueItems?.map((digit, idx) => (
        <input
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          autoFocus
          pattern="\d{1}"
          maxLength={maximumLength}
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
          className={`tw-w-[100%] tw-h-[48px] tw-rounded-[4px] tw-text-center tw-text-[32px] tw-font-regular tw-outline-0 tw-border focus:tw-border-[${palette.primary.main}] tw-bg-transparent`}
        />
      ))}
    </Box>
  );
};
export default OtpInput;
