import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import SimCardChargesTableInterface from "_interfaces/sim-card-charges/simCardChargesTable";
import { AppStatusCode } from "config/appStatusCode";
import { GetSimCardCharges } from "functions/http-requests/sim-card-charges";
import CustomDrawer from "parts/customDialog/customDrawer";
import { useEffect, useState } from "react";
import SimCardChargesForm from "./simCardChargesForm";
import SimCardChargesTable from "./simCardChargesTable";

const SimCardChargesLayout = () => {
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [data, setData] = useState<SimCardChargesTableInterface["data"]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");

  const toggleForm = () => {
    if (editId) return setEditId("");
    setOpenForm(!openForm);
  };

  const handleRefresh = () => setRefresh(!refresh);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);
      GetSimCardCharges()
        .then((res) => {
          const data = res?.data;

          if (data?.statusCode === AppStatusCode.api_success) {
            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              simCharges: item?.simCharges || "",
              serviceCharges: item?.serviceCharges || "",
              vatPercent: item?.vatPercent || "",
              MSISDNCategory: item?.MSISDNCategory || "",
              subTotal: item?.subTotal || "",
            }));
            setData(DATA);
          } else {
            setData([]);
          }
        })
        .catch(() => {
          setData([]);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [refresh]);

  return (
    <>
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item xs={12} sm={6}>
          <Box className="tw-flex tw-justify-end tw-items-center">
            <Button
              onClick={toggleForm}
              variant="outlined"
              startIcon={<AddIcon />}
              disableElevation
            >
              <Box component="span" className="tw-line-clamp-1">
                Add New Charges
              </Box>
            </Button>
          </Box>
        </Grid>
      </Grid>

      <SimCardChargesTable
        data={data}
        loading={dataLoading}
        setEditId={setEditId}
      />

      <CustomDrawer
        open={openForm || editId ? true : false}
        onClose={toggleForm}
        title={editId ? "Edit  Charges Details" : "Add New Charges"}
      >
        <SimCardChargesForm
          handleRefresh={handleRefresh}
          onClose={toggleForm}
          editId={editId}
        />
      </CustomDrawer>
    </>
  );
};

export default SimCardChargesLayout;
