import {
  GetAllSimProps,
  UploadSimCartonProps,
} from "_interfaces/functions/http-requests/simManagement";
import {
  GET_ALL_SIM,
  GET_ALL_SIM_COUNT,
  GET_SIM_BY_ICCID,
  GET_SIM_BY_ID,
  GET_SIM_STOCK_COUNT,
  UPLOAD_SIM_CARTON,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const UploadSimCarton = ({ DATA }: UploadSimCartonProps) => {
  return HTTP({
    Method: "POST",
    Url: UPLOAD_SIM_CARTON,
    Data: DATA,
  });
};

export const GetAllSim = ({ DATA }: GetAllSimProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_ALL_SIM,
    Data: DATA,
  });
};

export const GetSimById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_SIM_BY_ID(ID),
  });
};

export const GetSimByIccid = (ICCID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_SIM_BY_ICCID(ICCID),
  });
};

export const GetAllSimCount = () => {
  return HTTP({
    Method: "GET",
    Url: GET_ALL_SIM_COUNT,
  });
};
export const GetSimStockCount = () => {
  return HTTP({
    Method: "GET",
    Url: GET_SIM_STOCK_COUNT,
  });
};
