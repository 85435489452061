import DetailsBox from "parts/table/detailsBox";
import React from "react";
import CustomerManagementDetailsInterface from "_interfaces/customer-management/details";

interface Props {
  data: CustomerManagementDetailsInterface["agentInfo"] | null;
  fullWidth?: boolean;
  noPadding?: boolean;
  noBorder?: boolean;
}

const AgentInformationTable: React.FC<Props> = ({
  data,
  fullWidth,
  noPadding,
  noBorder,
}) => {
  const rows: {
    field: keyof NonNullable<CustomerManagementDetailsInterface["agentInfo"]>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
    { field: "firstName", headerName: "First Name" },
    { field: "middleName", headerName: "Middle Name" },
    { field: "lastName", headerName: "Last Name" },
    { field: "ownerName", headerName: "Owner Name" },
  ];

  return (
    <>
      {data?.id ? (
        <DetailsBox
          data={data}
          rows={rows}
          fullWidth={fullWidth}
          noPadding={noPadding}
          noBorder={noBorder}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AgentInformationTable;
