import MainContainer from "parts/mainContainer";
import InterSwitchEValueLayout from "component/inter-switch-e-value";

const InterSwitchEValue = () => {
    return (
      <MainContainer>
        <InterSwitchEValueLayout />
      </MainContainer>
    );
  };
  
  export default InterSwitchEValue;
  