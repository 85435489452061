import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import {
  CreateInventoryUsersProps,
  UpdateInventoryUsersProps,
} from "_interfaces/functions/http-requests/inventory-users";
import { InventoryUsersModel } from "_models/data/inventory-users/data.inventory-users.model";
import { InventoryUsersErrorModel } from "_models/error/inventory-users/error.inventory-users.model";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import Password from "component/_common/forms/password";
import Text from "component/_common/forms/text";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import {
  CreateInventoryUsers,
  GetInventoryUsersById,
  UpdateInventoryUsers,
} from "functions/http-requests/inventory-users";
import {
  isValidString,
  renderInvalidStringErrorText,
} from "functions/validators";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import { validateInventoryUsers } from "./formValidator";
import axios from "axios";

interface InputListProps extends FormInputProps {
  name: "name" | "email" | "phone" | "role" | "password" | "confirmPassword";
}

interface Props {
  editId?: string;
  handleRefresh: () => void;
  onClose: () => void;
}

const InventoryUsersForm: React.FC<Props> = ({
  editId,
  handleRefresh,
  onClose,
}) => {
  const Dispatch = useDispatch();

  const [state, setState] = useState<InventoryUsersModel>(
    new InventoryUsersModel()
  );
  const [errors, setErrors] = useState<InventoryUsersErrorModel>(
    new InventoryUsersErrorModel()
  );

  const [editRefresh, setEditRefresh] = useState<boolean>(false);

  const handleEditRefresh = (REFRESH: boolean) => setEditRefresh(REFRESH);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      if (name === "name" && value) {
        if (!isValidString(value)) {
          return setErrors((prev) => ({
            ...prev,
            [name]: renderInvalidStringErrorText(),
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "",
          }));
        }
      }
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleBlur = (name: string) => {
    if (name === "name") {
      if (
        state[name] &&
        !isValidString(state[name as keyof InventoryUsersModel] as string)
      ) {
        setErrors((prev) => ({
          ...prev,
          [name]: renderInvalidStringErrorText(),
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }
  };

  const handleCreate = ({ DATA }: CreateInventoryUsersProps) => {
    Dispatch(toggleLoading(true));
    CreateInventoryUsers({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_created ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleUpdate = ({ DATA }: UpdateInventoryUsersProps) => {
    Dispatch(toggleLoading(true));
    UpdateInventoryUsers({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_updated ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (): void => {
    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateInventoryUsers(state, editId);

    if (ValidateStep?.valid) {
      let DATA: CreateInventoryUsersProps["DATA"] = {
        name: state.name || "",
        email: state.email || "",
        phone: state.phone || "",
        role: state.role || "",
      };

      if (editId && state?.id) {
        let UPDATE_DATA: UpdateInventoryUsersProps["DATA"] = {
          ...DATA,
          id: state.id,
        };
        handleUpdate({ DATA: UPDATE_DATA });
      } else {
        DATA = {
          ...DATA,
          password: state.password,
        };
        handleCreate({ DATA });
      }
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  // GET BY ID
  const handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetInventoryUsersById(ID)
      .then((res) => {
        const data = res?.data;
        if (
          data?.statusCode === AppStatusCode.api_success ||
          data?.statusCode === AppStatusCode.api_updated
        ) {
          let DATA: any = data?.data;
          if (editRefresh) {
            setState((prev) => ({
              ...prev,
              isActive: DATA.isActive,
            }));
            handleEditRefresh(false);
          } else {
            setState({
              id: DATA?._id || "",
              name: DATA?.name || "",
              email: DATA?.email || "",
              phone: DATA?.phone || "",
              role: DATA?.role || "",
              password: "",
              confirmPassword: "",
            });
          }
        } else {
          Dispatch(setAlert({ type: "error", message: data?.message }));
          setState(new InventoryUsersModel());
          onClose();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  useEffect(() => {
    if (editId) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(editId);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [editId]);

  useEffect(() => {
    if (editId && editRefresh) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(editId);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [editId, editRefresh]);

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "text",
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        grid: 12,
        order: 1,
        enabled: true,
      },
      {
        type: "email",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        grid: 6,
        enabled: true,
      },
      {
        type: "number",
        name: "phone",
        label: "Phone",
        placeholder: "Enter Phone",
        grid: 6,
        enabled: true,
      },
      {
        type: "autoComplete",
        name: "role",
        label: "Role",
        placeholder: "Select Role",
        options: [
          {
            id: "inventory_manager",
            value: "inventory_manager",
            title: "Inventory Manager",
            isActive: true,
          },
          {
            id: "inventory_staff",
            value: "inventory_staff",
            title: "Inventory Staff",
            isActive: true,
          },
        ],
        multiple: false,
        enabled: true,
        grid: 12,
      },
      {
        type: "password",
        name: "password",
        label: "Password",
        placeholder: "Enter Password",
        autoComplete: "new-password",
        grid: 6,
        enabled: editId ? false : true,
      },
      {
        type: "password",
        name: "confirmPassword",
        label: "Repeat Password",
        placeholder: "Enter Password again",
        autoComplete: "new-password",
        grid: 6,
        enabled: editId ? false : true,
      },
    ],
    [editId]
  );

  return (
    <>
      <Box id="crete_edit_role_form">
        <Grid container rowSpacing={3} columnSpacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                grid,
                autoComplete,
                disabled,
                enabled,
                options,
                multiple,
              },
              index
            ) => (
              <Fragment key={index}>
                {enabled ? (
                  <>
                    {type === "text" ||
                    type === "email" ||
                    type === "number" ? (
                      <Grid item xs={12} md={6} lg={grid || 6}>
                        <Text
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof InventoryUsersModel]}
                          errorText={
                            errors[name as keyof InventoryUsersErrorModel]
                          }
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={() => handleBlur(name)}
                          disabled={disabled}
                        />
                      </Grid>
                    ) : type === "password" ? (
                      <Grid item xs={12} md={6} lg={grid || 6}>
                        <Password
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof InventoryUsersModel]}
                          errorText={
                            errors[name as keyof InventoryUsersErrorModel]
                          }
                          onChange={handleChange}
                          onFocus={handleFocus}
                          disabled={disabled}
                          isHideShow
                        />
                      </Grid>
                    ) : type === "autoComplete" ? (
                      <Grid item xs={12} md={6} lg={grid || 6}>
                        <InputAutoComplete
                          name={name}
                          options={options || []}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options.length &&
                            state?.[name as keyof InventoryUsersModel]
                          }
                          errorText={
                            errors[name as keyof InventoryUsersErrorModel]
                          }
                          onFocus={handleFocus}
                          multiple={multiple}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Fragment>
            )
          )}

          <Grid item xs={12} order={7}>
            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
            >
              {editId ? "Save Changes" : "Add New User"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InventoryUsersForm;
