import { LoginModel } from "_models/data/auth/data.login.model";

export const validateLogin = (DATA: LoginModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  // if (!DATA.loginAs) {
  //   valid = false;
  //   errors.push({
  //     name: "loginAs",
  //     error: "Select Login As",
  //   });
  // }

  if (!DATA.phone) {
    valid = false;
    errors.push({
      name: "phone",
      error: "Phone is required.",
    });
  }

  if (!DATA.password) {
    valid = false;
    errors.push({
      name: "password",
      error: "Password is required.",
    });
  }

  return { valid, errors };
};
