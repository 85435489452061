import { InventoryUsersModel } from "_models/data/inventory-users/data.inventory-users.model";
import {
  isValidEmail,
  isValidPassword,
  isValidPhone,
} from "functions/validators";

export const validateInventoryUsers = (
  DATA: InventoryUsersModel,
  editId?: string | number | null
) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.name) {
    valid = false;
    errors.push({
      name: "name",
      error: "Name is required.",
    });
  }

  if (!DATA?.email) {
    valid = false;
    errors.push({
      name: "email",
      error: "Email is required.",
    });
  }

  if (DATA?.email && !isValidEmail(DATA?.email)) {
    valid = false;
    errors.push({
      name: "email",
      error: "Invalid email.",
    });
  }

  if (!DATA?.phone) {
    valid = false;
    errors.push({
      name: "phone",
      error: "Phone is required.",
    });
  }

  if (DATA?.phone && !isValidPhone(DATA?.phone)) {
    valid = false;
    errors.push({
      name: "phone",
      error: "Invalid Phone.",
    });
  }

  if (!DATA?.role) {
    valid = false;
    errors.push({
      name: "role",
      error: "Role is required.",
    });
  }

  if (!editId) {
    if (!DATA?.password) {
      valid = false;
      errors.push({
        name: "password",
        error: "Password is required.",
      });
    }

    if (!DATA?.confirmPassword) {
      valid = false;
      errors.push({
        name: "confirmPassword",
        error: "Enter password again",
      });
    }
  }

  if (DATA?.password && !isValidPassword(DATA.password)) {
    valid = false;
    errors.push({
      name: "password",
      error:
        "Password must contain a minimum of eight letters containing at least one small letter, capital letter, number and special character.",
    });
  }

  if (DATA?.password && !DATA?.confirmPassword) {
    valid = false;
    errors.push({
      name: "confirmPassword",
      error: "Enter password again",
    });
  }

  if (DATA?.confirmPassword && DATA.password !== DATA.confirmPassword) {
    valid = false;
    errors.push({
      name: "confirmPassword",
      error: "Password not matched.",
    });
  }

  return { valid, errors };
};
