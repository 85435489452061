import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { SimStockCountInterface } from "_interfaces/sim-management/details";
import CustomPieChart from "parts/charts/pieChart";
import React from "react";

interface Props {
  DATA: SimStockCountInterface
}

const SimChart: React.FC<Props> = ({ DATA }) => {
  const { palette } = useTheme();

  const data = [
    { name: "Registered SIM", value: DATA?.registerCount, color: palette.primary.main },
    { name: "Distributed", value: DATA?.distributorCount, color: palette.common.black },
    { name: "In Stock", value: DATA?.inStockCount, color: palette.success.main },
  ];

  return (
    <Box
      p={2}
      className="tw-border tw-rounded-md tw-h-[100%] tw-min-h-[200px] tw-flex"
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={7}>
          <Box className="tw-h-[200px]">
            <CustomPieChart data={data} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box>
            <ChartText
              value={DATA?.registerCount}
              title="Registered SIM"
              color={palette.primary.main}
            />
          </Box>
          <Box pt={3}>
            <ChartText value={DATA?.distributorCount} title="Distributed" />
          </Box>
          <Box pt={3}>
            <ChartText
              value={DATA?.inStockCount}
              title="In Stock"
              color={palette.success.main}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

interface ChartTextProps {
  value: string | number;
  title: string;
  color?: string;
}
const ChartText: React.FC<ChartTextProps> = ({ value, title, color }) => {
  const { palette } = useTheme();
  return (
    <>
      <Box>
        <Typography fontSize={36} fontWeight={700} color="black">
          {value}
        </Typography>
        <Box
          sx={{
            position: "relative",
            paddingLeft: "12px",
            "&::before": {
              content: `""`,
              position: "absolute",
              left: 0,
              top: 0,
              width: "6px",
              height: "100%",
              backgroundColor: color || palette.common.black,
            },
          }}
        >
          <Typography fontSize={12} lineHeight={1}>
            {title}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SimChart;
