import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import {
  GetCustomerById,
  GetCustomerDoc,
} from "functions/http-requests/customer-management";
import { RouteConstant } from "navigation/constant";
import CustomDrawer from "parts/customDialog/customDrawer";
import { IsActiveCell } from "parts/table/customCell";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import CustomerManagementDetailsInterface, {
  CustomerDocInterface,
} from "_interfaces/customer-management/details";
import AgentInformationTable from "./agentInformationTable";
import CustomerDocs from "./customerDocs";
import CustomerInformationTable from "./customerInformationTable";
import DocumentInformationTable from "./documentInformationTable";

interface Props {
  id: string;
}

const CustomerManagementDetailsLayout: React.FC<Props> = ({ id }) => {
  const Dispatch = useDispatch();

  const [activeItem, setActiveItem] = useState("customerInfo");

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [state, setState] = useState<CustomerManagementDetailsInterface | null>(
    null
  );
  const [customerDocs, setCustomerDocs] = useState<CustomerDocInterface | null>(
    null
  );

  const [openCustomerDoc, setOpenCustomerDoc] = useState<boolean>(false);

  const toggleCustomerDoc = () => {
    setOpenCustomerDoc(!openCustomerDoc);
  };

  let handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetCustomerById(ID)
      .then((res) => {
        const data = res?.data;
        if (
          data?.statusCode === AppStatusCode.api_success ||
          data?.statusCode === AppStatusCode.api_updated
        ) {
          const DATA: any = data?.data;
          const DOCUMENT: any = DATA?.doc_Details;
          const AGENT: any = DATA?.agent_details;
          const KYC: any = DATA?.kyc_Details;

          setState((prev) => ({
            ...prev,
            id: DATA?._id,
            customerInfo: {
              id: DATA?._id,
              MSISDN: DATA?.MSISDN,
              MSISDN_id: DATA?.MSISDN_id,
              ICCID: DATA?.ICCID,
              customerType: DATA?.customerType,
              customerID: DATA?.customerID,
              serviceType: DATA?.serviceType,
              identityType: DATA?.identityType,
              alterNativeNumber: DATA?.alterNativeNumber,
              emailAddress: DATA?.emailAddress,
              nameAsDocument: DATA?.nameAsDocument,
              gender: DATA?.gender,
              dateOfBirth: DATA?.dateOfBirth,
              numberCategoryType: DATA?.numberCategoryType,
              SIM_type: DATA?.SIM_type,
              status: DATA?.status,
            },
            documentInfo: {
              id: DOCUMENT?._id,
              nameAsDocument: DOCUMENT?.nameAsDocument,
              identityType: DOCUMENT?.identityType,
              // Tourist
              passportNumber: DOCUMENT?.passportNumber,
              passportIssuingDate: DOCUMENT?.passportIssuingDate,
              passportExpiryDate: DOCUMENT?.passportExpiryDate,
              visaIdNumber: DOCUMENT?.visaIdNumber,
              visaIssuingDate: DOCUMENT?.visaIssuingDate,
              visaExpiryDate: DOCUMENT?.visaExpiryDate,
              // Citizen
              nationalIdIdNumber: DOCUMENT?.nationalIdIdNumber,
              nationalIdIssuingDate: DOCUMENT?.nationalIdIssuingDate,
              nationalIdExpiryDate: DOCUMENT?.nationalIdExpiryDate,
              givenName: DOCUMENT?.givenName,
              surName: DOCUMENT?.surName,
              cardNo: DOCUMENT?.cardNo,
              //refugee
              refugeeDocumentNumber: DOCUMENT?.refugeeDocumentNumber,
              refugeeDocumentIssuingDate: DOCUMENT?.refugeeDocumentIssuingDate,
              refugeeDocumentExpiryDate: DOCUMENT?.refugeeDocumentExpiryDate,
            },
            agentInfo: {
              id: AGENT?._id,
              ownerName: AGENT?.ownerName,
              firstName: AGENT?.firstName,
              middleName: AGENT?.middleName,
              lastName: AGENT?.lastName,
            },
            kycInfo: {
              id: KYC?._id,
              status: KYC?.status,
            },
          }));
          setIsLoaded(true);
        } else {
          setState(null);
          setIsLoaded(true);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
          setState(null);
          setIsLoaded(true);
        }
      })
      .finally(() => {
        Dispatch(toggleLoading(false));
      });
  };

  let handleFetchCustomerDoc = () => {
    Dispatch(toggleLoading(true));
    GetCustomerDoc(id)
      .then((res) => {
        const data = res?.data;
        if (
          data?.statusCode === AppStatusCode.api_success ||
          data?.statusCode === AppStatusCode.api_updated
        ) {
          const DATA = data?.data;

          // CITIZEN
          const customerPhoto = DATA?.customerPhoto || null;
          const nationalIdDocumentScanFront =
            DATA?.nationalIdDocumentScanFront || null;
          const nationalIdDocumentScanBack =
            DATA?.nationalIdDocumentScanBack || null;

          // Tourist
          const passportFrontScan = DATA?.passportFrontScan || null;
          const passportBackScan = DATA?.passportBackScan || null;
          const visaFrontScan = DATA?.visaFrontScan || null;
          const visaBackScan = DATA?.visaBackScan || null;

          // Refugee
          const refugeeDocumentFrontScan =
            DATA?.refugeeDocumentFrontScan || null;
          const refugeeDocumentBackScan = DATA?.refugeeDocumentBackScan || null;
          const digitalForm = DATA?.digitalForm || null;

          setCustomerDocs((prev) => ({
            ...prev,
            customerPhoto,
            nationalIdDocumentScanFront,
            nationalIdDocumentScanBack,
            passportFrontScan,
            passportBackScan,
            visaFrontScan,
            visaBackScan,
            refugeeDocumentFrontScan,
            refugeeDocumentBackScan,
            digitalForm,
          }));
        } else {
          setState(null);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
          setState(null);
        }
      })
      .finally(() => {
        setIsLoaded(true);
        Dispatch(toggleLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(id);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [id, Dispatch]);

  const listItems = [
    {
      id: "customerInfo",
      title: "Customer Information",
      component: <CustomerInformationTable data={state?.customerInfo} />,
    },
    {
      id: "documentInfo",
      title: "Document Information",
      component: <DocumentInformationTable data={state?.documentInfo} />,
    },
    {
      id: "agentInfo",
      title: "Agent Information",
      component: <AgentInformationTable data={state?.agentInfo} />,
    },
  ];

  if (id && isLoaded && !state?.id)
    return <Navigate to={RouteConstant.CUSTOMER_MANAGEMENT} />;

  return (
    <>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item xs={12} className="tw-flex tw-justify-end" sx={{ gap: 1 }}>
          {state?.kycInfo?.id ? (
            <Link to={`${RouteConstant.KYC}/${state?.kycInfo?.id}`}>
              <Button variant="outlined" color="inherit">
                KYC Details
              </Button>
            </Link>
          ) : (
            <></>
          )}
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => {
              handleFetchCustomerDoc();
              toggleCustomerDoc();
            }}
          >
            View Documents
          </Button>
        </Grid>
        <Grid item xs={12} className="tw-flex tw-justify-end" sx={{ gap: 1 }}>
          <Typography>
            KYC Status: <IsActiveCell status={state?.kycInfo?.status} />{" "}
          </Typography>
        </Grid>
      </Grid>
      <Box pt={3}>
        {listItems?.map(({ id, title, component }, i) => (
          <Fragment key={i}>
            <Box className="tw-border-b">
              <Button
                variant={activeItem === id ? "contained" : "text"}
                color={activeItem === id ? "primary" : "secondary"}
                fullWidth
                onClick={() =>
                  activeItem === id ? setActiveItem("") : setActiveItem(id)
                }
                endIcon={
                  activeItem === id ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
                className="tw-flex tw-justify-between tw-text-[14px] tw-rounded-none"
              >
                {title}
              </Button>
            </Box>
            {activeItem === id ? (
              <Box pt={2} pb={5} px={2}>
                {component}
              </Box>
            ) : (
              <></>
            )}
          </Fragment>
        ))}
      </Box>

      <CustomDrawer
        open={openCustomerDoc}
        onClose={toggleCustomerDoc}
        title="Customer Documents"
      >
        {customerDocs ? (
          <CustomerDocs
            onClose={toggleCustomerDoc}
            identityType={state?.customerInfo?.identityType}
            data={customerDocs}
          />
        ) : (
          <></>
        )}
      </CustomDrawer>
    </>
  );
};

export default CustomerManagementDetailsLayout;
