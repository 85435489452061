import RiseTicketLayout from "component/rise-ticket";
import MainContainer from "parts/mainContainer";

const RiseTicket = () => {
  return (
    <MainContainer>
      <RiseTicketLayout />
    </MainContainer>
  );
};

export default RiseTicket;
