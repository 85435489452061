type OtpVerificationProps = {
  logId?: string;
  OTP?: string;
  type?: string;
  token?: string;
  otpSent?: boolean;
};

export class OtpVerificationModel {
  logId?: string;
  OTP?: string;
  type?: string;
  token?: string;
  otpSent?: boolean;

  constructor(data: OtpVerificationProps = {}) {
    this.logId = data.logId || "";
    this.OTP = data.OTP || "";
    this.type = data.type || "";
    this.token = data.token || "";
    this.otpSent = data.otpSent || false;
  }
}
