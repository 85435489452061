import MsisdnManagementLayout from "component/msisdn-management";
import MainContainer from "parts/mainContainer";

const MsisdnManagement = () => {
  return (
    <MainContainer>
      <MsisdnManagementLayout />
    </MainContainer>
  );
};

export default MsisdnManagement;
