import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { Breakpoint } from "@mui/material/styles";
import React from "react";
import CustomDialogHeader from "./customDialogHeader";

interface Props {
  open: boolean;
  onClose?: () => void;
  title?: string;
  subTitle?: string;
  children: React.ReactNode;
  maxWidth?: Breakpoint | false;
  showCloseButton?: boolean;
}

const CustomDrawer: React.FC<Props> = ({
  open,
  onClose,
  title,
  subTitle,
  children,
  maxWidth,
  showCloseButton,
}) => {
  return (
    <Drawer onClose={onClose} open={open} anchor="right">
      <Box
        maxWidth={600}
        minWidth={{
          xs: 250,
          sm: 500,
        }}
      >
        {title ? (
          <Box sx={{ m: 0, px: 3, py: 1 }} className="tw-shadow-sm">
            <CustomDialogHeader
              onClose={onClose}
              title={title}
              subTitle={subTitle}
            />
          </Box>
        ) : (
          <></>
        )}
        {!title && showCloseButton ? (
          <Box className="tw-absolute tw-right-[20px] tw-top-[20px]">
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <></>
        )}
        <Box p={3}>{children}</Box>
      </Box>
    </Drawer>
  );
};

export default CustomDrawer;
