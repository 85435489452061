import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  FormHelperText,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import {
  GetAllDistrictByRegionName,
  GetAllRegions,
} from "functions/http-requests/address";
import { GridColDef } from "@mui/x-data-grid";
import {
  AutoCompleteOptionsProps,
  FormInputProps,
} from "_interfaces/_common/forms";
import {
  CreateRegionalDistributorProps,
  UpdateRegionalDistributorProps,
} from "_interfaces/functions/http-requests/distributor-channels/regional-distributors";
import { RegionalDistributorsModel } from "_models/data/regional-distributors/data.regional-distributors.model";
import { RegionalDistributorsErrorModel } from "_models/error/regional-distributos/error.regional-distributors.model";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import Password from "component/_common/forms/password";
import Text from "component/_common/forms/text";
import {
  CreateRegionalDistributor,
  GetRegionalDistributorById,
  UpdateRegionalDistributor,
} from "functions/http-requests/regional-distributors";
import {
  isValidString,
  renderInvalidStringErrorText,
} from "functions/validators";
import CustomDataGrid from "parts/customDataGrid";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import axios from "axios";
import { validateRegionalDistributor } from "component/regional-distributors/formValidator";

interface InputListProps extends FormInputProps {
  name:
    | "name"
    | "email"
    | "phone"
    | "region"
    | "districts"
    | "password"
    | "confirmPassword";
  order?: number;
}

interface Props {
  editId?: string;
  handleRefresh: () => void;
  onClose: () => void;
}

const AgentsForm: React.FC<Props> = ({
  editId,
  handleRefresh,
  onClose,
}) => {
  const { palette } = useTheme();
  const Dispatch = useDispatch();

  const [state, setState] = useState<RegionalDistributorsModel>(
    new RegionalDistributorsModel()
  );
  const [errors, setErrors] = useState<RegionalDistributorsErrorModel>(
    new RegionalDistributorsErrorModel()
  );

  const [allRegions, setAllRegions] = useState<AutoCompleteOptionsProps[]>([]);
  const [allDistricts, setAllDistricts] = useState<AutoCompleteOptionsProps[]>(
    []
  );

  const [editRefresh, setEditRefresh] = useState<boolean>(false);

  const handleEditRefresh = (REFRESH: boolean) => setEditRefresh(REFRESH);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      if (name === "name" && value) {
        if (!isValidString(value)) {
          return setErrors((prev) => ({
            ...prev,
            [name]: renderInvalidStringErrorText(),
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "",
          }));
        }
      }
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleBlur = (name: string) => {
    if (name === "name") {
      if (
        state[name] &&
        !isValidString(state[name as keyof RegionalDistributorsModel] as string)
      ) {
        setErrors((prev) => ({
          ...prev,
          [name]: renderInvalidStringErrorText(),
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }
  };

  const handleCreate = ({ DATA }: CreateRegionalDistributorProps) => {
    Dispatch(toggleLoading(true));
    CreateRegionalDistributor({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_created ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleUpdate = ({ DATA, ID }: UpdateRegionalDistributorProps) => {
    Dispatch(toggleLoading(true));
    UpdateRegionalDistributor({ DATA, ID })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_updated ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (): void => {
    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateRegionalDistributor(state, editId);

    if (ValidateStep?.valid) {
      const DATA: CreateRegionalDistributorProps["DATA"] = {
        name: state.name,
        email: state.email,
        phone: state.phone,
        assignedLocation: state.assignedLocation,
        password: state.password,
      };

      if (editId) handleUpdate({ DATA, ID: editId });
      else handleCreate({ DATA });
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  // GET BY ID
  const handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetRegionalDistributorById(ID)
      .then((res) => {
        const data = res?.data;
        if (data?.statusCode === AppStatusCode.api_success) {
          let DATA: any = data?.data;
          if (editRefresh) {
            setState((prev) => ({
              ...prev,
              isActive: DATA.isActive,
            }));
            handleEditRefresh(false);
          } else {
            setState({
              id: DATA?._id || "",
              name: DATA?.name || DATA?.userDetails?.name,
              email: DATA?.email || DATA?.userDetails?.email,
              phone: DATA?.phone || DATA?.userDetails?.phone,
              assignedLocation: DATA?.assignedLocation || [],
              password: "",
              confirmPassword: "",
            });
          }
        } else {
          Dispatch(setAlert({ type: "error", message: data?.message }));
          setState(new RegionalDistributorsModel());
          onClose();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  useEffect(() => {
    if (editId) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(editId);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [editId]);

  useEffect(() => {
    if (editId && editRefresh) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(editId);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [editId, editRefresh]);

  // REGIONS
  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      Dispatch(toggleLoading(true));

      GetAllRegions()
        .then((res) => {
          let DATA: any = res?.data?.data;
          let options: AutoCompleteOptionsProps[] = DATA?.map((item: any) => ({
            id: item?.name,
            value: item?.name,
            title: item?.name,
            isActive: true,
          }));
          setAllRegions(options);
        })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => Dispatch(toggleLoading(false)));
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [Dispatch]);

  // DISTRICTS
  useEffect(() => {
    if (state?.region) {
      let fetchList: (() => void) | null = () => {
        Dispatch(toggleLoading(true));

        if (state.region) {
          GetAllDistrictByRegionName(state.region)
            .then((res) => {
              let DATA: any = res?.data?.data;
              let options: AutoCompleteOptionsProps[] = DATA?.map(
                (item: any) => ({
                  id: item?.name,
                  value: item?.name,
                  title: item?.name,
                  isActive: true,
                })
              );
              setAllDistricts(options);
            })
            .catch((error) => {
              if (!axios.isCancel(error))
                Dispatch(
                  setAlert({ type: "error", message: HTTP_ERROR(error) })
                );
            })
            .finally(() => Dispatch(toggleLoading(false)));
        }
      };
      fetchList();
      return () => {
        fetchList = null;
      };
    }
  }, [Dispatch, state.region]);

  useEffect(() => {
    if (state.assignedLocation?.length) {
      state?.assignedLocation?.map((location: any) => {
        if (location?.region) handleUpdateRegionsDistricts(location?.region);
        return location;
      });
    }
  }, [state.assignedLocation]);

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "text",
        name: "name",
        label: "Agent Name",
        placeholder: "Enter Agent Name",
        grid: 12,
        order: 1,
        enabled: true,
      },
      {
        type: "email",
        name: "email",
        label: "Agent Email",
        placeholder: "Enter Email",
        grid: 6,
        order: 2,
        enabled: true,
      },
      {
        type: "number",
        name: "phone",
        label: "Agent Phone",
        placeholder: "Enter Phone",
        grid: 6,
        order: 3,
        enabled: true,
      },
      {
        type: "autoComplete",
        name: "region",
        label: "Region",
        placeholder: "Select Region",
        options: allRegions,
        multiple: false,
        grid: 5,
        enabled: true,
      },
      {
        type: "autoComplete",
        name: "districts",
        label: "Districts",
        placeholder: "Select Districts",
        options: allDistricts,
        multiple: true,
        grid: 5,
        enabled: true,
      },
      {
        type: "password",
        name: "password",
        label: "Password",
        placeholder: "Enter Password",
        autoComplete: "new-password",
        grid: 6,
        order: 5,
        enabled: editId ? false : true,
      },
      {
        type: "password",
        name: "confirmPassword",
        label: "Repeat Password",
        placeholder: "Enter Password again",
        autoComplete: "new-password",
        grid: 6,
        order: 6,
        enabled: editId ? false : true,
      },
    ],
    [allRegions, allDistricts, editId]
  );

  const SelectedLocationColumns: GridColDef[] = [
    {
      field: "region",
      headerName: "Regions",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "districts",
      headerName: "Districts",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box>
            {params?.row?.districts?.length ? (
              params?.row?.districts?.join(", ")
            ) : (
              <></>
            )}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    region: params?.row?.region,
                    districts: params?.row?.districts,
                  }))
                }
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="Edit">
                  <EditIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    assignedLocation: prev.assignedLocation?.filter(
                      (location) => location.region !== params.row.region
                    ),
                  }));
                  handleUpdateRegionsDistricts(params.row.region, true);
                }}
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="Clear">
                  <CloseIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];

  const handleUpdateRegionsDistricts = (region: string, isActive?: boolean) => {
    // UPDATE REGIONS - DE-ACTIVATE OR ACTIVATE SELECTED REGION FROM LIST
    setAllRegions((prev) => {
      return prev.map((r) => {
        if (r.id === region || r.value === region) {
          return {
            ...r,
            isActive: isActive || false,
          };
        } else return r;
      });
    });
    setAllDistricts([]);
  };

  // LOCATION HANDLERS
  const handleAddLocation = () => {
    if (state?.region && state?.districts?.length) {
      let alreadyExist = state?.assignedLocation?.find(
        (e) => e?.region === state?.region
      );
      if (alreadyExist) {
        handleUpdateRegionsDistricts(state.region);
        // UPDATE STATE
        setState((prev) => ({
          ...prev,
          assignedLocation: prev.assignedLocation?.map((location) => {
            if (location.region === state.region) {
              return {
                region: state.region,
                districts: state.districts,
              };
            } else return location;
          }),
          region: "",
          districts: [],
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          assignedLocation: "",
        }));
        let ASSIGNED_LOCATION_DATA = {
          region: state.region,
          districts: state.districts,
        };

        handleUpdateRegionsDistricts(state.region);

        // UPDATE STATE
        setState((prev) => ({
          ...prev,
          assignedLocation: prev.assignedLocation?.length
            ? [...prev.assignedLocation, ASSIGNED_LOCATION_DATA]
            : [ASSIGNED_LOCATION_DATA],
          region: "",
          districts: [],
        }));
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        assignedLocation: "Select region and districts.",
      }));
    }
  };

  return (
    <>
      <Box id="crete_edit_distributor_form">
        <Grid container rowSpacing={3} columnSpacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                grid,
                autoComplete,
                disabled,
                order,
                enabled,
              },
              index
            ) => (
              <Fragment key={index}>
                {enabled ? (
                  <>
                    {type === "text" ||
                    type === "email" ||
                    type === "number" ? (
                      <Grid item xs={12} md={6} lg={grid || 6} order={order}>
                        <Text
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof RegionalDistributorsModel]}
                          errorText={
                            errors[name as keyof RegionalDistributorsErrorModel]
                          }
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={() => handleBlur(name)}
                          disabled={disabled}
                        />
                      </Grid>
                    ) : type === "password" ? (
                      <Grid item xs={12} md={6} lg={grid || 6} order={order}>
                        <Password
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof RegionalDistributorsModel]}
                          errorText={
                            errors[name as keyof RegionalDistributorsErrorModel]
                          }
                          onChange={handleChange}
                          onFocus={handleFocus}
                          disabled={disabled}
                          isHideShow
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Fragment>
            )
          )}

          <Grid item xs={12} order={4}>
            <Grid container spacing={2}>
              {inputList.map(
                (
                  { type, name, label, placeholder, options, multiple },
                  index
                ) => (
                  <Fragment key={index}>
                    <>
                      {type === "autoComplete" ? (
                        <Grid item xs={12} md={5}>
                          <InputAutoComplete
                            name={name}
                            options={options || []}
                            label={label}
                            placeholder={placeholder}
                            onChange={(e, v, m) =>
                              handleAutoComplete(e, v, name, m)
                            }
                            value={
                              options &&
                              options.length &&
                              state?.[name as keyof RegionalDistributorsModel]
                            }
                            errorText={
                              errors[
                                name as keyof RegionalDistributorsErrorModel
                              ]
                            }
                            onFocus={handleFocus}
                            multiple={multiple}
                          />
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </>
                  </Fragment>
                )
              )}
              <Grid item xs={12} md={2}>
                <Box
                  sx={{
                    paddingTop: {
                      md: "28px",
                    },
                  }}
                >
                  <Button
                    variant="outlined"
                    fullWidth
                    className="tw-min-h-[54px]"
                    onClick={handleAddLocation}
                  >
                    <AddIcon />
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {errors?.assignedLocation ? (
                  <FormHelperText
                    className="tw-mb-[3px] tw-mx-[14px]"
                    sx={{ color: palette.error.main }}
                  >
                    {errors.assignedLocation}
                  </FormHelperText>
                ) : (
                  <></>
                )}
                {state?.assignedLocation?.length ? (
                  <CustomDataGrid
                    columns={SelectedLocationColumns}
                    rows={state.assignedLocation?.map((item, index) => ({
                      id: index,
                      ...item,
                    }))}
                    paginationMode="client"
                    height={250}
                    hideFooter
                  />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} order={7}>
            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
            >
              {editId ? "Save Changes" : "Add New Distributor"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AgentsForm;
