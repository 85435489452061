import AnalyticsReportLayout from "component/analytics/report";
import MainContainer from "parts/mainContainer";

const AnalyticsReport = () => {
  return (
    <MainContainer>
      <AnalyticsReportLayout />
    </MainContainer>
  );
};

export default AnalyticsReport;
