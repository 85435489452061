import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { IsActiveCell } from "parts/table/customCell";
import { Fragment } from "react";
import KycDetailsInterface from "_interfaces/kyc";

interface Props {
  data: KycDetailsInterface | null;
}

const TopStatistics: React.FC<Props> = ({ data }) => {
  const rows: {
    field: keyof NonNullable<KycDetailsInterface>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
    { field: "KYC_type", headerName: "KYC Type" },
    { field: "registerBy", headerName: "Register By" },
    {
      field: "time",
      headerName: "KYC Done on ",
      renderCell: (v) => (
        <>
          <Box>{moment(v).format("Do MMM, YYYY")}</Box>
          <Box fontSize="small" fontWeight={500}>
            {moment(v).format("h:mm:ss")}
          </Box>
        </>
      ),
    },
    {
      field: "updatedAt",
      headerName: "KYC Last updated",
      renderCell: (v) => (
        <>
          <Box>{moment(v).format("Do MMM, YYYY")}</Box>
          <Box fontSize="small" fontWeight={500}>
            {moment(v).format("h:mm:ss")}
          </Box>
        </>
      ),
    },
    {
      field: "status",
      headerName: "KYC Status",
      renderCell: (v) => <IsActiveCell status={v} />,
    },
  ];

  return (
    <>
      <Box p={2} className="tw-bg-white tw-rounded-lg tw-border tw-shadow">
        <Grid container spacing={3} justifyContent="center" textAlign="center">
          {rows?.map((row, i) => (
            <Fragment key={i}>
              {row?.field && data?.[row.field] ? (
                <Grid item xs={12} sm={6} md={2.4}>
                  <Box
                    px={2}
                    py={1}
                    sx={{
                      borderLeft: i !== 0 ? "1px solid" : "",
                      borderColor: "inherit",
                      height: "100%",
                    }}
                  >
                    <Typography fontSize="small" textTransform="uppercase">
                      {row.headerName}
                    </Typography>
                    <Typography
                      fontSize="large"
                      textTransform="capitalize"
                      fontWeight={500}
                      pt={0.3}
                    >
                      <>
                        {row?.renderCell
                          ? row.renderCell(data[row.field])
                          : data[row.field]}
                      </>
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                <></>
              )}
            </Fragment>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default TopStatistics;
