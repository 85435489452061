import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const SearchResultCard = () => {
  return (
    <>
      <Box className="tw-flex tw-justify-between tw-items-center tw-py-[2px]">
        <Typography fontSize={14}>ICCID: #7676767768</Typography>
        <IconButton size="small">
          <CloseIcon sx={{ fontSize: 14 }} />
        </IconButton>
      </Box>
    </>
  );
};

export default SearchResultCard;
