import SimCardChargesLayout from "component/sim-card-charges";
import MainContainer from "parts/mainContainer";

const SimCardCharges = () => {
  return (
    <MainContainer>
      <SimCardChargesLayout />
    </MainContainer>
  );
};

export default SimCardCharges;
