import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SideNavbar from "parts/navbar/sideNavbar";
import TopNavbar from "parts/navbar/topNavbar";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "state/store";
import { RouteConstant } from "./constant";

interface Props {
  children: React.ReactNode;
  role?: string | string[];
}
const SecuredRoute: React.FC<Props> = ({ children, role }) => {
  const theme = useTheme();
  const mdDevice = useMediaQuery(theme.breakpoints.up("md"));

  const { isLoggedIn, userInfo } = useSelector(
    (state: RootState) => state.user
  );

  if (!isLoggedIn) return <Navigate to={RouteConstant.LOG_IN} />;

  if (
    role &&
    ((!Array.isArray(role) && role !== userInfo?.role) ||
      (Array.isArray(role) && !role.includes(userInfo?.role)))
  ) {
    return <Navigate to={RouteConstant.UNAUTHORIZED} />;
  }

  return (
    <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
      {mdDevice ? (
        <Box flexGrow={0} className="tw-pl-[12px] tw-py-[8px]">
          <SideNavbar />
        </Box>
      ) : (
        <></>
      )}
      <Box display="flex" flexDirection="row" flexGrow={1}>
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          className="tw-px-[12px]"
        >
          <Box className="tw-py-[8px]">
            <TopNavbar />
          </Box>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default SecuredRoute;
