import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MsisdnManagementDetailsInterface from "_interfaces/msisdn-management/details";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import { GetMsisdnById } from "functions/http-requests/msisdnManagement";
import { RouteConstant } from "navigation/constant";
import SectionSearch from "parts/sectionSearch";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import MsisdnCustomerInformationTable from "./msisdnCustomerInformationTable";
import MsisdnDetailedInformationTable from "./msisdnDetailedInformationTable";
import MsisdnInformationTable from "./msisdnInformationTable";
import MsisdnSimInformationTable from "./msisdnSimInformationTable";
import axios from "axios";

interface Props {
  id: string;
}

const MsisdnManagementDetailsLayout: React.FC<Props> = ({ id }) => {
  const Dispatch = useDispatch();

  const [activeItem, setActiveItem] = useState("msisdnInformation");

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [state, setState] = useState<MsisdnManagementDetailsInterface | null>(
    null
  );

  let handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetMsisdnById(ID)
      .then((res) => {
        const data = res?.data;
        if (
          data?.statusCode === AppStatusCode.api_success ||
          data?.statusCode === AppStatusCode.api_updated
        ) {
          const DATA: any = data?.data;

          setState((prev) => ({
            ...prev,
            id: DATA?._id,
            msisdnInfo: {
              MSISDN: DATA?.MSISDN,
              phoneNumber: DATA?.phoneNumber,
              type: DATA?.type,
              isFixed: DATA?.isFixed,
              status: DATA?.status,
            },
            detailedInfo: {
              id: DATA?.details?._id,
              MSISDN_id: DATA?.details?.MSISDN_id,
              MSISDN: DATA?.details?.MSISDN,
              phoneNumber: DATA?.details?.phoneNumber,
              ICCID: DATA?.details?.ICCID,
              SIM: DATA?.details?.SIM,
              agent: DATA?.details?.agent,
              customer: DATA?.details?.customer,
              kyc: DATA?.details?.kyc,
              assignedDate: DATA?.details?.assignedDate,
              isFixed: DATA?.details?.isFixed,
              status: DATA?.details?.status,
            },
            simInfo: {
              id: DATA?.SIM_Details?._id,
              carton: DATA?.SIM_Details?.carton,
              ICCID: DATA?.SIM_Details?.ICCID,
              MSISDN: DATA?.SIM_Details?.MSISDN,
              MSISDN_id: DATA?.SIM_Details?.MSISDN_id,
              simType: DATA?.SIM_Details?.SIM_type,
              serviceType: DATA?.SIM_Details?.serviceType,
              assignedDate: DATA?.SIM_Details?.assignedDate,
              isReserved: DATA?.SIM_Details?.isReserved,
              status: DATA?.SIM_Details?.status,
            },
            customerInfo: {
              id: DATA?.customerDetails?._id,
              MSISDN: DATA?.customerDetails?.MSISDN,
              identityType: DATA?.customerDetails?.identityType,
              nameAsDocument: DATA?.customerDetails?.nameAsDocument,
            },
          }));
        } else {
          setState(null);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
          setState(null);
        }
      })
      .finally(() => {
        setIsLoaded(false);
        Dispatch(toggleLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(id);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [id, Dispatch]);

  const listItems = [
    {
      id: "msisdnInformation",
      title: "MSISDN Information",
      component: <MsisdnInformationTable data={state} id={id} getAllData={() => handleFetchById(id)} />,
    },
    {
      id: "detailedInfo",
      title: "Detailed Information",
      component: <MsisdnDetailedInformationTable data={state} />,
    },
    {
      id: "simInformation",
      title: "SIM Information",
      component: <MsisdnSimInformationTable data={state} />,
    },
    {
      id: "customerInformation",
      title: "Customer Information",
      component: <MsisdnCustomerInformationTable data={state} />,
    },
  ];

  console.log(id, isLoaded, !state?.id)

  if (id && isLoaded && !state?.id)
    return <Navigate to={RouteConstant.MSISDN_MANAGEMENT} />;

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SectionSearch />
          </Grid>
          <Grid item xs={12} sm={6} className="tw-flex tw-justify-end"></Grid>
        </Grid>
      </Box>

      <Box pt={5}>
        {listItems?.map(({ id, title, component }, i) => (
          <Fragment key={i}>
            <Box className="tw-border-b">
              <Button
                variant={activeItem === id ? "contained" : "text"}
                color={activeItem === id ? "primary" : "secondary"}
                fullWidth
                onClick={() => setActiveItem(id)}
                endIcon={
                  activeItem === id ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
                className="tw-flex tw-justify-between tw-text-[14px] tw-rounded-none"
              >
                {title}
              </Button>
            </Box>
            {activeItem === id ? (
              <Box pt={2} pb={5} px={2}>
                {component}
              </Box>
            ) : (
              <></>
            )}
          </Fragment>
        ))}
      </Box>
    </>
  );
};

export default MsisdnManagementDetailsLayout;
