import { SimCardChargesModel } from "_models/data/sim-card-charges/data.sim-card-charges.model";

export const validateSimCardCharges = (DATA: SimCardChargesModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA?.simCharges?.trim()) {
    valid = false;
    errors.push({
      name: "simCharges",
      error: "SIM Charges is required.",
    });
  }

  if (!DATA?.serviceCharges?.trim()) {
    valid = false;
    errors.push({
      name: "serviceCharges",
      error: "Service Charges is required.",
    });
  }

  if (!DATA?.vatPercent?.trim()) {
    valid = false;
    errors.push({
      name: "vatPercent",
      error: "VAT Percent is required.",
    });
  }

  if (!DATA?.MSISDNCategory?.trim()) {
    valid = false;
    errors.push({
      name: "MSISDNCategory",
      error: "MSISDN Category is required.",
    });
  }

  return { valid, errors };
};
