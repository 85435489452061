import { GET_KYC_BY_ID } from "config/endpoint";
import { HTTP } from "functions/http";
import { CallKycServerApiProps } from "_interfaces/functions/http-requests/kyc";

export const GetKycById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_KYC_BY_ID(ID),
  });
};

export const CallKycServerApi = ({ Url, DATA }: CallKycServerApiProps) => {
  return HTTP({
    Method: "POST",
    Url: Url,
    Data: DATA,
  });
};
