import { HTTP } from "functions/http";

import {
  CreateSimCardChargesProps,
  UpdateSimCardChargesProps,
} from "_interfaces/functions/http-requests/sim-card-charges";
import {
  CREATE_SIM_CARD_CHARGES,
  GET_SIM_CARD_CHARGES,
  GET_SIM_CARD_CHARGES_BY_ID,
  GET_SIM_CARD_CHARGES_BY_MSISDN_CATEGORY,
  UPDATE_SIM_CARD_CHARGES,
} from "config/endpoint";

export const CreateSimCardCharges = ({ DATA }: CreateSimCardChargesProps) => {
  return HTTP({
    Method: "POST",
    Url: CREATE_SIM_CARD_CHARGES,
    Data: DATA,
  });
};

export const UpdateSimCardCharges = ({ DATA }: UpdateSimCardChargesProps) => {
  return HTTP({
    Method: "POST",
    Url: UPDATE_SIM_CARD_CHARGES,
    Data: DATA,
  });
};

export const GetSimCardCharges = () => {
  return HTTP({
    Method: "GET",
    Url: GET_SIM_CARD_CHARGES,
  });
};

export const GetSimCardChargesById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_SIM_CARD_CHARGES_BY_ID(ID),
  });
};

export const GetSimCardChargesByMSISDNCategory = (CATEGORY: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_SIM_CARD_CHARGES_BY_MSISDN_CATEGORY(CATEGORY),
  });
};
