import { CreateAppVersionInterface } from "_interfaces/functions/http-requests/apps";
import { CREATE_NEW_VERSION, GET_ALL_VERSION, GET_LATEST_VERSION } from "config/endpoint";
import { HTTP } from "functions/http";

export const GetAppVersion = (AppName: string) => {
    return HTTP({
        Method: "GET",
        Url: GET_LATEST_VERSION(AppName),
    });
};

export const GetAllVersion = () => {
    return HTTP({
        Method: "GET",
        Url: GET_ALL_VERSION,
    });
};

export const CreateNewVersion = ({ DATA }: CreateAppVersionInterface) => {
    return HTTP({
        Method: "POST",
        Url: CREATE_NEW_VERSION,
        Data: DATA,
    });
};