import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import { AppStatusCode } from "config/appStatusCode";
import {
  CBS_RE_SUBMIT_REQUEST,
  CREATE_APN,
  CREATE_HLR,
  CREATE_HSS,
  CREATE_OCSI,
  CREATE_PDP,
  CREATE_SMSCI,
  CREATE_SUPPLEMENT1,
  CREATE_SUPPLEMENT2,
  NIRA_UCC_REGISTRATION_RE_SUBMIT_REQUEST,
} from "config/endpoint";
import { HTTP_ERROR } from "functions/http";
import { GetKycById } from "functions/http-requests/kyc";
import { RouteConstant } from "navigation/constant";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import { CallKycServerApiProps } from "_interfaces/functions/http-requests/kyc";
import KycDetailsInterface from "_interfaces/kyc";
import KycServerInfoTable from "./kycServerInfoTable";
import TopStatistics from "./topStatistics";

interface Props {
  id: string;
}

const KycLayout: React.FC<Props> = ({ id }) => {
  const Dispatch = useDispatch();

  const [activeItem, setActiveItem] = useState("customerInfo");

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [state, setState] = useState<KycDetailsInterface | null>(null);

  const [refresh, setRefresh] = useState<boolean>(false);

  const handleRefresh = () => setRefresh(!refresh);

  let handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetKycById(ID)
      .then((res) => {
        const data = res?.data;
        if (
          data?.statusCode === AppStatusCode.api_success ||
          data?.statusCode === AppStatusCode.api_updated
        ) {
          const DATA: any = data?.data;

          const NIRA = DATA?.NIRA;
          const UCC = DATA?.UCC;
          const CBS = DATA?.CBS;
          const HLR_SUBSCRIBE = DATA?.HLR_SUBSCRIBE;
          const HLR_SUBSCRIBE_mt = DATA?.HLR_SUBSCRIBE_mt;
          const HLR_PDP = DATA?.HLR_PDP;
          const HLR_PDP_mt = DATA?.HLR_PDP_mt;
          const HLR_OCSI = DATA?.HLR_OCSI;
          const HLR_OCSI_mt = DATA?.HLR_OCSI_mt;
          const HLR_SMSCI = DATA?.HLR_SMSCI;
          const HLR_SMSCI_mt = DATA?.HLR_SMSCI_mt;
          const HLR_SUPPLEMENT1 = DATA?.HLR_SUPPLEMENT1;
          const HLR_SUPPLEMENT1_mt = DATA?.HLR_SUPPLEMENT1_mt;
          const HLR_SUPPLEMENT2 = DATA?.HLR_SUPPLEMENT2;
          const HLR_SUPPLEMENT2_mt = DATA?.HLR_SUPPLEMENT2_mt;
          const HSS = DATA?.HSS;
          const HSS_mt = DATA?.HSS_mt;
          const HSS_SUBSCRIBE_APN = DATA?.HSS_SUBSCRIBE_APN;
          const HSS_SUBSCRIBE_APN_mt = DATA?.HSS_SUBSCRIBE_APN_mt;

          setState((prev) => ({
            ...prev,
            id: DATA?._id,
            KYC_type: DATA?.KYC_type,
            MSISDN_id: DATA?.MSISDN_id,
            MSISDN: DATA?.MSISDN,
            registerBy: DATA?.registerBy,
            customer: DATA?.customer,
            agent: DATA?.agent,
            time: DATA?.time,
            status: DATA?.status,
            createdAt: DATA?.createdAt,
            updatedAt: DATA?.updatedAt,
            selectedPackage: DATA?.selectedPackage || null,
            buildBundle: DATA?.buildBundle || null,
            serverList: {
              NIRA,
              UCC,
              CBS,
              HLR_SUBSCRIBE,
              HLR_SUBSCRIBE_mt,
              HLR_PDP,
              HLR_PDP_mt,
              HLR_OCSI,
              HLR_OCSI_mt,
              HLR_SMSCI,
              HLR_SMSCI_mt,
              HLR_SUPPLEMENT1,
              HLR_SUPPLEMENT1_mt,
              HLR_SUPPLEMENT2,
              HLR_SUPPLEMENT2_mt,
              HSS,
              HSS_mt,
              HSS_SUBSCRIBE_APN,
              HSS_SUBSCRIBE_APN_mt,
            },
          }));
          setIsLoaded(true);
        } else {
          setState(null);
          setIsLoaded(true);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
          setState(null);
          setIsLoaded(true);
        }
      })
      .finally(() => {
        Dispatch(toggleLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(id);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [id, Dispatch, refresh]);

  const listItems: {
    listId: string;
    title: string;
    Url?: CallKycServerApiProps["Url"];
    type?: CallKycServerApiProps["DATA"]["type"];
  }[] = [
    {
      listId: "NIRA",
      title: "NIRA",
      Url: NIRA_UCC_REGISTRATION_RE_SUBMIT_REQUEST,
    },
    {
      listId: "UCC",
      title: "UCC",
      Url: NIRA_UCC_REGISTRATION_RE_SUBMIT_REQUEST,
    },
    {
      listId: "CBS",
      title: "CBS",
      Url: CBS_RE_SUBMIT_REQUEST,
    },
    {
      listId: "HLR_SUBSCRIBE",
      title: "HLR SUBSCRIBE <small>MBYUA</small>",
      Url: CREATE_HLR,
      type: "mb",
    },
    {
      listId: "HLR_SUBSCRIBE_mt",
      title: "HLR SUBSCRIBE <small>MUTUNDWE</small>",
      Url: CREATE_HLR,
      type: "mt",
    },
    {
      listId: "HLR_PDP",
      title: "HLR PDP <small>MBYUA</small>",
      Url: CREATE_PDP,
      type: "mb",
    },
    {
      listId: "HLR_PDP_mt",
      title: "HLR PDP <small>MUTUNDWE</small>",
      Url: CREATE_PDP,
      type: "mt",
    },
    {
      listId: "HLR_OCSI",
      title: "HLR OCSI <small>MBYUA</small>",
      Url: CREATE_OCSI,
      type: "mb",
    },
    {
      listId: "HLR_OCSI_mt",
      title: "HLR OCSI <small>MUTUNDWE</small>",
      Url: CREATE_OCSI,
      type: "mt",
    },
    {
      listId: "HLR_SMSCI",
      title: "HLR SMSCI <small>MBYUA</small>",
      Url: CREATE_SMSCI,
      type: "mb",
    },
    {
      listId: "HLR_SMSCI_mt",
      title: "HLR SMSCI <small>MUTUNDWE</small>",
      Url: CREATE_SMSCI,
      type: "mt",
    },
    {
      listId: "HLR_SUPPLEMENT1",
      title: "HLR SUPPLEMENT 1 <small>MBYUA</small>",
      Url: CREATE_SUPPLEMENT1,
      type: "mb",
    },
    {
      listId: "HLR_SUPPLEMENT1_mt",
      title: "HLR SUPPLEMENT1 <small>MUTUNDWE</small>",
      Url: CREATE_SUPPLEMENT1,
      type: "mt",
    },
    {
      listId: "HLR_SUPPLEMENT2",
      title: "HLR SUPPLEMENT 2 <small>MBYUA</small>",
      Url: CREATE_SUPPLEMENT2,
      type: "mb",
    },
    {
      listId: "HLR_SUPPLEMENT2_mt",
      title: "HLR SUPPLEMENT2 <small>MUTUNDWE</small>",
      Url: CREATE_SUPPLEMENT2,
      type: "mt",
    },
    {
      listId: "HSS",
      title: "HSS <small>MBYUA</small>",
      Url: CREATE_HSS,
      type: "mb",
    },
    {
      listId: "HSS_mt",
      title: "HSS <small>MUTUNDWE</small> <small>MUTUNDWE</small>",
      Url: CREATE_HSS,
      type: "mt",
    },
    {
      listId: "HSS_SUBSCRIBE_APN",
      title: "HSS SUBSCRIBE APN <small>MBYUA</small>",
      Url: CREATE_APN,
      type: "mb",
    },
    {
      listId: "HSS_SUBSCRIBE_APN_mt",
      title: "HSS SUBSCRIBE APN <small>MUTUNDWE</small>",
      Url: CREATE_APN,
      type: "mt",
    },
  ];

  if (id && isLoaded && !state?.id)
    return <Navigate to={RouteConstant.CUSTOMER_MANAGEMENT} />;

  return (
    <>
      {isLoaded ? (
        <>
          <TopStatistics data={state} />

          <Box pt={3}>
            {listItems?.map(({ listId, title, Url, type }, i) => (
              <Fragment key={i}>
                {state?.serverList &&
                state?.serverList?.[
                  listId as keyof KycDetailsInterface["serverList"]
                ] ? (
                  <Box className="tw-border-b">
                    <Button
                      variant={activeItem === listId ? "contained" : "text"}
                      color={activeItem === listId ? "primary" : "secondary"}
                      fullWidth
                      onClick={() =>
                        activeItem === listId
                          ? setActiveItem("")
                          : setActiveItem(listId)
                      }
                      endIcon={
                        activeItem === listId ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )
                      }
                      className="tw-flex tw-justify-between tw-text-[14px] tw-rounded-none tw-uppercase"
                    >
                      <Box dangerouslySetInnerHTML={{ __html: title }} />
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
                {activeItem === listId ? (
                  <Box pt={2} pb={5} px={2}>
                    {state?.serverList ? (
                      <KycServerInfoTable
                        serverName={activeItem}
                        kycId={id}
                        Url={Url || undefined}
                        type={type}
                        data={
                          state?.serverList[
                            activeItem as keyof KycDetailsInterface["serverList"]
                          ]
                        }
                        isRetryEnabled={
                          listId === "NIRA" || listId === "UCC"
                            ? !state?.serverList?.NIRA?.isVerified &&
                              !state?.serverList?.UCC?.isVerified
                            : state?.serverList?.NIRA?.isVerified ||
                              state?.serverList?.UCC?.isVerified
                        }
                        handleRefresh={handleRefresh}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                ) : (
                  <></>
                )}
              </Fragment>
            ))}
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default KycLayout;
