import React from "react";
import { IconsInterface } from "_interfaces/_common/icons";

const BlockIcon: React.FC<IconsInterface> = ({ width, height, color }) => {
  return (
    <svg
      width={width || "14"}
      height={height || "14"}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.62416 0.167297L13.3342 3.87728V9.12398L9.62416 12.834H4.37746L0.66748 9.12398V3.87728L4.37746 0.167297H9.62416ZM9.07189 1.50063H4.92974L2.00081 4.42956V8.57171L4.92974 11.5006H9.07189L12.0008 8.57171V4.42956L9.07189 1.50063ZM4.33365 5.83344H9.66696V7.16678H4.33365V5.83344Z"
        fill={color}
      />
    </svg>
  );
};

export default BlockIcon;
