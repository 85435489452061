import Grid from "@mui/material/Grid";
import StatisticsCard from "component/_common/cards/statisticsCard";

const TopStatistics = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <StatisticsCard
            statisticsValue="2000"
            title="Total available stock"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <StatisticsCard statisticsValue="1000" title="Last Month Stock" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <StatisticsCard statisticsValue="2400" title="Received Stock" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <StatisticsCard statisticsValue="1900" title="Upcoming Stock" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <StatisticsCard
            statisticsValue="May 24"
            title="Last Receive on Date"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TopStatistics;
