import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MsisdnManagementDetailsInterface from "_interfaces/msisdn-management/details";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import { IsActiveCell } from "parts/table/customCell";
import DetailsTable from "parts/table/detailsTable";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  data: MsisdnManagementDetailsInterface | null;
}

const MsisdnDetailedInformationTable: React.FC<Props> = ({ data }) => {
  return (
    <>
      {data?.detailedInfo?.id ? (
        <DetailsTable>
          <TableRow>
            <TableCell>MSISDN</TableCell>
            <TableCell>{data?.detailedInfo?.MSISDN}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Phone Number</TableCell>
            <TableCell>{data?.detailedInfo?.phoneNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ICCID</TableCell>
            <TableCell>{data?.detailedInfo?.ICCID}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SIM</TableCell>
            <TableCell>
              {data?.detailedInfo?.SIM ? (
                <Link
                  to={`${RouteConstant.SIM_MANAGEMENT}/${data?.detailedInfo?.SIM}`}
                >
                  <Box component="span" color="primary.main">
                    View Details
                  </Box>
                </Link>
              ) : (
                <></>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Agent</TableCell>
            <TableCell>View Details</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>View Details</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>KYC</TableCell>
            <TableCell>View Details</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Assigned Date</TableCell>
            <TableCell>
              {data?.detailedInfo?.assignedDate
                ? moment(data?.detailedInfo?.assignedDate).format("Do MMM YY")
                : ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Number Type</TableCell>
            <TableCell>
              {data?.detailedInfo?.isFixed ? "Fixed" : "Normal"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              <IsActiveCell status={data?.detailedInfo?.status} />
            </TableCell>
          </TableRow>
        </DetailsTable>
      ) : (
        <></>
      )}
    </>
  );
};

export default MsisdnDetailedInformationTable;
