import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { OtpVerificationLayoutProps } from "_interfaces/auth";
import { OtpVerificationErrorModel } from "_models/error/auth/error.otpVerification.model";
import SectionTitle from "parts/sectionTitle";
import React, { useEffect, useState } from "react";
import { validateOtpVerification } from "./formValidator";
import OtpInput from "component/_common/forms/otpInput";

const OtpVerificationLayout: React.FC<OtpVerificationLayoutProps> = ({
  otpState,
  setOtpState,
  isProgress,
  handleOtpVerification,
  handleResendOtp,
}) => {
  const { palette } = useTheme();
  const [errors, setErrors] = useState<OtpVerificationErrorModel>(
    new OtpVerificationErrorModel()
  );

  const initialMinutes = 2;
  const initialSeconds = 0;
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  const [restartTimer, setRestartTimer] = useState(false);

  const handleChange = (newValue: string) => {
    setOtpState((prev) => ({ ...prev, OTP: newValue }));
  };

  const resendOTP = () => {
    handleResendOtp();
    setOtpState((prev) => ({ ...prev, otpSent: false }));
  };

  const handleSubmit = (): void => {
    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateOtpVerification(otpState);

    if (ValidateStep?.valid) {
      handleOtpVerification();
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes, restartTimer]);

  useEffect(() => {
    if (errors?.OTP && otpState?.OTP?.trim()) {
      setErrors((prev) => ({ ...prev, OTP: "" }));
    }
  }, [otpState.OTP, errors.OTP]);

  useEffect(() => {
    if (otpState.otpSent) {
      setMinutes(initialMinutes);
      setSeconds(initialSeconds);
      setRestartTimer((prev) => !prev);
      setOtpState((prev) => ({ ...prev, otpSent: false }));
    }
  }, [otpState]);

  return (
    <>
      <Box
        className="tw-text-center tw-max-w-[400px]"
        sx={{ margin: { xs: "auto", md: "0" } }}
      >
        <SectionTitle title={`${otpState.type || "OTP"} Verification`} />
        {otpState.type ? (
          <Typography>Enter OTP received on {otpState.type}</Typography>
        ) : (
          <></>
        )}

        <Box>
          <OtpInput
            code={otpState.OTP || ""}
            handleChange={handleChange}
            maximumLength={6}
          />

          {errors?.OTP ? (
            <Box pt={1}>
              <FormHelperText
                sx={{ color: palette.error.main }}
                className="tw-text-center"
              >
                {errors?.OTP}
              </FormHelperText>
            </Box>
          ) : (
            <></>
          )}
          <Box
            pt={1}
            className="tw-flex tw-items-center tw-justify-center tw-flex-wrap"
          >
            {seconds > 0 || minutes > 0 ? (
              <Typography fontSize={14}>
                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </Typography>
            ) : (
              <Typography fontSize={14}>Didn't receive code?</Typography>
            )}

            <Button
              variant="text"
              disabled={seconds > 0 || minutes > 0}
              sx={{
                margin: "0 10px",
                padding: "6px 10px",
                textTransform: "capitalize",
                opacity: seconds > 0 || minutes > 0 ? 0.7 : 1,
                fontFamily: "inherit",
                lineHeight: 1,
                textDecoration: "underline",
              }}
              onClick={resendOTP}
            >
              Resend code
            </Button>
          </Box>

          <Box pt={5}>
            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
              disabled={
                otpState?.OTP ? otpState?.OTP?.trim()?.length < 6 : false
              }
            >
              {isProgress ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Verify OTP"
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OtpVerificationLayout;
