interface SimManagementFilterModelProps {
  SIM_type?: string;
  serviceType?: string;
  status?: string;
  isReserved?: string;
}

const defaultSimManagementFilterModelProps: SimManagementFilterModelProps = {
  SIM_type: "",
  serviceType: "",
  status: "",
  isReserved: "",
};

export class SimManagementFilterModel {
  SIM_type?: string;
  serviceType?: string;
  status?: string;
  isReserved?: string;

  constructor(
    data: SimManagementFilterModelProps = defaultSimManagementFilterModelProps
  ) {
    this.SIM_type = data.SIM_type || "";
    this.serviceType = data.serviceType || "";
    this.status = data.status || "";
    this.isReserved = data.isReserved || "";
  }
}
