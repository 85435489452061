import ForgotPasswordLayout from "component/auth/forgot-password";
import AuthLayout from "parts/auth";

const ForgotPassword = () => {
  return (
    <AuthLayout>
      <ForgotPasswordLayout />
    </AuthLayout>
  );
};

export default ForgotPassword;
