interface SimCardChargesErrorModelProps {
  simCharges?: string;
  serviceCharges?: string;
  vatPercent?: string;
  MSISDNCategory?: string;
}

export class SimCardChargesErrorModel {
  simCharges?: string;
  serviceCharges?: string;
  vatPercent?: string;
  MSISDNCategory?: string;

  constructor(data: SimCardChargesErrorModelProps = {}) {
    this.simCharges = data.simCharges || "";
    this.serviceCharges = data.serviceCharges || "";
    this.vatPercent = data.vatPercent || "";
    this.MSISDNCategory = data.MSISDNCategory || "";
  }
}
