import ChangePasswordLayout from "component/auth/change-password";
import AuthLayout from "parts/auth";

const ChangePassword = () => {
  return (
    <AuthLayout>
      <ChangePasswordLayout />
    </AuthLayout>
  );
};

export default ChangePassword;
