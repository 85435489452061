import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  AutoCompleteOptionsProps,
  FormInputProps,
} from "_interfaces/_common/forms";
import DatePicker from "component/_common/forms/date";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import React, { Fragment, useMemo, useState } from "react";
import Text from "component/_common/forms/text";
import { CreateAppVersionErrorModel, CreateAppVersionModel } from "_models/data/apps/data.apps.model";
import InputCheckbox from "component/_common/forms/inputCheckbox";
import moment from "moment";
import { CreateNewVersion } from "functions/http-requests/apps";
import { validateAppVersion } from "./formValidator";
import { CreateAppVersionInterface } from "_interfaces/functions/http-requests/apps";
import { toggleLoading } from "state/reducers/loading";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { AppStatusCode } from "config/appStatusCode";

interface InputListProps extends FormInputProps {
  grid?: number;
  name: "currentVersion" | "releaseDate" | "isUpdateMandatory" | "description" | "appName";
}

interface Props {
  handleRefresh: () => void;
  onClose: () => void;
  fetchAllData: () => void;
}

const CurrentVersionForm: React.FC<Props> = ({ handleRefresh, onClose, fetchAllData }) => {
  const [state, setState] = useState<CreateAppVersionModel>(
    new CreateAppVersionModel()
  );
  const [errors, setErrors] = useState<CreateAppVersionErrorModel>(
    new CreateAppVersionErrorModel()
  );
  const Dispatch = useDispatch();
  const appNames: AutoCompleteOptionsProps[] = [
    {
      id: "0",
      title: "KYC",
      value: "KYC",
      isActive: true
    },
    {
      id: "1",
      title: "YO",
      value: "YO",
      isActive: true
    }
  ];

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
          (e: { value: string | number; id: string | number }) =>
            e?.value || e?.id
        )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    state.releaseDate = state.releaseDate ? moment(state.releaseDate).format("YYYY/MM/DD") : moment().format("YYYY/MM/DD")
    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateAppVersion(state);

    if (ValidateStep?.valid) {
      const DATA: CreateAppVersionInterface["DATA"] = {
        currentVersion: state.currentVersion,
        releaseDate: state.releaseDate,
        appName: state.appName,
        description: state.description,
        isUpdateMandatory: state.isUpdateMandatory,
      };
      Dispatch(toggleLoading(true));
      CreateNewVersion({ DATA: DATA }).then(res => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
          fetchAllData();
        }
      }).catch(err => console.log(err))
        .finally(() => Dispatch(toggleLoading(false)));
    }
    else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "text",
        name: "currentVersion",
        label: "Current Version",
        placeholder: "Current Version",
        grid: 6,
      },
      {
        type: "date",
        name: "releaseDate",
        label: "Release Date",
        placeholder: "Release Date",
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "appName",
        label: "App Name",
        placeholder: "Select Region",
        options: appNames,
        multiple: false,
        grid: 6,
        enabled: true,
      },
      {
        type: "checkbox",
        name: "isUpdateMandatory",
        options: [
          {
            id: "0",
            value: "0",
            title:
              "Yes",
            isActive: true,
          },
          {
            id: "1",
            value: "1",
            title:
              "No",
            isActive: true,
          }
        ],
        grid: 6,
      },
      {
        type: "textArea",
        name: "description",
        label: "Description",
        placeholder: "Description",
        minRows: 5,
      },
    ],
    []
  );

  return (
    <>
      <Box
        component="form"
        name="manage_account_report_form"
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                options,
                helperText,
                grid,
                autoComplete,
                disabled,
                multiple,
                minRows
              },
              index
            ) => (
              <Fragment key={index}>
                <>
                  {type === "showTopLabel" ? (
                    <>
                      <Grid item xs={12}>
                        <Typography className="tw-uppercase">
                          {label}
                        </Typography>
                      </Grid>
                    </>
                  ) :
                    type === "text" || type === "textArea" ? (
                      <>
                        <Grid item xs={12} md={grid || 12}>
                          <Text
                            type={type}
                            name={name}
                            label={label}
                            placeholder={placeholder}
                            autoComplete={autoComplete || "off"}
                            value={state[name as keyof CreateAppVersionModel]}
                            errorText={
                              errors[name as keyof CreateAppVersionErrorModel]
                            }
                            onChange={handleChange}
                            onFocus={handleFocus}
                            disabled={disabled}
                            minRows={minRows}
                          />
                        </Grid>
                      </>
                    )
                      : type === "date" ? (
                        <Grid item xs={6} md={6} lg={grid || 6}>
                          <DatePicker
                            name={name}
                            label={label}
                            placeholder={placeholder}
                            value={state[name as keyof CreateAppVersionErrorModel] || moment.now()}
                            errorText={errors[name as keyof CreateAppVersionErrorModel]}
                            onChange={(value) => {
                              setState((prev) => ({
                                ...prev,
                                [name]: value,
                              }));
                              setErrors((prev) => ({
                                ...prev,
                                [name]: "",
                              }));
                            }}
                          />
                        </Grid>
                      ) : type === "checkbox" ? (
                        <Grid item xs={6}>
                          <Box className="tw-flex" sx={{ margin: "2rem 0 0 3.6rem" }}>
                            <InputCheckbox
                              name={name}
                              label={label}
                              value={state?.isUpdateMandatory}
                              onChange={handleChange}
                            />

                            <Typography className="tw-flex tw-items-center" sx={{ marginLeft: "-16.8px" }}>
                              Update Mandatory
                            </Typography>
                          </Box>
                        </Grid>
                      ) : type === "autoComplete" ? (
                        <Grid item xs={12} md={5}>
                          <InputAutoComplete
                            name={name}
                            options={options || []}
                            label={label}
                            placeholder={placeholder}
                            onChange={(e, v, m) =>
                              handleAutoComplete(e, v, name, m)
                            }
                            value={
                              options &&
                              options.length &&
                              state?.[name as keyof CreateAppVersionModel]
                            }
                            errorText={
                              errors[
                              name as keyof CreateAppVersionErrorModel
                              ]
                            }
                            onFocus={handleFocus}
                            multiple={multiple}
                          />
                        </Grid>
                      ) : (
                        <></>
                      )}
                </>
              </Fragment>
            )
          )}

          <Grid item xs={12} className="tw-pt-[40px]">
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{
                minWidth: 150,
              }}
            >
              Create Version
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CurrentVersionForm;
