import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";
import { ChartStatusInterface } from "_interfaces/_common/charts";
import React, { useMemo } from "react";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  TooltipProps,
  XAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

const chartData = [
  { name: "M1", value: 400 },
  { name: "M2", value: 150 },
  { name: "M3", value: 300 },
  { name: "M4", value: 100 },
  { name: "M5", value: 350 },
  { name: "M6", value: 250 },
  { name: "M7", value: 200 },
  { name: "M8", value: 280 },
];

interface Props extends ChartStatusInterface {
  data?: object[];
}
const CustomBarChart: React.FC<Props> = ({ data = chartData, status }) => {
  const { palette } = useTheme();
  const color = useMemo(
    () =>
      status === "success"
        ? palette.success.main
        : status === "error"
        ? palette.error.main
        : alpha(palette.secondary.main, 0.1),
    [status, palette.success.main, palette.error.main, palette.secondary.main]
  );
  return (
    <>
      <ResponsiveContainer>
        <BarChart data={data}>
          {/* <CartesianGrid vertical={false} /> */}
          <XAxis dataKey="name" />
          {/* <YAxis /> */}

          {/* <Tooltip cursor={false} content={<CustomTooltip status={status} />} /> */}
          <Bar dataKey="value" fill={palette.primary.main} barSize={20} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

interface CustomTooltipProps
  extends ChartStatusInterface,
    TooltipProps<ValueType, NameType> {
  active?: boolean;
  payload?: any;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  status,
}) => {
  const { palette } = useTheme();

  if (active && payload && payload.length) {
    return (
      <Box
        className="tw-rounded-3xl tw-min-w-[60px] tw-py-[4px] tw-px-[10px] tw-text-center"
        sx={{
          backgroundColor:
            status === "success"
              ? alpha(palette.success.main, 0.2)
              : status === "error"
              ? alpha(palette.error.main, 0.2)
              : "",
        }}
      >
        <Typography
          className=""
          fontSize={14}
          fontWeight="bold"
          sx={{
            color:
              status === "success"
                ? palette.success.main
                : status === "error"
                ? palette.error.main
                : "",
          }}
        >
          {payload[0].value} req
        </Typography>
      </Box>
    );
  }

  return null;
};

export default CustomBarChart;
