import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { OtpVerificationModel } from "_models/data/auth/data.otpVerification.model";
import { TwoWayAuthModel } from "_models/data/manage-account/data.two-way-auth.model";
import OtpVerificationLayout from "component/_common/otpVerification";
import SectionTitle from "parts/sectionTitle";
import { useState } from "react";
import TwoWayAuthForm from "./twoWayAuthForm";
import axios from "axios";

const TwoWayAuthLayout = () => {
  const [isProgress, setIsProgress] = useState<boolean>(false);

  const [steps, setSteps] = useState<{
    needVerification: "both" | "email" | "sms" | "none";
    otpVerified: boolean;
  }>({
    needVerification: "none",
    otpVerified: false,
  });

  const [state, setState] = useState<TwoWayAuthModel>(new TwoWayAuthModel());
  const [otpState, setOtpState] = useState<OtpVerificationModel>(
    new OtpVerificationModel()
  );

  const handleOtpVerification = () => {
    // if (otpState?.token && otpState?.OTP && otpState?.type) {
    //   setIsProgress(true);
    //   VerifyOtpPasswordRequest({
    //     DATA: {
    //       token: otpState?.token,
    //       otp: otpState?.OTP,
    //       type: otpState?.type,
    //     },
    //   })
    //     .then((res) => {
    //       if (res?.data?.statusCode === AppStatusCode.api_success) {
    //         Dispatch(
    //           setAlert({
    //             type: res?.data?.level || "success",
    //             message: res?.data?.message,
    //           })
    //         );
    //         setSteps({
    //           needVerification: "none",
    //           otpVerified: true,
    //         });
    //       } else {
    //         setOtpState((prev) => ({ ...prev, OTP: "" }));
    //         Dispatch(
    //           setAlert({
    //             type: res?.data?.level || "error",
    //             message: res?.data?.message,
    //           })
    //         );
    //       }
    //     })
    //     .catch((error) =>
    //       {
    //   if (!axios.isCancel(error))
    //     Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
    // }
    //     )
    //     .finally(() => setIsProgress(false));
    // }
  };

  const handleResendOtp = () => {
    // if (otpState?.token && otpState.type) {
    //   Dispatch(toggleLoading(true));
    //   ResendPasswordRequestOtp({
    //     DATA: {
    //       token: otpState.token,
    //       type: otpState.type,
    //     },
    //   })
    //     .then((res) => {
    //       const data = res?.data?.data;
    //       if (data) {
    //         setSteps((prev) => ({
    //           ...prev,
    //           needVerification: data?.type,
    //         }));
    //         setOtpState((prev) => ({
    //           ...prev,
    //           OTP: "",
    //           otpSent: true,
    //         }));
    //       }
    //       Dispatch(
    //         setAlert({
    //           type: res?.data?.level || "success",
    //           message: res?.data?.message,
    //         })
    //       );
    //     })
    //     .catch((error) =>
    //       {
    //   if (!axios.isCancel(error))
    //     Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
    // }
    //     )
    //     .finally(() => Dispatch(toggleLoading(false)));
    // } else {
    //   Dispatch(
    //     setAlert({
    //       type: "error",
    //       message: `Mandatory fields missing!`,
    //     })
    //   );
    // }
  };

  return (
    <>
      <Container maxWidth="xs">
        <Box py={5} className="tw-text-center">
          <SectionTitle title="Enable Two Way Auth" />
        </Box>

        {steps?.needVerification === "none" ? (
          <TwoWayAuthForm
            isProgress={isProgress}
            state={state}
            setState={setState}
          />
        ) : (
          <>
            <OtpVerificationLayout
              otpState={otpState}
              setOtpState={setOtpState}
              isProgress={isProgress}
              handleOtpVerification={handleOtpVerification}
              handleResendOtp={handleResendOtp}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default TwoWayAuthLayout;
