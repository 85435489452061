type ChangePasswordProps = {
  password?: string;
  confirmPassword?: string;
};

export class ChangePasswordModel {
  password?: string;
  confirmPassword?: string;

  constructor(data: ChangePasswordProps = {}) {
    this.password = data.password || "";
    this.confirmPassword = data.confirmPassword || "";
  }
}
