import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { GridPaginationModel } from "@mui/x-data-grid";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { GetAllCustomers } from "functions/http-requests/customer-management";
import moment from "moment";
import FilterButton from "parts/filterButton";
import SectionSearch from "parts/sectionSearch";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CustomerManagementTableInterface from "_interfaces/customer-management/customerManagementTable";
import { GetAllCustomersProps } from "_interfaces/functions/http-requests/customer-management";
import { CustomerManagementFilterModel } from "_models/data/customer-management/data.customer-management-filter.model";
import CustomerManagementFilter from "./customerManagementFilter";
import CustomerManagementTable from "./customerManagementTable";

const CustomerManagementLayout = () => {
  const isInitialRender = useRef(true);
  const Dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [data, setData] = useState<CustomerManagementTableInterface["data"]>(
    []
  );

  const [refresh, setRefresh] = useState<boolean>(false);

  const [searchKey, setSearchKey] = useState<string>("");

  const [count, setCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filters, setFilters] = useState<CustomerManagementFilterModel>(
    new CustomerManagementFilterModel()
  );

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleRefresh = () => setRefresh(!refresh);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchKey(value);
  };

  const handleClearSearch = () => setSearchKey("");

  const handleSubmitSearch = () => {
    isInitialRender.current = false;
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
    handleRefresh();
  };

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);
      let PAYLOAD_DATA: GetAllCustomersProps["DATA"] = {
        searchKey: searchKey,
        kycStatus: filters?.kycStatus || undefined,
        serviceStatus: filters?.serviceStatus || undefined,
        customerType: filters?.customerType || undefined,
        identityType: filters?.identityType || undefined,
        fromDate: filters?.fromDate
          ? moment(filters.fromDate).format("YYYY/MM/DD")
          : undefined,
        toDate: filters?.toDate
          ? moment(filters.toDate).format("YYYY/MM/DD")
          : undefined,
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
      };

      let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      GetAllCustomers({
        DATA: FILTERED_PAYLOAD_DATA,
      })
        .then((res) => {
          const data = res?.data;

          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count;
            if (count && count > 1) {
              if (count > 1) setCount(count);
            } else setCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              MSISDN: item?.MSISDN || "",
              nameAsDocument: item?.nameAsDocument || "",
              identityType: item?.identityType || "",
              kyc_Details: item?.kyc_Details,
              MSISDN_details: item?.MSISDN_details,
              createdAt: item?.createdAt || "",
            }));
            setData(DATA);
          } else {
            setData([]);
            setCount(0);
          }
        })
        .catch(() => {
          setData([]);
          setCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [paginationModel, refresh, filters]);

  useEffect(() => {
    if (!isInitialRender.current) {
      if (!searchKey) handleRefresh();
    }
  }, [searchKey]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
          <Box sx={{ flex: "1 1 auto" }}>
            <SectionSearch
              name="search_sim"
              value={searchKey}
              onChange={handleSearch}
              onClear={handleClearSearch}
              onSubmit={handleSubmitSearch}
            />
          </Box>
          <FilterButton onClick={() => setOpenFilter(true)} />
        </Grid>
      </Grid>

      <Box pt={3}>
        <CustomerManagementTable
          data={data}
          loading={dataLoading}
          count={count}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      </Box>

      <CustomerManagementFilter
        open={openFilter}
        onClose={toggleFilter}
        filters={filters}
        setFilters={setFilters}
      />
    </>
  );
};

export default CustomerManagementLayout;
