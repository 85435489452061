import React from "react";
import { IconsInterface } from "_interfaces/_common/icons";

const DashboardIcon: React.FC<IconsInterface> = ({ width, height, color }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "21"}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M14.0756 0.500366H17.4616C18.8639 0.500366 20.0001 1.64622 20.0001 3.06033V6.47489C20.0001 7.889 18.8639 9.03485 17.4616 9.03485H14.0756C12.6734 9.03485 11.5371 7.889 11.5371 6.47489V3.06033C11.5371 1.64622 12.6734 0.500366 14.0756 0.500366Z"
        fill={color}
      />
      <path
        d="M2.53852 0.500366H5.92449C7.32676 0.500366 8.46301 1.64622 8.46301 3.06033V6.47489C8.46301 7.889 7.32676 9.03485 5.92449 9.03485H2.53852C1.13626 9.03485 0 7.889 0 6.47489V3.06033C0 1.64622 1.13626 0.500366 2.53852 0.500366Z"
        fill={color}
      />
      <path
        d="M2.53852 11.9652H5.92449C7.32676 11.9652 8.46301 13.1111 8.46301 14.5252V17.9397C8.46301 19.3529 7.32676 20.4997 5.92449 20.4997H2.53852C1.13626 20.4997 0 19.3529 0 17.9397V14.5252C0 13.1111 1.13626 11.9652 2.53852 11.9652Z"
        fill={color}
      />
      <path
        d="M14.0756 11.9652H17.4616C18.8639 11.9652 20.0001 13.1111 20.0001 14.5252V17.9397C20.0001 19.3529 18.8639 20.4997 17.4616 20.4997H14.0756C12.6734 20.4997 11.5371 19.3529 11.5371 17.9397V14.5252C11.5371 13.1111 12.6734 11.9652 14.0756 11.9652Z"
        fill={color}
      />
    </svg>
  );
};

export default DashboardIcon;
