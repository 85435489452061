import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/material/styles";

interface Props {
  name?: string;
  label?: string;
  value?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorText?: string;
  icon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
}

const InputCheckbox: React.FC<Props> = ({
  name,
  label,
  value,
  onChange,
  errorText,
  icon,
  checkedIcon,
}) => {
  const { palette } = useTheme();
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={value}
            onChange={onChange}
            icon={icon || <RadioButtonUncheckedIcon />}
            checkedIcon={checkedIcon || <RadioButtonCheckedIcon />}
          />
        }
        label={label}
      />
      {errorText ? (
        <FormHelperText
          className="tw-mt-[3px] tw-mx-[14px]"
          sx={{ color: palette.error.main }}
        >
          {errorText}
        </FormHelperText>
      ) : (
        <></>
      )}
    </>
  );
};

export default InputCheckbox;
