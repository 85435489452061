interface Props {
  kycStatus?: "pending" | "rejected" | "success" | "";
  serviceStatus?:
    | "inprogress"
    | "active"
    | "notActive"
    | "rejected"
    | "blocked"
    | "";
  customerType?: "individual" | "company" | "";
  identityType?: "citizen" | "tourist" | "refugee" | "";
  fromDate?: Date | null; // YYYY/MM/DD
  toDate?: Date | null; // YYYY/MM/DD
}

const defaultProps: Props = {
  kycStatus: "",
  serviceStatus: "",
  customerType: "",
  identityType: "",
  fromDate: null,
  toDate: null,
};

export class CustomerManagementFilterModel {
  kycStatus?: "pending" | "rejected" | "success" | "";
  serviceStatus?:
    | "inprogress"
    | "active"
    | "notActive"
    | "rejected"
    | "blocked"
    | "";
  customerType?: "individual" | "company" | "";
  identityType?: "citizen" | "tourist" | "refugee" | "";
  fromDate?: Date | null; // YYYY/MM/DD
  toDate?: Date | null; // YYYY/MM/DD

  constructor(data: Props = defaultProps) {
    this.kycStatus = data.kycStatus || "";
    this.serviceStatus = data.serviceStatus || "";
    this.customerType = data.customerType || "";
    this.identityType = data.identityType || "";
    this.fromDate = data.fromDate || null;
    this.toDate = data.toDate || null;
  }
}
