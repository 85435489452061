import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import InventoryUsersDetailsTableInterface from "_interfaces/inventory-users/details/inventoryUsersDetailsTable";
import { IsActiveCell } from "parts/table/customCell";
import DetailsTable from "parts/table/detailsTable";
import React from "react";

interface Props {
  data: InventoryUsersDetailsTableInterface | null;
}

const InventoryUsersDetailsTable: React.FC<Props> = ({ data }) => {
  return (
    <>
      {data?.id ? (
        <DetailsTable>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{data?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>{data?.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Phone</TableCell>
            <TableCell>{data?.phone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Role</TableCell>
            <TableCell>{data?.role}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              <IsActiveCell status={data?.isActive} />
            </TableCell>
          </TableRow>
        </DetailsTable>
      ) : (
        <></>
      )}
    </>
  );
};

export default InventoryUsersDetailsTable;
