import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type CameraState = {
  isCameraPageLoaded: boolean;
  isCameraAllowed: boolean;
};

const initialState: CameraState = {
  isCameraPageLoaded: false,
  isCameraAllowed: false,
};

const cameraSlice = createSlice({
  name: "camera",
  initialState,
  reducers: {
    toggleCameraPageLoaded(state, action: PayloadAction<boolean>) {
      if (action.payload) state.isCameraPageLoaded = true;
      else state.isCameraPageLoaded = false;
    },
    toggleCameraStatus(state, action: PayloadAction<boolean>) {
      if (action.payload) state.isCameraAllowed = true;
      else state.isCameraAllowed = false;
    },
  },
});

export const { toggleCameraPageLoaded, toggleCameraStatus } =
  cameraSlice.actions;

export default cameraSlice.reducer;
