import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  FormLabel,
  IconButton,
  InputAdornment,
  lighten,
  Popover,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";

interface CustomDateRangePickerProps {
  label?: string;
  value: [Date | null, Date | null];
  onChange: (newValue: [Date | null, Date | null]) => void;
}

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  label,
  value,
  onChange,
}) => {
  const { palette } = useTheme();

  const today = new Date();

  const [startDate, setStartDate] = useState<Date | null>(value[0]);
  const [endDate, setEndDate] = useState<Date | null>(value[1]);
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const [currentMonth, setCurrentMonth] = useState<Moment>(
    moment().startOf("month")
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showYearSelector, setShowYearSelector] = useState<boolean>(false);

  const [transitionDirection, setTransitionDirection] = useState<string | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowYearSelector(false);
  };

  const open = Boolean(anchorEl);

  const handleDateClick = (date: Date) => {
    let selectedMomentDate = moment(date);
    if (!startDate) {
      setStartDate(date);
      return;
    }

    if (!endDate) {
      if (selectedMomentDate.isAfter(startDate)) {
        setEndDate(date);
      } else {
        setEndDate(startDate);
        setStartDate(date);
      }
      return;
    }

    if (selectedMomentDate.isBefore(startDate)) {
      setStartDate(date);
    } else if (selectedMomentDate.isAfter(endDate)) {
      setEndDate(date);
    } else if (
      selectedMomentDate.isAfter(startDate) &&
      selectedMomentDate.isBefore(endDate)
    ) {
      setStartDate(date);
    } else if (
      selectedMomentDate.isSame(startDate) ||
      selectedMomentDate.isSame(endDate)
    ) {
      setStartDate(date);
      setEndDate(date);
    } else {
      setEndDate(date);
    }
  };

  const isDateInRange = (date: Date) => {
    if (!startDate || !endDate) return false;

    let momentDate = moment(date);
    if (startDate && !endDate && hoveredDate) {
      return (
        (momentDate.isAfter(startDate) && momentDate.isBefore(hoveredDate)) ||
        (momentDate.isBefore(startDate) && momentDate.isAfter(hoveredDate))
      );
    }

    if (startDate && endDate) {
      return (
        (momentDate.isBefore(startDate) && momentDate.isAfter(hoveredDate)) ||
        (momentDate.isAfter(startDate) && momentDate.isBefore(hoveredDate)) ||
        (momentDate.isAfter(endDate) && momentDate.isBefore(startDate)) ||
        (momentDate.isAfter(startDate) && momentDate.isBefore(endDate))
      );
    }

    return false;
  };

  const renderCalendar = () => {
    const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];
    const daysInMonth = currentMonth.daysInMonth();
    const startOfMonth = currentMonth.startOf("month").day();
    const days = [];

    // Render the days of the week
    daysOfWeek.forEach((day) => {
      days.push(
        <Box
          key={day}
          sx={{
            width: { xs: 32, sm: 40 },
            height: { xs: 32, sm: 40 },
            fontSize: 14,
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: palette.text.secondary,
          }}
        >
          {day}
        </Box>
      );
    });

    // Add empty boxes for days before the start of the month
    for (let i = 0; i < startOfMonth; i++) {
      days.push(
        <Box
          key={`empty-${i}`}
          sx={{ width: { xs: 32, sm: 40 }, height: { xs: 32, sm: 40 } }}
        />
      );
    }

    // Render the days of the current month
    for (let i = 1; i <= daysInMonth; i++) {
      const date = currentMonth.date(i).toDate();

      const isSelected =
        startDate?.getTime() === date.getTime() ||
        endDate?.getTime() === date.getTime();

      const isInRange = isDateInRange(date);
      const isHoveredRange = isDateInRange(date);

      const isToday =
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();

      days.push(
        <Box
          key={`${date.toDateString()}-${i}`}
          className={`${date}`}
          onClick={() => handleDateClick(date)}
          onMouseEnter={() => setHoveredDate(date)}
          onMouseLeave={() => setHoveredDate(null)}
          sx={{
            width: { xs: 32, sm: 40 },
            height: { xs: 32, sm: 40 },
            fontSize: 14,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            backgroundColor: isSelected
              ? palette.primary.main
              : isInRange || isHoveredRange
              ? lighten(palette.text.primary, 0.8)
              : isToday
              ? lighten(palette.primary.main, 0.2)
              : "transparent",
            color: isSelected ? palette.primary.contrastText : "#000",
            cursor: "pointer",
            transition: "background-color 0.3s, color 0.3s",
          }}
        >
          {date.getDate()}
        </Box>
      );
    }

    return days;
  };

  const handlePrevMonth = () => {
    setTransitionDirection("left");
    setTimeout(() => {
      setCurrentMonth((prevMonth) => prevMonth.subtract(1, "month"));
      setTransitionDirection(null);
    }, 150);
  };

  const handleNextMonth = () => {
    setTransitionDirection("right");
    setTimeout(() => {
      setCurrentMonth((prevMonth) => prevMonth.add(1, "month"));
      setTransitionDirection(null);
    }, 150);
  };

  const toggleYearSelector = () => {
    setShowYearSelector(!showYearSelector);
  };

  const handleYearSelection = (year: number) => {
    setCurrentMonth(currentMonth.year(year));
    setShowYearSelector(false);
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    onChange([null, null]);
  };

  useEffect(() => {
    // Set the current month to the start date's month if available, otherwise to the current month
    if (startDate) {
      setCurrentMonth(moment(startDate).startOf("month"));
    } else {
      setCurrentMonth(moment().startOf("month"));
    }
  }, [startDate]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {label ? (
          <FormLabel
            className="tw-mb-[6px] tw-block"
            sx={{ color: palette.secondary.main }}
          >
            {label}
          </FormLabel>
        ) : (
          <></>
        )}
        <TextField
          value={`${
            startDate ? moment(startDate).format("Do MMM, YY") : "Start Date"
          } - ${endDate ? moment(endDate).format("Do MMM, YY") : "End Date"}`}
          onClick={handleClick}
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {startDate || endDate ? (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClear();
                    }}
                    size="small"
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <></>
                )}
              </InputAdornment>
            ),
          }}
          sx={{ cursor: "pointer" }}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box p={2}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  onClick={toggleYearSelector}
                  sx={{
                    cursor: "pointer",
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                    color: palette.common.black,
                  }}
                >
                  {currentMonth.format("MMMM YYYY")}
                  {showYearSelector ? (
                    <KeyboardArrowUp sx={{ ml: 1 }} />
                  ) : (
                    <KeyboardArrowDown sx={{ ml: 1 }} />
                  )}
                </Typography>
                <Box>
                  <IconButton onClick={handlePrevMonth} sx={{ p: 1 }}>
                    <KeyboardArrowLeft />
                  </IconButton>
                  <IconButton onClick={handleNextMonth} sx={{ p: 1 }}>
                    <KeyboardArrowRight />
                  </IconButton>
                </Box>
              </Box>
              <Box
                width={{
                  xs: 280,
                  sm: 304,
                }}
                height={{ xs: 300, sm: 350 }}
                position="relative"
                display="flex"
                flexDirection="column"
              >
                {showYearSelector ? (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gap: 0.5,
                      height: "100%",
                      overflowY: "auto",
                    }}
                  >
                    {Array.from({ length: 200 }, (_, i) => 1900 + i).map(
                      (year) => (
                        <Button
                          key={year}
                          onClick={() => handleYearSelection(year)}
                          variant={
                            year === currentMonth.year() ? "contained" : "text"
                          }
                          color={
                            year === currentMonth.year() ? "primary" : "inherit"
                          }
                          sx={{
                            minWidth: "unset",
                            borderRadius: 30,
                          }}
                        >
                          {year}
                        </Button>
                      )
                    )}
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(7, 1fr)",
                        gap: 0.5,
                        width: "100%",
                        position: "relative",
                        overflow: "hidden",
                        height: "100%", // Ensure height is set
                      }}
                    >
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(7, 1fr)",
                          gap: 0.5,
                          position: "absolute",
                          top: 0,
                          left:
                            transitionDirection === "left"
                              ? "-100%"
                              : transitionDirection === "right"
                              ? "100%"
                              : "0",
                          transition: "left 0.15s ease-in-out",
                          width: "100%", // Ensure it occupies full width
                          height: "100%", // Ensure it occupies full height
                        }}
                      >
                        {renderCalendar()}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: "auto",
                      }}
                    >
                      <Button
                        onClick={() => {
                          setStartDate(null);
                          setEndDate(null);
                          setHoveredDate(null);
                          handleClose();
                        }}
                        variant="text"
                        color="error"
                        size="small"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          onChange([startDate, endDate]);
                          handleClose();
                        }}
                        variant="text"
                        color="primary"
                        size="small"
                      >
                        Ok
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Popover>
      </LocalizationProvider>
    </>
  );
};

export default CustomDateRangePicker;
