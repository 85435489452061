import SearchInformationLayout from "component/search-information";
import MainContainer from "parts/mainContainer";

const SearchInformation = () => {
  return (
    <MainContainer>
      <SearchInformationLayout />
    </MainContainer>
  );
};

export default SearchInformation;
