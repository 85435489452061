import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import Text from "component/_common/forms/text";
import { BLOCKUNBLOCKINTERSWITCHEVALUE } from "functions/http-requests/inter-switch-e-value";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";

interface IBlockDialog {
    open: boolean;
    onClose: () => void,
    activation: boolean,
    fetchAllData: () => void
}

const BlockDialog: React.FC<IBlockDialog> = ({ open, onClose, activation, fetchAllData }) => {
    const Dispatch = useDispatch();

    const handleBlock = async () => {
        Dispatch(toggleLoading(true));
        await BLOCKUNBLOCKINTERSWITCHEVALUE({ DATA: { activation: activation ? false : true } }).then(res => {
            if (res.data.level !== "success") {
                Dispatch(setAlert({ type: "error", message: res.data.message }));
                return;
            };
            Dispatch(setAlert({ type: "success", message: res.data.message }));
        })
            .catch((err: any) => Dispatch(setAlert({ type: "error", message: err.response.data.message })))
            .finally(() => Dispatch(toggleLoading(false)));
        fetchAllData();
        onClose();
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent>
                    Are you sure you want to {activation ? "block" : "unblock"} this wallet?
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={handleBlock} autoFocus sx={{ color: "#D10606" }}>
                        {activation ? "Block" : "Unblock"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default BlockDialog;