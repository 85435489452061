export interface ManageAccountProps {
  fullName: string;
  userId: string;
  role: string;
}

const defaultManageAccountProps: ManageAccountProps = {
  fullName: "",
  userId: "",
  role: "",
};

export class ManageAccountModel {
  fullName: string;
  userId: string;
  role: string;

  constructor(data: ManageAccountProps = defaultManageAccountProps) {
    this.fullName = data.fullName || "";
    this.userId = data.userId || "";
    this.role = data.role || "";
  }
}
