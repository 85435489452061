import { Card, Box, Typography } from "@mui/material";
import { LatestAppVersionData } from "_interfaces/apps";
import moment from "moment";

interface Props {
    data: LatestAppVersionData | null;
    heading: string
}

const CurrentVersionDetails: React.FC<Props> = ({ data, heading }) => {

    return (
        <Card className="tw-mt-2">
            <Box className="tw-p-4" sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", fontSize: '18.5px' }}>
                <Typography sx={{ fontSize: '25px', fontWeight: "bold", textDecoration: "underline" }}>{heading}</Typography>
                <p>
                    <strong>Version:{" "}
                        <Typography component="span" sx={{ fontSize: '25px', fontWeight: "bold" }}>
                            {data?.currentVersion}
                        </Typography>
                    </strong>
                </p>
                <p>
                    <strong>Release Date:{" "}
                        <Typography component="span" sx={{ fontSize: '18px' }}>
                            {moment(data?.releaseDate).format("Do MMM, YYYY")}
                        </Typography>
                    </strong>
                </p>
                <p>
                    <strong>Mandatory:{" "}
                        <Typography component="span" sx={{ fontSize: '18px' }}>
                            {data?.isUpdateMandatory ? "Yes" : "No"}
                        </Typography>
                    </strong>
                </p>
                <p>
                    <strong>Description:{" "}
                        <Typography component="span" sx={{ fontSize: '16px' }}>
                            {data?.description}
                        </Typography>
                    </strong>
                </p>
            </Box>
        </Card>
    )
}

export default CurrentVersionDetails;