import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { useTheme } from "@mui/material/styles";
import React from "react";
import NotificationCard from "./notificationCard";

interface Props {
  id?: string;
  anchorEl?: HTMLButtonElement | null;
  open: boolean;
  onClose: () => void;
}
const Notifications: React.FC<Props> = ({ id, anchorEl, open, onClose }) => {
  const { palette } = useTheme();
  return (
    <Popover
      id={id}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box py={2} pr={1}>
        <Box
          px={2}
          sx={{
            maxHeight: 400,
          }}
          className="tw-overflow-auto"
        >
          <NotificationCard isNew />
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
        </Box>
      </Box>
      <Box pb={2} className="tw-text-center">
        <Button
          variant="text"
          sx={{ color: palette.text.secondary }}
          className="tw-capitalize"
        >
          Clear All
        </Button>
      </Box>
    </Popover>
  );
};

export default Notifications;
