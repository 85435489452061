import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React from "react";
import LogoImage from "assets/images/logo.png";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { RouteConstant } from "navigation/constant";
import { alpha } from "@mui/material/styles";

interface Props {
  children: React.ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  return (
    <Grid container className="tw-min-h-[100dvh]">
      <Grid
        item
        xs={12}
        md={6}
        sx={{ backgroundColor: ({ palette }) => palette.common.white }}
        className="tw-flex"
      >
        <Container className="tw-flex tw-flex-col tw-justify-between tw-py-[30px]">
          <Box className="tw-flex tw-justify-center tw-items-center tw-flex-auto">
            <img
              src={LogoImage}
              alt="Talkio Mobile"
              className="tw-max-w-[280px]"
            />
          </Box>

          <Box display={{ xs: "none", md: "block" }}>
            <Typography
              color={({ palette }) => palette.text.primary}
              fontWeight={400}
              className="tw-text-center"
            >
              Need help with Log in ?{" "}
              <Box color="primary.main" fontWeight={600} component="span">
                <Link to={RouteConstant.CONTACT_US}> Contact admin</Link>
              </Box>
            </Typography>
          </Box>
        </Container>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: ({ palette }) => palette.secondary.main,
          color: ({ palette }) => alpha(palette.common.white, 0.6),
        }}
        className="tw-flex"
      >
        <Container className="tw-flex tw-flex-col tw-justify-between tw-py-[30px]">
          <Box className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-flex-auto">
            <Box>{children}</Box>
          </Box>
          <Box pt={5}>
            <Box className="tw-flex tw-justify-between tw-items-center">
              <Link to="#">FAQ</Link>
              <Link to="#">Blog</Link>
              <Link to="#">Distributor Policy</Link>
              <Link to="#">Become Distributor</Link>
            </Box>
          </Box>
          <Box
            pt={8}
            display={{ xs: "block", md: "none" }}
            className="tw-text-center"
          >
            <Typography
              color={({ palette }) => palette.text.primary}
              fontWeight={400}
              className="tw-text-center"
            >
              Need help with Log in ?{" "}
              <Box color="primary.main" fontWeight={600} component="span">
                <Link to={RouteConstant.CONTACT_US}> Contact admin</Link>
              </Box>
            </Typography>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
