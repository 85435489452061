import { Box, Card, Typography } from "@mui/material";
import { IInterSwitchEValueCard } from "_interfaces/inter-switch-e-value/InterSwitchEValueCard";

interface IInterSwitchEValueCardTemp {
    data: IInterSwitchEValueCard,
}

const InterSwitchEValueCard: React.FC<IInterSwitchEValueCardTemp> = ({ data }) => {

    return (
        <>
            <Card className="tw-mt-2">
                <Box className="tw-p-4" sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                    <p>
                        <strong>Wallet ID:{" "}
                            <Typography component="span" sx={{ fontSize: '21px', fontWeight: "bold" }}>
                                {data?.walletID}
                            </Typography>
                        </strong>
                    </p>
                    <p>
                        <strong>Balance:{" "}
                            <Typography component="span" sx={{ fontSize: '21px', fontWeight: "bold" }}>
                                {data?.balance}
                            </Typography>
                        </strong>
                    </p>
                    <p>
                        <strong>Status:{" "}
                            <Typography component="span" sx={{ fontSize: '21px', fontWeight: "bold" }}>
                                {data?.isActive ? "Active" : "InActive"}
                            </Typography>
                        </strong>
                    </p>
                </Box>
            </Card>
        </>
    )
}

export default InterSwitchEValueCard;