import EditIcon from "@mui/icons-material/Edit";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import ProfileImage from "assets/images/profile.png";
import PasswordIcon from "component/_common/icons/password";
import ReportIcon from "component/_common/icons/report";
import SectionTitle from "parts/sectionTitle";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ManageAccountForm from "./manageAccountForm";
import { RouteConstant } from "navigation/constant";

interface LinkProps {
  path?: string;
  Icon: React.ReactNode;
  title: string;
  subTitle?: string;
  subTitleColor?: string;
}

const ManageAccountLayout = () => {
  const { palette } = useTheme();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const toggleEdit = () => setIsEditing(!isEditing);

  const settingsLinkList: LinkProps[] = [
    {
      title: "Enable Two-way auth",
      Icon: <ShieldOutlinedIcon color="inherit" />,
      subTitle: "Enabled",
      subTitleColor: palette.success.main,
      path: RouteConstant.MANAGE_ACCOUNT_TWO_WAY_AUTH,
    },
    {
      title: "Change Password",
      Icon: <PasswordIcon color={palette.secondary.main} />,
      path: "",
    },
    {
      title: "Notifications & Alerts",
      Icon: <NotificationsActiveOutlinedIcon color="inherit" />,
      subTitle: "Enabled All",
      subTitleColor: palette.success.main,
      path: RouteConstant.MANAGE_ACCOUNT_NOTIFICATION,
    },
  ];

  const additionalServicesLinkList: LinkProps[] = [
    {
      title: "Download Report",
      Icon: <ReportIcon color={palette.secondary.main} />,
      subTitle: "Last report downloaded on 13 May 24",
      path: RouteConstant.MANAGE_ACCOUNT_REPORT,
    },
  ];

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={2}>
            <Box className="tw-w-[80px] tw-h-[80px] tw-rounded-full tw-relative">
              <img
                src={ProfileImage}
                alt="Profile"
                className="tw-w-[100%] tw-h-[100%] tw-object-top tw-object-cover"
              />

              <IconButton
                onClick={toggleEdit}
                size="small"
                className="tw-absolute tw-right-0 tw-bottom-0"
                sx={{
                  backgroundColor: palette.grey[200],
                  "&:hover, :focus": {
                    backgroundColor: palette.grey[200],
                  },
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={10}>
            <Box>
              <ManageAccountForm
                isEditing={isEditing}
                toggleEdit={toggleEdit}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box pt={8}>
        <SectionTitle
          title="Setting"
          variant="h2"
          fontSize={18}
          fontWeight={500}
        />

        <Box pt={3}>
          <Grid container spacing={3}>
            {settingsLinkList.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <LinkCard {...item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <Box pt={8}>
        <SectionTitle
          title="Additional Services"
          variant="h2"
          fontSize={18}
          fontWeight={500}
        />

        <Box pt={3}>
          <Grid container spacing={3}>
            {additionalServicesLinkList.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <LinkCard {...item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ManageAccountLayout;

const LinkCard: React.FC<LinkProps> = ({
  path,
  Icon,
  title,
  subTitle,
  subTitleColor,
}) => {
  const { palette } = useTheme();

  return (
    <>
      <Link to={path || ""} className="tw-w-full tw-h-[100%] tw-block">
        <Button
          color="secondary"
          fullWidth
          variant="outlined"
          className="tw-p-[10px] tw-justify-start tw-text-left tw-text-[14px] tw-h-[inherit]"
        >
          <Box className="tw-w-[40px]" color={palette.secondary.main}>
            {Icon}
          </Box>
          <Box className="tw-flex tw-flex-col">
            <Box component="span">{title}</Box>
            <Box
              component="span"
              className="tw-text-[12px]"
              color={subTitleColor || "inherit"}
            >
              {subTitle}
            </Box>
          </Box>
        </Button>
      </Link>
    </>
  );
};
