export interface CreateReportErrorProps {
  reportType: string;
  startDate: string;
  endDate: string;
}

const defaultCreateReportErrorProps: CreateReportErrorProps = {
  reportType: "",
  startDate: "",
  endDate: "",
};

export class CreateReportErrorModel {
  reportType: string;
  startDate: string;
  endDate: string;

  constructor(data: CreateReportErrorProps = defaultCreateReportErrorProps) {
    this.reportType = data.reportType || "";
    this.startDate = data.startDate || "";
    this.endDate = data.endDate || "";
  }
}
