interface RegionalDistributorsErrorModelProps {
  name?: string;
  email?: string;
  phone?: string;
  region?: string;
  districts?: string;
  assignedLocation?: string;
  password?: string;
  confirmPassword?: string;
}

const defaultRegionalDistributorsErrorModelProps: RegionalDistributorsErrorModelProps =
  {
    name: "",
    email: "",
    phone: "",
    region: "",
    districts: "",
    assignedLocation: "",
    password: "",
    confirmPassword: "",
  };

export class RegionalDistributorsErrorModel {
  name?: string;
  email?: string;
  phone?: string;
  region?: string;
  districts?: string;
  assignedLocation?: string;
  password?: string;
  confirmPassword?: string;

  constructor(
    data: RegionalDistributorsErrorModelProps = defaultRegionalDistributorsErrorModelProps
  ) {
    this.name = data.name || "";
    this.email = data.email || "";
    this.phone = data.phone || "";
    this.region = data.region || "";
    this.districts = data.districts || "";
    this.assignedLocation = data.assignedLocation || "";
    this.password = data.password || "";
    this.confirmPassword = data.confirmPassword || "";
  }
}
