import { InputBaseProps, InputProps, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import React from "react";

interface Props extends InputBaseProps {
  label?: string;
  InputProps?: InputProps;
  errorText?: string;
  active?: boolean;
  activeColor?: string;
}
const Text: React.FC<Props> = ({
  type,
  name,
  label,
  placeholder,
  autoComplete,
  minRows,
  InputProps,
  onChange,
  onFocus,
  onBlur,
  value,
  errorText,
  disabled,
  active,
  activeColor,
}) => {
  const { palette } = useTheme();
  return (
    <>
      {label ? (
        <FormLabel
          htmlFor={name}
          className="tw-mb-[6px] tw-block"
          sx={{ color: palette.secondary.main }}
        >
          {label}
        </FormLabel>
      ) : (
        <></>
      )}
      <TextField
        id={name}
        fullWidth
        variant="outlined"
        type={type}
        name={name}
        // label={value ? label : ""}
        value={value || ""}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        autoComplete={autoComplete}
        multiline={type === "textArea"}
        minRows={minRows}
        error={errorText ? true : false}
        helperText={
          errorText ? (
            <Box
              component="span"
              dangerouslySetInnerHTML={{ __html: errorText }}
            />
          ) : (
            <></>
          )
        }
        disabled={disabled}
        InputProps={InputProps}
        sx={{
          ".MuiInputBase-root.MuiOutlinedInput-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor:
                active && activeColor
                  ? activeColor
                  : active && !activeColor
                  ? palette.primary.main
                  : "",
            },
          },
        }}
      />
    </>
  );
};

export default Text;
