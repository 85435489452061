import React from "react";
import { IconsInterface } from "_interfaces/_common/icons";

const RiseTicketIcon: React.FC<IconsInterface> = ({ width, height, color }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "21"}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.45456 4.37832C8.31517 4.14281 8.07004 4.00342 7.79847 4.00342C7.52691 4.00342 7.28178 4.14281 7.14239 4.37832L3.76823 10.1029C3.62884 10.3408 3.62644 10.6268 3.76342 10.8671C3.90041 11.1074 4.14794 11.2516 4.42432 11.2516H11.1702C11.4466 11.2516 11.6941 11.1074 11.8311 10.8671C11.9681 10.6268 11.9657 10.3408 11.8263 10.1029L8.45456 4.37832ZM7.43799 6.65179C7.43799 6.56047 7.46923 6.48116 7.53171 6.41628C7.66149 6.2889 7.87778 6.28891 8.00275 6.41868C8.06523 6.48116 8.09648 6.56047 8.09648 6.65179V7.1709L8.00035 8.89883C7.99554 8.96131 7.97391 9.01178 7.93306 9.05023C7.8922 9.08869 7.83693 9.10791 7.76964 9.10791C7.70234 9.10791 7.64707 9.08869 7.60381 9.05023C7.56055 9.01178 7.53652 8.96131 7.53171 8.90123L7.43799 7.1733V6.65179ZM8.03399 10.0284C7.9619 10.1005 7.87298 10.1365 7.76964 10.1365C7.6687 10.1365 7.58218 10.1005 7.51008 10.0284C7.43799 9.95626 7.39954 9.86734 7.39954 9.764C7.39954 9.66306 7.43799 9.57654 7.51008 9.50445C7.58218 9.43235 7.6687 9.3939 7.76964 9.3939C7.87298 9.3939 7.9619 9.43235 8.03399 9.50445C8.10609 9.57654 8.14214 9.66306 8.14214 9.764C8.14214 9.86734 8.10369 9.95626 8.03399 10.0284Z"
        fill={color}
      />
      <path
        d="M15.0466 12.6167C15.3206 12.136 15.3158 11.5664 15.0346 11.0906L9.1082 1.03061C8.83183 0.56198 8.34157 0.283203 7.79843 0.283203C7.2553 0.283203 6.76504 0.56198 6.48866 1.03061L0.56226 11.0906C0.28108 11.5664 0.276273 12.136 0.550243 12.6167C0.824213 13.0973 1.31688 13.3833 1.86963 13.3833H13.7248C14.2776 13.3809 14.7727 13.0949 15.0466 12.6167ZM12.2516 11.1074C12.0281 11.4992 11.6244 11.7323 11.1726 11.7323H4.42428C3.97247 11.7323 3.56872 11.4992 3.34522 11.1074C3.12172 10.7157 3.12412 10.2495 3.35483 9.86014L6.72899 4.13561C6.95489 3.75349 7.35383 3.52518 7.79843 3.52518C8.24303 3.52518 8.64197 3.75349 8.86788 4.13561L12.242 9.86014C12.4703 10.2495 12.4751 10.7157 12.2516 11.1074Z"
        fill={color}
      />
      <path
        d="M15.3278 4.68831L14.4266 3.15985C14.3593 3.04449 14.2127 3.00844 14.0973 3.07574C13.982 3.14303 13.9459 3.28962 14.0132 3.40498L14.9145 4.93344C14.9601 5.01035 15.0394 5.0512 15.1211 5.0512C15.162 5.0512 15.2052 5.04159 15.2437 5.01756C15.3566 4.94786 15.3951 4.80127 15.3278 4.68831Z"
        fill={color}
      />
      <path
        d="M12.5376 2.83563C12.4703 2.72027 12.3237 2.68423 12.2084 2.75152C12.093 2.81881 12.057 2.96541 12.1243 3.08076L14.3064 6.78657C14.3521 6.86347 14.4314 6.90433 14.5131 6.90433C14.5539 6.90433 14.5972 6.89471 14.6357 6.87068C14.751 6.80339 14.7871 6.65679 14.7198 6.54144L12.5376 2.83563Z"
        fill={color}
      />
      <path
        d="M0.47572 5.04871C0.55743 5.04871 0.639141 5.00545 0.682399 4.93095L1.58362 3.40248C1.65091 3.28713 1.61246 3.14053 1.4995 3.07324C1.38415 3.00595 1.23755 3.0444 1.17026 3.15735L0.269041 4.68582C0.20175 4.80117 0.240202 4.94777 0.353154 5.01506C0.39401 5.0391 0.434865 5.04871 0.47572 5.04871Z"
        fill={color}
      />
      <path
        d="M1.08375 6.90183C1.16546 6.90183 1.24717 6.85857 1.29043 6.78407L3.47258 3.07827C3.53987 2.96291 3.50142 2.81631 3.38846 2.74902C3.27311 2.68173 3.12651 2.72018 3.05922 2.83313L0.877073 6.53894C0.809782 6.6543 0.848234 6.80089 0.961187 6.86819C0.999639 6.89222 1.0429 6.90183 1.08375 6.90183Z"
        fill={color}
      />
    </svg>
  );
};

export default RiseTicketIcon;
