interface InventoryUsersErrorModelProps {
  id?: string;
  name?: string;
  email?: string;
  phone?: string;
  role?: string;
  password?: string;
  confirmPassword?: string;
}

const defaultInventoryUsersErrorModelProps: InventoryUsersErrorModelProps = {
  id: "",
  name: "",
  email: "",
  phone: "",
  role: "",
  password: "",
  confirmPassword: "",
};

export class InventoryUsersErrorModel {
  id?: string;
  name?: string;
  email?: string;
  phone?: string;
  role?: string;
  password?: string;
  confirmPassword?: string;

  constructor(
    data: InventoryUsersErrorModelProps = defaultInventoryUsersErrorModelProps
  ) {
    this.id = data.id || "";
    this.name = data.name || "";
    this.email = data.email || "";
    this.phone = data.phone || "";
    this.role = data.role || "";
    this.password = data.password || "";
    this.confirmPassword = data.confirmPassword || "";
  }
}
