import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import SimManagementDetailsInterface from "_interfaces/sim-management/details";
import { camelCaseToFormattedString } from "functions/helper";
import DetailsTable from "parts/table/detailsTable";
import React from "react";

interface Props {
  data: SimManagementDetailsInterface | null;
}

const CustomerInformationTable: React.FC<Props> = ({ data }) => {
  return (
    <>
      {data?.customerInfo?.id ? (
        <DetailsTable>
          <TableRow>
            <TableCell>Name As Document</TableCell>
            <TableCell>
              <Box className="tw-capitalize">
                {data?.customerInfo?.nameAsDocument
                  ? camelCaseToFormattedString(
                      data?.customerInfo?.nameAsDocument
                    )
                  : ""}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Identity Type</TableCell>
            <TableCell>
              <Box className="tw-capitalize">
                {data?.customerInfo?.identityType
                  ? camelCaseToFormattedString(data?.customerInfo?.identityType)
                  : ""}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>MSISDN</TableCell>
            <TableCell>{data?.customerInfo?.MSISDN}</TableCell>
          </TableRow>
        </DetailsTable>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomerInformationTable;
