import React from "react";
import { IconsInterface } from "_interfaces/_common/icons";

const ReportIcon: React.FC<IconsInterface> = ({ width, height, color }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "20"}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 5H10V15H8V5ZM12 9H14V15H12V9ZM4 11H6V15H4V11ZM12 2H2V18H16V6H12V2ZM0 0.9918C0 0.44405 0.44749 0 0.9985 0H13L17.9997 5L18 18.9925C18 19.5489 17.5551 20 17.0066 20H0.9934C0.44476 20 0 19.5447 0 19.0082V0.9918Z"
        fill={color}
      />
    </svg>
  );
};

export default ReportIcon;
