export interface UploadMsisdnErrorModelProps {
  batchNo?: string;
  firstMSISDN?: string;
  lastMSISDN?: string;
  type?: string;
  isFixed?: string;
}

const defaultUploadMsisdnErrorModelProps: UploadMsisdnErrorModelProps = {
  batchNo: "",
  firstMSISDN: "",
  lastMSISDN: "",
  type: "",
  isFixed: "",
};

export class UploadMsisdnErrorModel {
  batchNo?: string;
  firstMSISDN?: string;
  lastMSISDN?: string;
  type?: string;
  isFixed?: string;

  constructor(
    data: UploadMsisdnErrorModelProps = defaultUploadMsisdnErrorModelProps
  ) {
    this.batchNo = data.batchNo || "";
    this.firstMSISDN = data.firstMSISDN || "";
    this.lastMSISDN = data.lastMSISDN || "";
    this.type = data.type || "";
    this.isFixed = data.isFixed || "";
  }
}
