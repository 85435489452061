import { Box, Button, Dialog, DialogContent, DialogTitle, Modal, Typography } from "@mui/material";
import { MsisdnDialogInterface } from "_interfaces/msisdn-management/details";
import axios from "axios";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import { MsisdnStatusUpdate, MsisdnTypeUpdate } from "functions/http-requests/msisdnManagement";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";

interface Props {
    id: string,
    open: boolean,
    handleClose: any,
    data: MsisdnDialogInterface[],
    field: string,
    getAllData: () => void
}

const MsisdnChangeTypeStatusDialog: React.FC<Props> = ({ id, open, data, field, handleClose, getAllData }) => {
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
    const [type, setType] = useState<string>("");
    const Dispatch = useDispatch();

    console.log(data)

    const handleCloseConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
        handleClose();
    }

    const handleConfirmationDialog = (type: string) => {
        setType(type.toLowerCase());

        if (data.find(val => val.type == type)?.selected) {
            Dispatch(setAlert({ type: "warning", message: `${field} is already set to ${type}` }));
            return;
        }
        setOpenConfirmationDialog(true);
    }

    const handleSubmit = () => {
        let submitFunc: any;
        let DATA: any;
        let ID: string = "";

        if (field.toLowerCase() == "status") {
            DATA = { status: type };
            ID = id;
            submitFunc = () => MsisdnStatusUpdate({ DATA, ID })
        }
        else {
            DATA = { type: type };
            ID = id;
            submitFunc = () => MsisdnTypeUpdate({ DATA, ID })
        }

        Dispatch(toggleLoading(true));
        submitFunc({ DATA, ID })
            .then((res: any) => {
                const data = res?.data;
                console.log(res)
                Dispatch(setAlert({ type: data?.level, message: data?.message }));
                if (
                    data?.level === "success"
                ) {
                    handleClose();
                    getAllData();
                    setOpenConfirmationDialog(false);
                }
            })
            .catch((error: any) => {
                if (!axios.isCancel(error))
                    Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
            })
            .finally(() => Dispatch(toggleLoading(false)));

    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box>
                    <DialogTitle
                        id="alert-dialog-title">
                        <Typography sx={{
                            fontSize: "1.1rem",
                            fontWeight: 600,
                            marginBottom: "1rem",
                            textAlign: "center"
                        }}>Please choose a {field}</Typography>
                    </DialogTitle>
                    <DialogContent sx={{ width: "22.2rem", }}>
                        <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
                            {data.map(val => (
                                <Button
                                    onClick={() => handleConfirmationDialog(val.type)}
                                    sx={{
                                        color: "white",
                                        background: val.selected ? "green" : "gray",
                                        '&:hover': {
                                            background: val.selected ? "green" : "gray", // same as normal state
                                        }
                                    }} >
                                    {val.type}
                                </Button>
                            ))}
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>

            <Dialog
                open={openConfirmationDialog}
                onClose={handleCloseConfirmationDialog}
            >
                <Box>
                    <DialogTitle
                        id="alert-dialog-title">
                        <Typography
                            sx={{
                                fontSize: "1.1rem",
                                fontWeight: 600,
                                marginBottom: "1rem"
                            }}>
                            Are you sure to change the {field} to <Box component="span" sx={{ textTransform: 'capitalize' }}>
                                {type}
                            </Box>?
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "13.3px",
                                float: "inline-end"
                            }}
                        >
                            <Button onClick={handleCloseConfirmationDialog} >Cancel</Button>
                            <Button onClick={handleSubmit} >Confirm</Button>
                        </Box>
                    </DialogContent>
                </Box >
            </Dialog >
        </>
    )
}

export default MsisdnChangeTypeStatusDialog;