import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import SideDrawer from "parts/sideDrawer";
import React, { Fragment, useMemo } from "react";
import { RegionalDistributorsFilterModel } from "_models/data/regional-distributors/data.regional-distributors-filter.model";

interface InputListProps extends FormInputProps {
  name: "status";
}
interface Props extends SideDrawerProps {
  filters: RegionalDistributorsFilterModel;
  setFilters: React.Dispatch<
    React.SetStateAction<RegionalDistributorsFilterModel>
  >;
}

const RegionalDistributorsFilter: React.FC<Props> = ({
  open,
  onClose,
  filters,
  setFilters,
}) => {
  const handleAutoComplete = (
    e: React.SyntheticEvent,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setFilters((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "status",
        label: "Filter by Status",
        placeholder: "Select Status",
        options: [
          {
            id: "true",
            value: "true",
            title: "Active",
            isActive: true,
          },
          {
            id: "false",
            value: "false",
            title: "In-active",
            isActive: true,
          },
        ],
        multiple: false,
      },
    ],
    []
  );

  return (
    <>
      <SideDrawer open={open} onClose={onClose}>
        <Box id="filter_regional_distributors">
          <Grid container rowSpacing={3} columnSpacing={2}>
            {inputList.map(
              (
                { type, name, label, placeholder, options, multiple },
                index
              ) => (
                <Fragment key={index}>
                  <>
                    {type === "autoComplete" && options?.length ? (
                      <Grid item xs={12}>
                        <InputAutoComplete
                          name={name}
                          options={options}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options?.length &&
                            filters?.[
                              name as keyof RegionalDistributorsFilterModel
                            ]
                          }
                          multiple={multiple}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                </Fragment>
              )
            )}
          </Grid>
        </Box>
      </SideDrawer>
    </>
  );
};

export default RegionalDistributorsFilter;
