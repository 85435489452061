import LoginLayout from "component/auth/login";
import AuthLayout from "parts/auth";

const Login = () => {
  return (
    <AuthLayout>
      <LoginLayout />
    </AuthLayout>
  );
};

export default Login;
