import { COUNTRY_CODE } from "config/endpoint";
import { RouteConstant } from "navigation/constant";
import { AutoCompleteOptionsProps } from "_interfaces/_common/forms";

export const getClientTimeZone = (): string | null => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timeZone) return timeZone;
    else return null;
  } catch (error) {
    return null;
  }
};

export const Channel = new BroadcastChannel("logout");

export const ClearDataOnLogout = () => {
  localStorage.removeItem("token");
  window.location.href = RouteConstant.LOG_IN;
};

export const getLocation = (): Promise<GeolocationPosition> => {
  return new Promise((res, rej) => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(res, rej);
    } else {
      return rej;
    }
  });
};

export const generateMSISDN = (str: string) => {
  if (!str) return "";
  return `${COUNTRY_CODE}${str}`;
};

export const filterNonNullValues = (obj: any): any => {
  const filteredObj: any = {};
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== ""
    ) {
      filteredObj[key] = obj[key];
    }
  }
  return filteredObj;
};

export const sumCountSuccessAndReject = (data: object[]): number => {
  let sumSuccess = 0;
  let sumReject = 0;

  data.forEach((entry: any) => {
    sumSuccess += entry.countSuccess;
    sumReject += entry.countReject;
  });

  return sumSuccess + sumReject;
};

export const camelCaseToFormattedString = (camelCase?: string): string => {
  if (!camelCase) return "";
  return camelCase
    .toString()
    .replace(/([A-Z])/g, " $1")
    .toLowerCase();
};

export const toTitleCase = (str: string): string => {
  if (!str) return "";
  return str
    ?.toString()
    ?.replace(/_/g, " ")
    ?.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
    );
};

export const generateAutocompleteOptions = (
  values: string[]
): AutoCompleteOptionsProps[] => {
  return values?.map((value) => ({
    id: value,
    value: value,
    title: toTitleCase(value),
    isActive: true,
  }));
};
