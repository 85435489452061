export interface AgentFilterModelProps {
  type?: string;
  activeStatus?: "active" | "inActive" |"";
  approveStatus?: "approve" | "notApprove" | "";
}

const defaultAgentFilterModelProps: AgentFilterModelProps =
  {
    type: "",
    activeStatus: "",
    approveStatus: "",
  };

export class AgentsFilterModel {
  type?: string;
  activeStatus?: "active" | "inActive" |"";
  approveStatus?: "approve" | "notApprove" | "";

  constructor(
    data: AgentFilterModelProps = defaultAgentFilterModelProps
  ) {
    this.type = data.type || "";
    this.activeStatus = data.activeStatus || "";
    this.approveStatus = data.approveStatus || "";
  }
}
