import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CreateReportForm from "component/_common/createReportForm";
import SectionTitle from "parts/sectionTitle";

const AnalyticsReportLayout = () => {
  return (
    <Container maxWidth="xs">
      <Box py={5} className="tw-text-center">
        <SectionTitle title="Create a Report" />
      </Box>

      <CreateReportForm />
    </Container>
  );
};

export default AnalyticsReportLayout;
