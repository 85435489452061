import PackageBundleLayout from "component/package-bundle";
import MainContainer from "parts/mainContainer";

const PackageBundle = () => {
  return (
    <MainContainer>
      <PackageBundleLayout />
    </MainContainer>
  );
};

export default PackageBundle;
