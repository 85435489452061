import MsisdnManagementDetailsLayout from "component/msisdn-management/details";
import { RouteConstant } from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { Navigate, useParams } from "react-router-dom";

const MsisdnManagementDetails = () => {
  const params = useParams();

  if (!params?.id) return <Navigate to={RouteConstant.MSISDN_MANAGEMENT} />;

  return (
    <MainContainer>
      <MsisdnManagementDetailsLayout id={params.id} />
    </MainContainer>
  );
};

export default MsisdnManagementDetails;
