import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import { ManageAccountModel } from "_models/data/manage-account/data.manage-account.model";
import { ManageAccountErrorModel } from "_models/error/manage-account/error.manage-account.model";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import Text from "component/_common/forms/text";
import React, { Fragment, useState } from "react";

interface InputListProps extends FormInputProps {
  name: "fullName" | "userId" | "role";
}

const inputList: InputListProps[] = [
  {
    type: "text",
    name: "fullName",
    label: "Full Name",
    placeholder: "Enter Full name",
    grid: 4,
  },
  {
    type: "text",
    name: "userId",
    label: "User ID",
    placeholder: "Enter User ID",
    grid: 4,
  },
  {
    type: "autoComplete",
    name: "role",
    label: "Role",
    placeholder: "Enter Role",
    options: [
      {
        id: "inventoryManager",
        value: "inventoryManager",
        title: "Inventory Manager",
        isActive: true,
      },
    ],
    grid: 4,
  },
];

interface ManageAccountFormProps {
  isEditing: boolean;
  toggleEdit: () => void;
}
const ManageAccountForm: React.FC<ManageAccountFormProps> = ({
  isEditing,
  toggleEdit,
}) => {
  const [state, setState] = useState<ManageAccountModel>(
    new ManageAccountModel()
  );
  const [errors, setErrors] = useState<ManageAccountErrorModel>(
    new ManageAccountErrorModel()
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev: any) => ({ ...prev, [name]: "" }));
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
  };

  return (
    <>
      <Box component="form" name="manage_account_form" onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="flex-end">
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                autoComplete,
                disabled,
                options,
                multiple,
                minRows,
                grid,
              },
              index
            ) => (
              <Fragment key={index}>
                <>
                  {type === "text" ? (
                    <Grid item xs={12} md={grid || 12}>
                      <Text
                        type={type}
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        autoComplete={autoComplete || "off"}
                        value={state[name as keyof ManageAccountModel]}
                        errorText={
                          errors[name as keyof ManageAccountErrorModel]
                        }
                        onChange={handleChange}
                        onFocus={handleFocus}
                        disabled={disabled}
                        minRows={minRows}
                      />
                    </Grid>
                  ) : type === "autoComplete" ? (
                    <Grid item xs={12} md={grid || 12}>
                      <InputAutoComplete
                        name={name}
                        options={options || []}
                        label={label}
                        placeholder={placeholder}
                        onChange={(e, v, m) =>
                          handleAutoComplete(e, v, name, m)
                        }
                        value={
                          options &&
                          options?.length &&
                          state?.[name as keyof ManageAccountModel]
                        }
                        errorText={
                          errors[name as keyof ManageAccountErrorModel]
                        }
                        onFocus={handleFocus}
                        multiple={multiple}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              </Fragment>
            )
          )}

          {isEditing ? (
            <Grid item xs={12} md={4} className="tw-flex tw-justify-end">
              <Button
                onClick={toggleEdit}
                variant="text"
                color="secondary"
                className="tw-mr-[12px]"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disableElevation
                className="tw-min-w-[120px]"
              >
                Submit
              </Button>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ManageAccountForm;
