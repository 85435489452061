import SimManagementLayout from "component/sim-management";
import MainContainer from "parts/mainContainer";

const SimManagement = () => {
  return (
    <MainContainer>
      <SimManagementLayout />
    </MainContainer>
  );
};

export default SimManagement;
