interface LoginErrorModelInterface {
  phone?: string;
  password?: string;
}
export class LoginErrorModel {
  phone?: string;
  password?: string;

  constructor(data: LoginErrorModelInterface = {}) {
    this.phone = data.phone || "";
    this.password = data.password || "";
  }
}
