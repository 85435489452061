import { UploadCartonModel } from "_models/data/sim-management/data.upload-carton.model";

export const validateSimCarton = (DATA: UploadCartonModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.batchNo?.trim()) {
    valid = false;
    errors.push({
      name: "batchNo",
      error: "Batch No is required.",
    });
  }

  if (!DATA.firstICCID?.trim()) {
    valid = false;
    errors.push({
      name: "firstICCID",
      error: "First ICCID is required.",
    });
  }

  if (!DATA.lastICCID?.trim()) {
    valid = false;
    errors.push({
      name: "lastICCID",
      error: "Last ICCID is required.",
    });
  }

  if (DATA?.firstICCID?.trim() && DATA?.lastICCID?.trim()) {
    if (DATA.firstICCID.length < 20) {
      valid = false;
      errors.push({
        name: "firstICCID",
        error: "ICCID must have a minimum length of 20 digits.",
      });
    }

    if (DATA.lastICCID.length < 20) {
      valid = false;
      errors.push({
        name: "lastICCID",
        error: "ICCID must have a minimum length of 20 digits.",
      });
    }

    if (DATA.firstICCID.length >= 20 && DATA.lastICCID.length >= 20) {
      if (DATA.lastICCID < DATA.firstICCID) {
        valid = false;
        errors.push({
          name: "lastICCID",
          error: "Last ICCID must be greater than or equal to the first ICCID.",
        });
      }
    }
  }

  if (!DATA.firstIMSI?.trim()) {
    valid = false;
    errors.push({
      name: "firstIMSI",
      error: "First IMSI is required.",
    });
  }

  if (!DATA.lastIMSI?.trim()) {
    valid = false;
    errors.push({
      name: "lastIMSI",
      error: "Last IMSI is required.",
    });
  }

  if (DATA.firstIMSI?.trim() && DATA.lastIMSI?.trim()) {
    if (DATA.lastIMSI < DATA.firstIMSI) {
      valid = false;
      errors.push({
        name: "lastIMSI",
        error: "Last IMSI must be greater than or equal to the first IMSI.",
      });
    }
  }

  if (
    DATA?.firstICCID?.trim() &&
    DATA?.lastICCID?.trim() &&
    DATA?.firstIMSI?.trim() &&
    DATA?.lastIMSI?.trim()
  ) {
    const startSerialICCID = BigInt(DATA.firstICCID);
    const endSerialICCID = BigInt(DATA.lastICCID);
    const subtractICCID = endSerialICCID - startSerialICCID;
    const totalICCIDSim = Number(subtractICCID) + 1;

    if (totalICCIDSim > 25000) {
      valid = false;
      errors.push({
        name: "lastICCID",
        error:
          "The total number of ICCID calculated from the provided ICCID range exceeds the maximum limit of 25,000.",
      });
    }

    const startSerialIMSI = BigInt(DATA.firstIMSI);
    const endSerialIMSI = BigInt(DATA.lastIMSI);
    const subtractIMSI = endSerialIMSI - startSerialIMSI;
    const totalIMSISim = Number(subtractIMSI) + 1;

    if (totalIMSISim > 25000) {
      valid = false;
      errors.push({
        name: "lastIMSI",
        error:
          "The total number of IMSI calculated from the provided IMSI range exceeds the maximum limit of 25,000.",
      });
    }

    if (totalICCIDSim !== totalIMSISim) {
      valid = false;
      errors.push({
        name: "lastIMSI",
        error: "The total number of ICCID and IMSI must be equal.",
      });
    }
  }

  if (!DATA.serviceType?.trim()) {
    valid = false;
    errors.push({
      name: "serviceType",
      error: "Service Type is required.",
    });
  }

  if (!DATA.SIM_type?.trim()) {
    valid = false;
    errors.push({
      name: "SIM_type",
      error: "SIM Type is required.",
    });
  }

  return { valid, errors };
};
