import React from "react";
import { IconsInterface } from "_interfaces/_common/icons";

const SimManagementIcon: React.FC<IconsInterface> = ({
  width,
  height,
  color,
}) => {
  return (
    <svg
      width={width || "12"}
      height={height || "13"}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0755 10.08V11.9664H1.21759V2.42224L1.64682 1.97388"
        fill={color}
      />
      <path
        d="M9.79661 10.0882C9.79661 10.7143 9.79661 11.3376 9.79661 11.9637C9.88683 11.8734 9.97978 11.7805 10.07 11.6903C9.07212 11.6903 8.07423 11.6903 7.07635 11.6903C5.48521 11.6903 3.8968 11.6903 2.30565 11.6903C1.9393 11.6903 1.57569 11.6903 1.20935 11.6903C1.29957 11.7805 1.39252 11.8734 1.48274 11.9637C1.48274 11.6493 1.48274 11.3349 1.48274 11.0205C1.48274 10.2604 1.48274 9.5004 1.48274 8.73763C1.48274 7.82177 1.48274 6.9059 1.48274 5.9873C1.48274 5.19173 1.48274 4.39342 1.48274 3.59785C1.48274 3.33539 1.48274 3.07567 1.48274 2.81321C1.48274 2.69019 1.49367 2.56169 1.48274 2.43866C1.48274 2.4332 1.48274 2.42773 1.48274 2.42226C1.4554 2.48788 1.42806 2.55076 1.40345 2.61637C1.54562 2.46874 1.69052 2.32111 1.83268 2.17074C2.07873 1.91648 1.69052 1.531 1.4472 1.78526C1.3187 1.91922 1.18747 2.05318 1.05898 2.18714C0.985164 2.26369 0.941421 2.34844 0.938687 2.45507C0.933219 2.6109 0.938687 2.764 0.938687 2.91984C0.938687 3.63886 0.938687 4.35788 0.938687 5.0769C0.938687 6.05018 0.938687 7.02346 0.938687 7.99674C0.938687 8.87706 0.938687 9.75465 0.938687 10.635C0.938687 11.0724 0.922283 11.5098 0.938687 11.9473C0.938687 11.9527 0.938687 11.9609 0.938687 11.9664C0.938687 12.114 1.06445 12.2398 1.21208 12.2398C2.20996 12.2398 3.20785 12.2398 4.20573 12.2398C5.79687 12.2398 7.38529 12.2398 8.97643 12.2398C9.34278 12.2398 9.70639 12.2398 10.0727 12.2398C10.2204 12.2398 10.3461 12.114 10.3461 11.9664C10.3461 11.3403 10.3461 10.717 10.3461 10.0909C10.3434 9.73825 9.79661 9.73825 9.79661 10.0882Z"
        fill={color}
      />
      <path d="M0.2771 0.6698L10.07 10.0882L11.3987 11.436" fill={color} />
      <path
        d="M0.0829558 0.861201C0.495779 1.25762 0.908602 1.65677 1.32142 2.05319C2.27283 2.96906 3.22424 3.88219 4.17564 4.79805C5.23367 5.81508 6.2917 6.8321 7.34973 7.85185C8.08243 8.5572 8.81512 9.26256 9.54781 9.96791C10.0673 10.4682 10.5703 10.9877 11.0788 11.4989C11.1226 11.5427 11.1636 11.5864 11.2073 11.6301C11.4561 11.8817 11.8416 11.4934 11.5928 11.2447C11.087 10.7334 10.5894 10.214 10.07 9.71366C9.40565 9.07392 8.7413 8.43418 8.07696 7.79717C7.03533 6.79656 5.99371 5.7932 4.95208 4.79259C3.96513 3.84391 2.97818 2.89524 1.99124 1.94384C1.49366 1.45993 1.00429 0.96509 0.490311 0.494854C0.482109 0.489387 0.476641 0.481185 0.468439 0.475717C0.216918 0.232397 -0.171299 0.617881 0.0829558 0.861201Z"
        fill={color}
      />
      <path
        d="M2.33032 1.23574L3.27353 0.265198H10.1302V8.69116L2.33032 1.23574Z"
        fill={color}
      />
      <path
        d="M2.52165 1.43531C2.83605 1.11271 3.15319 0.790105 3.46759 0.470235C3.40198 0.497575 3.3391 0.524914 3.27348 0.549519C4.04718 0.549519 4.82089 0.549519 5.59732 0.549519C6.82485 0.549519 8.05239 0.549519 9.27992 0.549519C9.56151 0.549519 9.84311 0.549519 10.1247 0.549519C10.0345 0.4593 9.94153 0.366346 9.85131 0.276127C9.85131 1.2248 9.85131 2.17621 9.85131 3.12488C9.85131 4.63674 9.85131 6.1486 9.85131 7.65773C9.85131 8.0022 9.85131 8.34394 9.85131 8.68842C10.0071 8.62281 10.163 8.55993 10.3188 8.49431C10.0618 8.24826 9.8021 8.0022 9.54511 7.75342C8.92451 7.16015 8.30391 6.56963 7.68331 5.97636C6.93148 5.26007 6.18238 4.54379 5.43055 3.82476C4.78261 3.20416 4.13194 2.58356 3.484 1.96296C3.16686 1.66223 2.86066 1.34783 2.53532 1.0553C2.52985 1.04983 2.52712 1.04709 2.52165 1.04163C2.2674 0.798307 1.87918 1.18379 2.13617 1.42711C2.39316 1.67316 2.65288 1.91922 2.90987 2.168C3.53047 2.76127 4.15107 3.35179 4.77167 3.94506C5.5235 4.66135 6.2726 5.37763 7.02443 6.09666C7.67237 6.71726 8.32305 7.33786 8.97099 7.95846C9.28812 8.25919 9.59432 8.57359 9.91966 8.86613C9.92513 8.87159 9.92786 8.87433 9.93333 8.87979C10.1028 9.0411 10.4008 8.92627 10.4008 8.68569C10.4008 7.73701 10.4008 6.78561 10.4008 5.83693C10.4008 4.32507 10.4008 2.81321 10.4008 1.30408C10.4008 0.959608 10.4008 0.617867 10.4008 0.273393C10.4008 0.125761 10.2751 0 10.1274 0C9.08581 0 8.04419 0 7.00256 0C5.78323 0 4.5639 0 3.34457 0C3.24614 0 3.16413 0.00820182 3.08484 0.0792839C2.99736 0.155834 2.92081 0.246053 2.83879 0.330805C2.6064 0.568657 2.37129 0.809242 2.1389 1.04709C1.88738 1.30135 2.2756 1.68683 2.52165 1.43531Z"
        fill={color}
      />
    </svg>
  );
};

export default SimManagementIcon;
