import DashboardLayout from "component/dashboard";
import MainContainer from "parts/mainContainer";

const Dashboard = () => {
  return (
    <MainContainer>
      <DashboardLayout />
    </MainContainer>
  );
};

export default Dashboard;
