import { Visibility } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { GridColDef, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import CustomDataGrid from "parts/customDataGrid";
import { IsActiveCell } from "parts/table/customCell";
import React from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import HandlerTableInterface, { HandlerDetailsInterface } from "_interfaces/handler";


const AllHandlersTable: React.FC<HandlerTableInterface> = ({ data, count, paginationModel, loading, setPaginationModel }) => {
    const navigate = useNavigate();
    const { palette } = useTheme();

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Handler Name",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "phone",
            headerName: "Phone",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "date",
            headerName: "Created On",
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => moment(params.value).format("Do MMM, YYYY"),
            renderCell: (params) => (
                <Box className="tw-capitalize">{params?.formattedValue}</Box>
            ),
        },
        {
            field: "role",
            headerName: "Role",
            minWidth: 120,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "isActive",
            headerName: "Status",
            minWidth: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <IsActiveCell status={params?.row?.isActive} />
            ),
        },
        {
            field: "seeDetails",
            headerName: "See Details",
            minWidth: 130,
            align: "center",
            headerAlign: "center",
            sortable: false,
            renderCell: (params) => {
              return (
                <>
                  <Box className="tw-flex tw-justify-center tw-items-center">
                    <IconButton
                      size="small"
                      onClick={() =>
                        params?.row?.id &&
                        navigate(
                          `${RouteConstant.HANDLERS}/${params?.row?.id}`
                        )
                      }
                      sx={{ color: palette.secondary.main }}
                    >
                      <Tooltip title="Edit">
                      <Visibility color="inherit" fontSize="small" />
                      </Tooltip>
                    </IconButton>
                  </Box>
                </>
              );
            },
          },
    ];

    return (
        <>
            <CustomDataGrid
                loading={loading}
                columns={columns}
                rows={data || []}
                count={count}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
            />
        </>
    )
}

export default AllHandlersTable;