export interface ManageAccountNotificationProps {
  notification: string[];
}

const defaultManageAccountNotificationProps: ManageAccountNotificationProps = {
  notification: [],
};

export class ManageAccountNotificationModel {
  notification: string[];

  constructor(
    data: ManageAccountNotificationProps = defaultManageAccountNotificationProps
  ) {
    this.notification = data.notification || [];
  }
}
