import InventoryUsersLayout from "component/inventory-users";
import MainContainer from "parts/mainContainer";

const InventoryUsers = () => {
  return (
    <MainContainer>
      <InventoryUsersLayout />
    </MainContainer>
  );
};

export default InventoryUsers;
