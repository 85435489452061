export interface ManageAccountErrorProps {
  fullName: string;
  userId: string;
  role: string;
}

const defaultManageAccountErrorProps: ManageAccountErrorProps = {
  fullName: "",
  userId: "",
  role: "",
};

export class ManageAccountErrorModel {
  fullName: string;
  userId: string;
  role: string;

  constructor(data: ManageAccountErrorProps = defaultManageAccountErrorProps) {
    this.fullName = data.fullName || "";
    this.userId = data.userId || "";
    this.role = data.role || "";
  }
}
