import { AgentGetById, CountByAgent, GetAllHandlersByAgent } from "functions/http-requests/agents";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import AgentAnalytics from "./agentAnalytics";
import { AppStatusCode } from "config/appStatusCode";
import AgentDetailsTable from "./agentDetailsTable";
import { Box, Card, Grid, Typography } from "@mui/material";
import { GetAllHandlersByAgentProps } from "_interfaces/functions/http-requests/agents";
import { filterNonNullValues } from "functions/helper";
import { GridPaginationModel } from "@mui/x-data-grid";
import { GetAllCustomersByAgentProps } from "_interfaces/functions/http-requests/customer-management";
import { GetCustomerByAgentId } from "functions/http-requests/customer-management";
import moment from "moment";
import { CustomerManagementFilterModel } from "_models/data/customer-management/data.customer-management-filter.model";
import CustomerManagementTableInterface from "_interfaces/customer-management/customerManagementTable";
import CustomerManagementFilter from "component/customer-management/customerManagementFilter";
import CustomerManagementTable from "component/customer-management/customerManagementTable";
import FilterButton from "parts/filterButton";
import SectionSearch from "parts/sectionSearch";
import { DistributorDetailsAnalytics } from "_interfaces/regional-distributors/details/regionalDistributorDetailsTable";
import HandlersTable from "./handlersTable";
import HandlerDetailsFilter from "./handlerDetailsFilter";
import { HandlersFilterModel } from "_models/data/handler/data.handlers-filter.model";
import { AgentDetailsInterface } from "_interfaces/agent";

interface Props {
    ID: string
}


const AgentDeailsLayout: React.FC<Props> = ({ ID }) => {
    const isInitialRender = useRef(true);
    const isInitialHandlerRender = useRef(true);
    const [analyticsData, setAnalyticsData] = useState<DistributorDetailsAnalytics[]>([]);
    const Dispatch = useDispatch();
    const [agentDetails, setAgentDetails] = useState<AgentDetailsInterface | null>(null);
    const [handlersData, setHandlersData] = useState<any[]>([]);
    const [handlerRefresh, setHandlerRefresh] = useState<boolean>(false);
    const [handlerSearchKey, setHandlerSearchKey] = useState<string>("");
    const handleAgentRefresh = () => setHandlerRefresh(!handlerRefresh);
    const [openHandlerFilter, setOpenHandlerFilter] = useState<boolean>(false);
    const [handlerFilters, setHandlerFilters] = useState<any>(
        new HandlersFilterModel()
    );
    const [handlerPaginationModel, setHandlerPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [totalHandlercount, setTotalHandlerCount] = useState<number>(0);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [handlersDataLoading, setHandlersDataLoading] = useState<boolean>(false);
    const [customerFilters, setCustomerFilters] = useState<CustomerManagementFilterModel>(
        new CustomerManagementFilterModel()
    );
    const [refresh, setRefresh] = useState<boolean>(false);
    const [searchKey, setSearchKey] = useState<string>("");
    const handleRefresh = () => setRefresh(!refresh);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [userId, setUserId] = useState<string | null>(null);
    const [customerPaginationModel, setCustomerPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [data, setData] = useState<CustomerManagementTableInterface["data"]>(
        []
    );
    const [totalCustomercount, setTotalCustomerCount] = useState<number>(0);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKey(value);
    };
    const handleHandlerSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setHandlerSearchKey(value);
    };

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    };
    const toggleHandlerFilter = () => {
        setOpenHandlerFilter(!openHandlerFilter);
    };

    const handleClearSearch = () => setSearchKey("");

    const handleSubmitSearch = () => {
        isInitialRender.current = false;
        setCustomerPaginationModel((prev) => ({ ...prev, page: 0 }));
        handleRefresh();
    };
    const handleClearHandlerSearch = () => setHandlerSearchKey("");

    const handleHandlerSubmitSearch = () => {
        isInitialHandlerRender.current = false;
        setHandlerPaginationModel((prev) => ({ ...prev, page: 0 }));
        handleAgentRefresh();
    };

    const fetchAgentAnalytics = () => {
        Dispatch(toggleLoading(true));
        CountByAgent(ID)
            .then(res => setAnalyticsData(res?.data?.data))
            .catch(err => setAnalyticsData([]))
            .finally(() => Dispatch(toggleLoading(false)))
    }

    const fetchAgentDetails = () => {
        Dispatch(toggleLoading(true));
        AgentGetById(ID)
            .then((res) => {
                const data = res?.data;
                if (data?.statusCode === AppStatusCode.api_success) {
                    let DATA: any = data?.data;
                    setUserId(DATA?.userDetails?._id);
                    setAgentDetails({
                        id: DATA?._id,
                        email: DATA?.userDetails?.email,
                        name: DATA?.userDetails?.name,
                        phone: DATA?.userDetails?.phone,
                        alternativePhoneNumber: DATA?.phone,
                        isActive: DATA?.isActive,
                        isApprove: DATA?.isApproved,
                        role: DATA?.userDetails?.role,
                        county: DATA?.county,
                        msisdn: DATA?.agentMSISDN,
                        preferredLanguage: DATA?.preferredLanguage,
                        type: DATA?.type,
                        subCounty: DATA?.subCounty,
                        date: DATA?.createdAt,
                        assignedLocation: [
                            {
                                region: DATA?.assignedLocation[0]?.region,
                                districts: DATA?.assignedLocation[0]?.districts
                            }
                        ],
                    })
                }
            })
            .catch((error) => {
                setAgentDetails(null)
            })
            .finally(() => Dispatch(toggleLoading(false)));
    }


    useEffect(() => {
        let fetchList: (() => void) | null = () => {
            setDataLoading(true);
            let PAYLOAD_DATA: GetAllCustomersByAgentProps["DATA"] = {
                searchKey: searchKey,
                agentId: ID,
                kycStatus: customerFilters?.kycStatus || undefined,
                serviceStatus: customerFilters?.serviceStatus || undefined,
                customerType: customerFilters?.customerType || undefined,
                identityType: customerFilters?.identityType || undefined,
                fromDate: customerFilters?.fromDate
                    ? moment(customerFilters.fromDate).format("YYYY/MM/DD")
                    : undefined,
                toDate: customerFilters?.toDate
                    ? moment(customerFilters.toDate).format("YYYY/MM/DD")
                    : undefined,
                pageNumber: customerPaginationModel.page + 1,
                pageSize: customerPaginationModel.pageSize,
            };

            let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

            GetCustomerByAgentId({
                DATA: FILTERED_PAYLOAD_DATA,
            })
                .then((res) => {
                    const data = res?.data;

                    if (data?.statusCode === AppStatusCode.api_success) {
                        let count = res?.data?.meta?.count;
                        if (count && count > 1) {
                            if (count > 1) setTotalCustomerCount(count);
                        } else setTotalCustomerCount(0);

                        let DATA: any = res?.data?.data;
                        DATA = DATA?.map((item: any) => ({
                            id: item?._id,
                            MSISDN: item?.MSISDN || "",
                            nameAsDocument: item?.nameAsDocument || "",
                            identityType: item?.identityType || "",
                            kyc_Details: item?.kyc_Details,
                            MSISDN_details: item?.MSISDN_details,
                            createdAt: item?.createdAt || "",
                        }));
                        setData(DATA);
                    } else {
                        setData([]);
                        setTotalCustomerCount(0);
                    }
                })
                .catch(() => {
                    setData([]);
                    setTotalCustomerCount(0);
                })
                .finally(() => {
                    setDataLoading(false);
                });
        };
        fetchList();
        return () => {
            fetchList = null;
        };
    }, [customerPaginationModel, refresh, customerFilters, searchKey]);

    useEffect(() => {

        let fetchAllAgentByDistributor: (() => void) | null = () => {
            setHandlersDataLoading(true);
            if (!ID) return;
            let PAYLOAD_DATA: GetAllHandlersByAgentProps["DATA"] = {
                searchKey: handlerSearchKey,
                agentId: ID,
                isActive: handlerFilters.activeStatus == "active" ? true : handlerFilters.activeStatus == "inActive" ? false : undefined,
                isApprove: handlerFilters.approveStatus == "approve" ? true : handlerFilters.approveStatus == "notApprove" ? false : undefined,
                pageNumber: handlerPaginationModel.page + 1,
                pageSize: handlerPaginationModel.pageSize,
            };

            let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);
            GetAllHandlersByAgent({ DATA: FILTERED_PAYLOAD_DATA })
                .then(res => {
                    const data = res?.data;
                    if (data?.statusCode === AppStatusCode.api_success) {
                        let count = res?.data?.meta?.count;
                        if (count && count > 1) {
                            if (count > 1) setTotalHandlerCount(count);
                        } else setTotalHandlerCount(0);
                        let DATA: any = res?.data?.data;
                        console.log(DATA)
                        DATA = DATA?.map((item: any) => ({
                            id: item?._id,
                            name: item?.userDetails?.name || "",
                            email: item?.userDetails?.email || "",
                            phone: item?.userDetails?.phone || "",
                            role: item?.userDetails?.role || "",
                            date: item?.createdAt || "",
                            isActive: item?.isActive
                        }));
                        setHandlersData(DATA);
                        setTotalHandlerCount(res?.data?.meta?.count)
                    }
                    else {
                        console.log('elseeeeeeeeee')
                        setHandlersData([]);
                        setTotalHandlerCount(0);
                    }
                })
                .catch((err) => {
                    console.log("erorrrrrrrrrrrrrr", err)
                    setHandlersData([]);
                    setTotalHandlerCount(0);
                })
                .finally(() => {
                    setHandlersDataLoading(false);
                    // setHandlersData(dummyData)
                });
        };
        fetchAllAgentByDistributor();
        return () => {
            fetchAllAgentByDistributor = null;
        };
    }, [handlerSearchKey, handlerPaginationModel, handlerFilters, handlerRefresh])

    useEffect(() => {
        fetchAgentAnalytics();
        fetchAgentDetails();
    }, [])

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <AgentAnalytics Data={analyticsData} />
            {agentDetails && <AgentDetailsTable DATA={agentDetails} UserId={userId} />}

            {/* //HANDLERS TABLE// */}
            <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
                <Typography color="text.primary" className="tw-text-[18px]" sx={{ fontWeight: "bold" }}>
                    {"Handlers List"}
                </Typography>
                <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
                    <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
                        <Box sx={{ flex: "1 1 auto" }}>
                            <SectionSearch
                                name="search_sim"
                                value={handlerSearchKey}
                                onChange={handleHandlerSearch}
                                onClear={handleClearHandlerSearch}
                                onSubmit={handleHandlerSubmitSearch}
                            />
                        </Box>
                        <FilterButton onClick={() => setOpenHandlerFilter(true)} />
                    </Grid>
                </Grid>
                <HandlersTable DATA={handlersData}
                    loading={handlersDataLoading}
                    count={totalHandlercount}
                    paginationModel={handlerPaginationModel}
                    setPaginationModel={setHandlerPaginationModel} />
                <HandlerDetailsFilter
                    open={openHandlerFilter}
                    onClose={toggleHandlerFilter}
                    filters={handlerFilters}
                    setFilters={setHandlerFilters}
                />
            </Card>

            {/* //CUSTOMERS TABLE// */}
            <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
                <Typography color="text.primary" className="tw-text-[18px]" sx={{ fontWeight: "bold" }}>
                    {"Customers List"}
                </Typography>
                <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
                    <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
                        <Box sx={{ flex: "1 1 auto" }}>
                            <SectionSearch
                                name="search_sim"
                                value={searchKey}
                                onChange={handleSearch}
                                onClear={handleClearSearch}
                                onSubmit={handleSubmitSearch}
                            />
                        </Box>
                        <FilterButton onClick={() => setOpenFilter(true)} />
                    </Grid>
                </Grid>
                <CustomerManagementTable data={data}
                    loading={dataLoading}
                    count={totalCustomercount}
                    paginationModel={customerPaginationModel}
                    setPaginationModel={setCustomerPaginationModel} />
                <CustomerManagementFilter
                    open={openFilter}
                    onClose={toggleFilter}
                    filters={customerFilters}
                    setFilters={setCustomerFilters}
                />
            </Card>
        </Box>
    )
}

export default AgentDeailsLayout;