import {
  GET_ALL_COUNTY_BY_DISTRICT,
  GET_ALL_COUNTY_BY_DISTRICT_NAME,
  GET_ALL_DISTRICT_BY_REGION,
  GET_ALL_DISTRICT_BY_REGION_NAME,
  GET_ALL_REGIONS,
  GET_ALL_SUB_COUNTY_BY_COUNTY,
  GET_ALL_SUB_COUNTY_BY_COUNTY_NAME,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const GetAllRegions = () => {
  return HTTP({
    Method: "GET",
    Url: GET_ALL_REGIONS,
  });
};

export const GetAllDistrictByRegion = (REGION_ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_ALL_DISTRICT_BY_REGION(REGION_ID),
  });
};

export const GetAllDistrictByRegionName = (REGION_NAME: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_ALL_DISTRICT_BY_REGION_NAME(REGION_NAME),
  });
};

export const GetAllCountyByDistrict = (DISTRICT_ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_ALL_COUNTY_BY_DISTRICT(DISTRICT_ID),
  });
};

export const GetAllCountyByDistrictName = (DISTRICT_NAME: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_ALL_COUNTY_BY_DISTRICT_NAME(DISTRICT_NAME),
  });
};

export const GetAllSubCountyByCounty = (COUNTY_ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_ALL_SUB_COUNTY_BY_COUNTY(COUNTY_ID),
  });
};

export const GetAllSubCountyByCountyName = (County_NAME: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_ALL_SUB_COUNTY_BY_COUNTY_NAME(County_NAME),
  });
};
