export interface HandlerFilterModelProps {
  activeStatus?: "active" | "inActive" |"";
  approveStatus?: "approve" | "notApprove" | "";
}

const defaultHandlerFilterModelProps: HandlerFilterModelProps =
  {
    activeStatus: "",
    approveStatus: "",
  };

export class HandlersFilterModel {
  activeStatus?: "active" | "inActive" |"";
  approveStatus?: "approve" | "notApprove" | "";

  constructor(
    data: HandlerFilterModelProps = defaultHandlerFilterModelProps
  ) {
    this.activeStatus = data.activeStatus || "";
    this.approveStatus = data.approveStatus || "";
  }
}
