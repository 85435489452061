import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import React from "react";

interface Props {
  children: React.ReactNode;
}
const DetailsTable: React.FC<Props> = ({ children }) => {
  return (
    <TableContainer>
      <Table
        className="tw-border"
        sx={{
          "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
            "&:first-of-type": {
              width: "20%",
              fontWeight: 500,
            },
          },
        }}
      >
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailsTable;
