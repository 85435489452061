import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import { VerificationVerify } from "functions/http-requests/verification";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import ChangePasswordForm from "./changePasswordForm";
import { TokenNotFound, TokenNotVerified } from "./changePasswordMessageBoxes";

const ChangePasswordLayout = () => {
  const Dispatch = useDispatch();

  const [params] = useSearchParams();
  const token = params?.get("token");

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [tokenVerified, setTokenVerified] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      let verifyToken: (() => void) | null = () => {
        Dispatch(toggleLoading(true));
        VerificationVerify({
          DATA: { token },
        })
          .then((res) => {
            const data = res?.data;
            if (data?.statusCode === AppStatusCode.api_success) {
              setTokenVerified(true);
            } else {
              setTokenVerified(false);
            }
            Dispatch(setAlert({ type: data?.level, message: data?.message }));
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
              setTokenVerified(false);
            }
          })
          .finally(() => {
            setIsLoaded(true);
            Dispatch(toggleLoading(false));
          });
      };

      verifyToken();

      return () => {
        verifyToken = null;
      };
    } else {
      setTokenVerified(false);
      setIsLoaded(true);
    }
  }, [token, Dispatch]);

  if (!isLoaded)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 99999 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  if (!token) return <TokenNotFound />;

  if (token && isLoaded && !tokenVerified) return <TokenNotVerified />;

  return (
    <>
      <ChangePasswordForm token={token} />
    </>
  );
};

export default ChangePasswordLayout;
