import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import SectionTitle from "parts/sectionTitle";
import React from "react";

interface Props {
  onClose?: () => void;
  title?: string;
  subTitle?: string;
}

const CustomDialogHeader: React.FC<Props> = ({ onClose, title, subTitle }) => {
  return (
    <>
      {title ? (
        <>
          <Box className="tw-w-[100%] tw-flex tw-justify-between tw-items-center">
            <DialogTitle sx={{ m: 0, p: 0 }}>
              <SectionTitle
                title={title}
                fontSize={22}
                fontWeight="medium"
                variant="body2"
              />
              {subTitle ? (
                <Typography className="tw-pt-[6px]">{subTitle}</Typography>
              ) : (
                <></>
              )}
            </DialogTitle>
            {onClose ? (
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomDialogHeader;
