import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
  GetRegionalDistributorProps,
  UpdateRegionalDistributorProps,
} from "_interfaces/functions/http-requests/distributor-channels/regional-distributors";
import RegionalDistributorsTableInterface from "_interfaces/regional-distributors/regionalDistributorsTable";
import { AppStatusCode } from "config/appStatusCode";
import { GetRegionalDistributor } from "functions/http-requests/regional-distributors";
import CustomDrawer from "parts/customDialog/customDrawer";
import { useEffect, useRef, useState } from "react";
import RegionalDistributorsForm from "./regionalDistributorsForm";
import RegionalDistributorsTable from "./regionalDistributorsTable";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { RegionalDistributorsFilterModel } from "_models/data/regional-distributors/data.regional-distributors-filter.model";
import SectionSearch from "parts/sectionSearch";
import FilterButton from "parts/filterButton";
import RegionalDistributorsFilter from "./regionalDistributorsFilter";
import { filterNonNullValues } from "functions/helper";

const RegionalDistributorsLayout = () => {
  const isInitialRender = useRef(true);

  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [data, setData] = useState<RegionalDistributorsTableInterface["data"]>(
    []
  );

  const [openForm, setOpenForm] = useState<boolean>(false);

  const [editId, setEditId] =
    useState<UpdateRegionalDistributorProps["ID"]>("");

  const [refresh, setRefresh] = useState<boolean>(false);

  const [searchKey, setSearchKey] = useState<string>("");

  const [count, setCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filters, setFilters] = useState<RegionalDistributorsFilterModel>(
    new RegionalDistributorsFilterModel()
  );

  const toggleForm = () => {
    if (editId) return setEditId("");
    setOpenForm(!openForm);
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleRefresh = () => setRefresh(!refresh);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => { 
    const { value } = e.target;
    setSearchKey(value);
  };

  const handleClearSearch = () => setSearchKey("");

  const handleSubmitSearch = () => {
    isInitialRender.current = false;
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
    handleRefresh();
  };

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);

      let PAYLOAD_DATA: GetRegionalDistributorProps["DATA"] = {
        searchKey: searchKey,
        status:
          filters?.status === "true"
            ? true
            : filters?.status === "false"
            ? false
            : undefined,
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        sortBy: filters?.sortBy || undefined,
        sortType: filters?.sortType || undefined,
      };

      PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      GetRegionalDistributor({ DATA: PAYLOAD_DATA })
        .then((res) => {
          const data = res?.data;

          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count;
            if (count && count > 1) {
              if (count > 1) setCount(count);
            } else setCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              name: item?.name || item?.userDetails?.name,
              email: item?.email || item?.userDetails?.email,
              phone: item?.phone || item?.userDetails?.phone,
              assignedLocation: item?.assignedLocation || [],
              isActive: item?.isActive,
              date: item?.createdAt,
            }));
            setData(DATA);
          } else {
            setData([]);
            setCount(0);
          }
        })
        .catch(() => {
          setData([]);
          setCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [refresh, paginationModel, filters]);

  useEffect(() => {
    if (!isInitialRender.current) {
      if (!searchKey) handleRefresh();
    }
  }, [searchKey]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
          <Box sx={{ flex: "1 1 auto" }}>
            <SectionSearch
              name="search_regional_distributor"
              value={searchKey}
              onChange={handleSearch}
              onClear={handleClearSearch}
              onSubmit={handleSubmitSearch}
            />
          </Box>
          <FilterButton onClick={() => setOpenFilter(true)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="tw-flex tw-justify-end tw-items-center">
            <Button
              onClick={toggleForm}
              variant="outlined"
              startIcon={<AddIcon />}
              disableElevation
            >
              <Box component="span" className="tw-line-clamp-1">
                Add New Distributor
              </Box>
            </Button>
          </Box>
        </Grid>
      </Grid>

      <RegionalDistributorsTable
        data={data}
        loading={dataLoading}
        count={count}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        setEditId={setEditId}
        setFilters={setFilters}
      />

      <RegionalDistributorsFilter
        open={openFilter}
        onClose={toggleFilter}
        filters={filters}
        setFilters={setFilters}
      />

      <CustomDrawer
        open={openForm || editId ? true : false}
        onClose={toggleForm}
        title={editId ? "Edit Distributor Details" : "Add New Distributor"}
      >
        <RegionalDistributorsForm
          handleRefresh={handleRefresh}
          onClose={toggleForm}
          editId={editId}
        />
      </CustomDrawer>
    </>
  );
};

export default RegionalDistributorsLayout;
