import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { BreadCrumbsLinksProps } from "_interfaces/_common/breadcrumbs";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  links: BreadCrumbsLinksProps[];
}
const CustomBreadCrumbs: React.FC<Props> = ({ links }) => {
  return (
    <Breadcrumbs className="tw-text-[14px] tw-pt-[4px]">
      {links.map(({ path, label }, index) => (
        <Box key={index}>
          {path && label ? (
            <Link color="inherit" to={path} className="tw-text-[14px]">
              {label}
            </Link>
          ) : label ? (
            <Typography color="text.primary" className="tw-text-[14px]">
              {label}
            </Typography>
          ) : (
            <></>
          )}
        </Box>
      ))}
    </Breadcrumbs>
  );
};

export default CustomBreadCrumbs;
