import { useEffect } from "react";

const useScrollToTop = (): void => {
  useEffect(() => {
    const scrollToTop = (): void => {
      window.scrollTo(0, 0);
    };

    // Scroll to top when component mounts
    scrollToTop();

    // Add event listener to scroll to top when URL changes
    const handleUrlChange = (): void => {
      scrollToTop();
    };
    window.addEventListener("popstate", handleUrlChange);

    return () => {
      // Cleanup the event listener on unmount
      window.removeEventListener("popstate", handleUrlChange);
    };
  }, []);
};

export default useScrollToTop;
