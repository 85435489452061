import { COUNT_BY_HANDLER, GET_ALL_HANDLERS, GET_CUSTOMER_BY_HANDLER, GET_HANDLER_BY_ID } from "config/endpoint";
import { HTTP } from "functions/http";
import { GetAllCustomersByHandlerProps, GetAllHandlersProps } from "../../../_interfaces/functions/http-requests/handlers"

export const CountByHandler = (ID: string) => {
  return HTTP({
    Method: "Get",
    Url: COUNT_BY_HANDLER(ID),
  });
};

export const GetHandlersById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_HANDLER_BY_ID(ID),
  });
};
export const GetAllHandlers = ({ DATA }: GetAllHandlersProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_ALL_HANDLERS,
    Data: DATA,
  });
};
export const GetCustomersByHandler = ({ DATA }: GetAllCustomersByHandlerProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_CUSTOMER_BY_HANDLER,
    Data: DATA
  });
};