export interface CreateReportProps {
  reportType: string;
  startDate: string;
  endDate: string;
}

const defaultCreateReportProps: CreateReportProps = {
  reportType: "",
  startDate: "",
  endDate: "",
};

export class CreateReportModel {
  reportType: string;
  startDate: string;
  endDate: string;

  constructor(data: CreateReportProps = defaultCreateReportProps) {
    this.reportType = data.reportType || "";
    this.startDate = data.startDate || "";
    this.endDate = data.endDate || "";
  }
}
