type LoginProps = {
  // loginAs?: string;
  phone?: string;
  password?: string;
  userAgent?: string;
  // IP?: string;
  location?: string;
  timeZone?: string;
  stayLogin?: boolean;
};

export class LoginModel {
  // loginAs?: string;
  phone: string;
  password: string;
  userAgent: string;
  // IP: string;
  location: string;
  timeZone: string;
  stayLogin: boolean;

  constructor(data: LoginProps = {}) {
    // this.loginAs = data.loginAs || "";
    this.phone = data.phone || "";
    this.password = data.password || "";
    this.userAgent = data.userAgent || "";
    // this.IP = data.IP || "";
    this.location = data.location || "";
    this.timeZone = data.timeZone || "";
    this.stayLogin = data.stayLogin || false;
  }
}
