import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { GridPaginationModel } from "@mui/x-data-grid";
import { GetAllSimProps } from "_interfaces/functions/http-requests/simManagement";
import SimManagementTableInterface from "_interfaces/sim-management/simManagementTable";
import { SimManagementFilterModel } from "_models/data/sim-management/data.sim-management-filter.model";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import {
  GetAllSim,
  GetAllSimCount,
} from "functions/http-requests/simManagement";
import CustomDrawer from "parts/customDialog/customDrawer";
import FilterButton from "parts/filterButton";
import SectionSearch from "parts/sectionSearch";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toggleLoading } from "state/reducers/loading";
import SimManagementFilter from "./simManagementFilter";
import SimManagementTable from "./simManagementTable";
import TopStatistics from "./topStatistics";
import UploadCartonForm from "./upload-carton/uploadCartonForm";

const SimManagementLayout = () => {
  const isInitialRender = useRef(true);
  const Dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("sim-type");

  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [data, setData] = useState<SimManagementTableInterface["data"]>([]);

  const [openUploadCartonForm, setOpenUploadCartonForm] =
    useState<boolean>(false);

  const [refresh, setRefresh] = useState<boolean>(false);

  const [searchKey, setSearchKey] = useState<string>("");

  const [count, setCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filters, setFilters] = useState<SimManagementFilterModel>(
    new SimManagementFilterModel()
  );

  const [topStatisticsData, setTopStatisticsData] = useState(null);

  const [activeButton, setActiveButton] = useState<string>("");

  const toggleUploadCarton = () => {
    setOpenUploadCartonForm(!openUploadCartonForm);
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleRefresh = () => setRefresh(!refresh);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchKey(value);
  };

  const handleClearSearch = () => setSearchKey("");

  const handleSubmitSearch = () => {
    isInitialRender.current = false;
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
    handleRefresh();
  };

  const handleSetSearchParams = () => {
    setSearchParams();
  };

  const handleButtonClick = (id: string, filterName: string) => {
    if (id && filterName) {
      if (id === "all") {
        setFilters(new SimManagementFilterModel());
      } else {
        setFilters({
          ...new SimManagementFilterModel(),
          [filterName]: id,
        });
      }
      setActiveButton(id);
    }
  };

  const handleClearActiveButton = () => {
    setFilters(new SimManagementFilterModel());
    setActiveButton("");
  };

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);
      let PAYLOAD_DATA: GetAllSimProps["DATA"] = {
        searchKey: searchKey,
        SIM_type: filters.SIM_type,
        serviceType: filters.serviceType,
        status: filters.status,
        isReserved:
          filters?.isReserved === "true"
            ? true
            : filters?.isReserved === "false"
            ? false
            : "",
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
      };

      let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      GetAllSim({
        DATA: FILTERED_PAYLOAD_DATA,
      })
        .then((res) => {
          const data = res?.data;

          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count;
            if (count && count > 1) {
              if (count > 1) setCount(count);
            } else setCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              ICCID: item?.ICCID || "",
              simType: item?.SIM_type || "",
              serviceType: item?.serviceType || "",
              status: item?.status || "",
              isReserved: item?.isReserved,
            }));
            setData(DATA);
          } else {
            setData([]);
            setCount(0);
          }
        })
        .catch(() => {
          setData([]);
          setCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [paginationModel, refresh, filters]);

  useEffect(() => {
    if (!isInitialRender.current) {
      if (!searchKey) handleRefresh();
    }
  }, [searchKey]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      Dispatch(toggleLoading(true));
      GetAllSimCount()
        .then((res) => {
          // if (res?.data === AppStatusCode.api_success) {
          const DATA = res?.data?.data;
          const formattedData: any = {
            total: DATA?.total || 0,
          };

          DATA?.byStatus?.forEach((item: any) => {
            formattedData[item?._id] = item?.count;
          });
          setTopStatisticsData(formattedData);
          // }
        })
        .catch(() => {})
        .finally(() => Dispatch(toggleLoading(false)));
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [Dispatch]);

  useEffect(() => {
    if (query) {
      setFilters((prev) => ({ ...prev, SIM_type: query }));
    }
  }, [query]);

  const buttonsList = [
    {
      id: "all",
      label: "All",
      filterName: "status",
    },
    {
      id: "active",
      label: "Active",
      filterName: "status",
    },
    { id: "idle", label: "Idle", filterName: "status" },
  ];

  return (
    <>
      <TopStatistics data={topStatisticsData} />

      <Box pt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
            <Box sx={{ flex: "1 1 auto" }}>
              <SectionSearch
                name="search_sim"
                value={searchKey}
                onChange={handleSearch}
                onClear={handleClearSearch}
                onSubmit={handleSubmitSearch}
              />
            </Box>
            <FilterButton onClick={() => setOpenFilter(true)} />
          </Grid>
          <Grid item xs={12} sm={6} className="tw-flex tw-justify-end">
            <Button
              variant="outlined"
              className="tw-mr-[12px]"
              onClick={toggleUploadCarton}
            >
              Upload Carton
            </Button>
            <Button variant="contained">Manage Stock</Button>
          </Grid>
          <Grid item xs={12}>
            {buttonsList?.map(({ id, label, filterName }, i) => (
              <Button
                key={i}
                variant="outlined"
                color={activeButton === id ? "primary" : "secondary"}
                className="tw-ml-[8px]"
                onClick={() => handleButtonClick(id, filterName)}
              >
                {label}
              </Button>
            ))}
          </Grid>
        </Grid>
      </Box>

      <Box pt={3}>
        <SimManagementTable
          data={data}
          loading={dataLoading}
          count={count}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      </Box>

      <SimManagementFilter
        open={openFilter}
        onClose={toggleFilter}
        filters={filters}
        setFilters={setFilters}
        handleSetSearchParams={handleSetSearchParams}
        handleClearActiveButton={handleClearActiveButton}
      />

      <CustomDrawer
        open={openUploadCartonForm}
        onClose={toggleUploadCarton}
        title="Upload Carton"
      >
        <UploadCartonForm onClose={toggleUploadCarton} />
      </CustomDrawer>
    </>
  );
};

export default SimManagementLayout;
