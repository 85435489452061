import {
  VerificationSendSmsOtpProps,
  VerificationVerifyProps,
  VerificationVerifySmsOtpProps,
} from "_interfaces/functions/http-requests/verification";
import {
  VERIFICATION_SEND_SMS_OTP,
  VERIFICATION_VERIFY,
  VERIFICATION_VERIFY_SMS_OTP,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const VerificationVerify = ({ DATA }: VerificationVerifyProps) => {
  return HTTP({
    Method: "POST",
    Url: VERIFICATION_VERIFY,
    Data: DATA,
  });
};

export const VerificationSendSmsOtp = ({
  DATA,
}: VerificationSendSmsOtpProps) => {
  return HTTP({
    Method: "POST",
    Url: VERIFICATION_SEND_SMS_OTP,
    Data: DATA,
  });
};

export const VerificationVerifySmsOtp = ({
  DATA,
}: VerificationVerifySmsOtpProps) => {
  return HTTP({
    Method: "POST",
    Url: VERIFICATION_VERIFY_SMS_OTP,
    Data: DATA,
  });
};
