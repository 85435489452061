import { createTheme } from "@mui/material/styles";
import { Theme, ThemeOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface CustomTheme extends Theme {
    customPalette: {
      primary: string;
    };
  }
  // allow configuration using `createTheme`
  interface CustomThemeOptions extends ThemeOptions {
    customPalette?: {
      primary?: string;
    };
  }

  export function createTheme(options?: CustomThemeOptions): CustomTheme;
}

const theme = createTheme({
  customPalette: {
    primary: "#e39725",
  },
  typography: {
    fontFamily: `'Poppins', sans-serif;`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  palette: {
    primary: {
      main: "#e39725",
      contrastText: "#fff",
      light: "#e7b521",
    },
    secondary: {
      main: "#414042",
      contrastText: "#fff",
      light: "#F8F8F8",
    },
    text: {
      primary: "#666",
      secondary: "#8E8E8E",
    },
    info: {
      main: "#000",
      contrastText: "#fff",
    },
    success: {
      main: "#34a300",
      contrastText: "#f5fff0",
    },
    error: {
      main: "#c60606",
      contrastText: "#fff0f0",
    },
    warning: {
      main: "#f5c416",
      contrastText: "#fffdf4",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: "1rem",
          textTransform: "capitalize",
          paddingTop: 5,
          paddingBottom: 5,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          lineHeight: 1.3,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ".MuiInputBase-input": {
            fontSize: "14px",
          },
          ".MuiOutlinedInput-notchedOutline": {
            // background: alpha(palette.secondary.main, 0.04),
            borderRadius: "4px",
            borderWidth: "1px !important",
            borderColor: "#e5e7eb",
          },
          "&:not(.Mui-disabled)": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e39725",
            },
          },
        },
      },
    },
  },
});

export default theme;
