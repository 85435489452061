import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { filterNonNullValues } from "functions/helper";
import { HTTP_ERROR } from "functions/http";
import { CallKycServerApi } from "functions/http-requests/kyc";
import moment from "moment";
import CustomDrawer from "parts/customDialog/customDrawer";
import { IsActiveCell } from "parts/table/customCell";
import DetailsBox from "parts/table/detailsBox";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import { CallKycServerApiProps } from "_interfaces/functions/http-requests/kyc";
import { ServerApiResponse, ServerInfo } from "_interfaces/kyc";

interface Props {
  serverName?: string;
  kycId: string;
  Url?: CallKycServerApiProps["Url"];
  type?: CallKycServerApiProps["DATA"]["type"];
  data?: ServerInfo | null;
  isRetryEnabled?: boolean;
  handleRefresh: () => void;
}

const KycServerInfoTable: React.FC<Props> = ({
  serverName,
  kycId,
  Url,
  type,
  data,
  isRetryEnabled,
  handleRefresh,
}) => {
  const Dispatch = useDispatch();
  const { palette } = useTheme();

  const [openRetryModal, setOpenRetryModal] = useState<boolean>(false);
  const [openApiResponses, setOpenApiResponses] = useState<boolean>(false);

  const handleToggleApiResponses = () => setOpenApiResponses(!openApiResponses);

  const handleRetryClick = () => {
    if (isRetryEnabled) setOpenRetryModal(true);
  };

  const handleCancelRetry = () => {
    setOpenRetryModal(false);
  };

  const handleCreateHlrHss = (
    KYC_ID: string,
    URL: CallKycServerApiProps["Url"],
    TYPE: CallKycServerApiProps["DATA"]["type"]
  ) => {
    Dispatch(toggleLoading(true));
    let PAYLOAD = {
      kycId: KYC_ID,
      type: TYPE || undefined,
    };

    PAYLOAD = filterNonNullValues(PAYLOAD);

    CallKycServerApi({
      Url: URL,
      DATA: PAYLOAD,
    })
      .then((res) => {
        const data = res?.data;

        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        handleRefresh();
        setOpenRetryModal(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        }
      })
      .finally(() => {
        Dispatch(toggleLoading(false));
      });
  };

  const handleConfirmRetry = () => {
    if (kycId && Url) {
      handleCreateHlrHss(kycId, Url, type);
    }
  };

  const rows: {
    field: keyof NonNullable<ServerInfo>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
    {
      field: "status",
      headerName: "Status",
      renderCell: (v) => <IsActiveCell status={v} />,
    },
    {
      field: "isVerified",
      headerName: "Verification",
      renderCell: (v) => (
        <Box className="tw-flex tw-items-center tw-gap-3">
          <IsActiveCell
            status={data?.isVerified ? "Verified" : "Not Verified"}
            statusColor={
              data?.isVerified ? palette.success.main : palette.error.main
            }
          />
          {!data?.isVerified && (
            <Button
              variant="outlined"
              size="small"
              sx={{ fontSize: "inherit" }}
              onClick={handleRetryClick}
              disabled={!isRetryEnabled}
            >
              Retry
            </Button>
          )}
        </Box>
      ),
    },
    { field: "tryCount", headerName: "Try count" },
    {
      field: "API_Responses",
      headerName: "API Responses",
      renderCell(v) {
        return data?.API_Responses?.length ? (
          <Button
            onClick={handleToggleApiResponses}
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
          >
            View Responses
          </Button>
        ) : (
          <Box>N/A</Box>
        );
      },
    },
  ];

  const apiResponsesRows: {
    field: keyof NonNullable<ServerApiResponse>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
    {
      field: "status",
      headerName: "Status",
      renderCell: (v) => <IsActiveCell status={v} />,
    },
    { field: "statusCode", headerName: "Status Code" },
    { field: "responseMessage", headerName: " Response Message" },
    {
      field: "time",
      headerName: "Date & Time",
      renderCell: (v: string) => (
        <>
          <Box>{moment(v).format("Do MMM, YYYY")}</Box>
          <Box fontSize="small">{moment(v).format("h:mm:ss")}</Box>
        </>
      ),
    },
  ];

  return (
    <>
      <DetailsBox data={data} rows={rows} fullWidth />

      <Dialog
        open={openRetryModal}
        onClose={handleCancelRetry}
        maxWidth="sm"
        fullWidth
        aria-labelledby="retry-dialog-title"
        aria-describedby="retry-dialog-description"
      >
        <DialogTitle id="retry-dialog-title">Are you sure?</DialogTitle>
        <DialogContent
          sx={{
            pb: 5,
          }}
        >
          <DialogContentText id="retry-dialog-description">
            Are you sure you want to retry?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelRetry} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleConfirmRetry} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <CustomDrawer
        title={`API Responses - ${serverName}`}
        open={openApiResponses}
        onClose={handleToggleApiResponses}
      >
        <Grid container spacing={2}>
          {data?.API_Responses?.map((response, i) => (
            <Grid item xs={12} key={i}>
              <DetailsBox data={response} rows={apiResponsesRows} fullWidth />
            </Grid>
          ))}
        </Grid>
      </CustomDrawer>
    </>
  );
};

export default KycServerInfoTable;
