import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { CustomerDocInterface } from "_interfaces/customer-management/details";
import Grid from "@mui/material/Grid";

interface Props {
  onClose?: () => void;
  identityType?: string;
  data: CustomerDocInterface;
}

interface Document {
  url: string | undefined;
  type: string;
}

const CustomerDocs: React.FC<Props> = ({ onClose, identityType, data }) => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    const docs: Document[] = [
      { url: data?.customerPhoto?.Url, type: "Customer Photo" },
      ...(identityType === "citizen"
        ? [
            {
              url: data?.nationalIdDocumentScanFront?.Url,
              type: "National ID Front Side",
            },
            {
              url: data?.nationalIdDocumentScanBack?.Url,
              type: "National ID Back Side",
            },
          ]
        : []),
      ...(identityType === "tourist"
        ? [
            { url: data?.passportFrontScan?.Url, type: "Passport Front Side" },
            { url: data?.passportBackScan?.Url, type: "Passport Back Side" },
            { url: data?.visaFrontScan?.Url, type: "Visa Front Side" },
            { url: data?.visaBackScan?.Url, type: "Visa Back Side" },
          ]
        : []),
      ...(identityType === "refugee"
        ? [
            {
              url: data?.refugeeDocumentFrontScan?.Url,
              type: "Document Front Side",
            },
            {
              url: data?.refugeeDocumentBackScan?.Url,
              type: "Document Back Side",
            },
            { url: data?.digitalForm?.Url, type: "Digital Form" },
          ]
        : []),
    ];
    setDocuments(docs);
    setCurrentIndex(0); // Reset index when documents change
  }, [data, identityType]);

  return (
    <Box width="100%">
      <Grid container spacing={3}>
        {documents.map((doc, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <RenderDoc
              url={doc.url}
              type={doc.type}
              documents={documents}
              initialIndex={index}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

interface RenderDocProps {
  url?: string;
  type: string;
  documents: Document[];
  initialIndex: number;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}

const RenderDoc: React.FC<RenderDocProps> = ({
  url,
  type,
  documents,
  initialIndex,
  currentIndex,
  setCurrentIndex,
}) => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setCurrentIndex(initialIndex);
    setOpen(true);
  };
  const handleClose = () => {
    setCurrentIndex(0);
    setOpen(false);
  };

  const handleNext = () => {
    if (currentIndex < documents.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  //   useEffect(() => {
  //     onIndexChange(currentIndex);
  //   }, [currentIndex, onIndexChange]);

  //   useEffect(() => {
  //     setCurrentIndex(initialIndex);
  //   }, [initialIndex]);

  return (
    <>
      <Box className="tw-shadow-md tw-border-2 tw-border-gray-200 tw-bg-gray-200 tw-rounded-lg tw-overflow-hidden">
        <Box className="tw-w-[100%] tw-h-[250px] tw-bg-white ">
          <img
            src={url || ""}
            alt={type}
            className="tw-w-[100%] tw-h-[100%] tw-object-contain"
            onClick={handleOpen}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <Box className="tw-flex tw-justify-between tw-items-center" p={1}>
          <Typography variant="h4" sx={{ color: palette.common.black }}>
            {type}
          </Typography>
          <Box className="tw-flex tw-items-center">
            <IconButton size="small" onClick={handleOpen}>
              <ZoomInIcon fontSize="small" />
            </IconButton>
            <a href={url || "#"} download>
              <IconButton size="small">
                <FileDownloadIcon fontSize="small" />
              </IconButton>
            </a>
          </Box>
        </Box>
      </Box>

      {/* Modal for Full Image View */}
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "rgba(0, 0, 0, 0.7)",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            outline: "none",
            overflow: "hidden",
          }}
        >
          {/* Close Button */}
          <IconButton
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              color: palette.common.white,
              zIndex: 1, // Ensure it's above other elements
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          {/* Download Button */}
          <a
            href={documents[currentIndex]?.url || "#"}
            download
            target="_blank"
          >
            <IconButton
              sx={{
                position: "absolute",
                top: 16,
                right: 56,
                color: palette.common.white,
                zIndex: 1, // Ensure it's above other elements
              }}
            >
              <FileDownloadIcon />
            </IconButton>
          </a>

          {/* Navigation Buttons */}
          <IconButton
            sx={{
              position: "absolute",
              top: "50%",
              left: 16,
              transform: "translateY(-50%)",
              color: palette.common.white,
              fontSize: 40,
              bgcolor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1,
              "&:hover": {
                bgcolor: "rgba(0, 0, 0, 0.7)",
              },
            }}
            onClick={handlePrev}
            disabled={currentIndex === 0} // Disable when at the first item
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            sx={{
              position: "absolute",
              top: "50%",
              right: 16,
              transform: "translateY(-50%)",
              color: palette.common.white,
              fontSize: 40,
              bgcolor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1,
              "&:hover": {
                bgcolor: "rgba(0, 0, 0, 0.7)",
              },
            }}
            onClick={handleNext}
            disabled={currentIndex === documents.length - 1} // Disable when at the last item
          >
            <NavigateNextIcon />
          </IconButton>

          {/* Sliding Images Container */}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              transition: "transform 0.5s ease-in-out",
              transform: `translateX(-${currentIndex * 100}%)`,
              position: "relative",
              zIndex: 0,
            }}
          >
            {documents.map((doc, index) => (
              <Box
                key={index}
                sx={{
                  flexShrink: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={doc.url || ""}
                  alt={doc.type}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            ))}
          </Box>

          {/* Title at Bottom */}
          <Typography
            variant="h4"
            sx={{
              position: "absolute",
              bottom: 16,
              left: "50%",
              transform: "translateX(-50%)",
              color: palette.common.white,
              bgcolor: "rgba(0, 0, 0, 0.5)",
              p: 1,
              borderRadius: 1,
              zIndex: 1,
            }}
          >
            {documents[currentIndex]?.type}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default CustomerDocs;
