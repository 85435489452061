import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";
import React from "react";

const AlertCard = () => {
  const { palette } = useTheme();
  return (
    <>
      <Box
        mt={2}
        p={1}
        className="tw-rounded-md tw-border tw-flex tw-items-center tw-justify-between"
      >
        <Box className="tw-flex tw-items-center tw-justify-between">
          <Box
            className="tw-[48px] tw-h-[48px] tw-rounded-full tw-flex tw-items-center tw-justify-center"
            sx={{
              backgroundColor: alpha(palette.primary.main, 0.1),
              color: palette.primary.main,
              flex: "0 0 48px",
            }}
          >
            <WarningIcon color="inherit" />
          </Box>
          <Box pl={1}>
            <Typography>New Stock Alert</Typography>
            <Typography fontSize={12}>Batch Number #56456GHYUI</Typography>
          </Box>
        </Box>
        <Box className="tw-text-[12px]">Now</Box>
      </Box>
    </>
  );
};

export default AlertCard;
