import React from "react";
import { IconsInterface } from "_interfaces/_common/icons";

const InsightsIcon: React.FC<IconsInterface> = ({ width, height, color }) => {
  return (
    <svg
      width={width || "12"}
      height={height || "12"}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.394772 0C0.176486 0 0 0.176486 0 0.394772V10.617C0 10.8353 0.176486 11.0118 0.394772 11.0118H11.4019C11.6202 11.0118 11.7967 10.8353 11.7967 10.617C11.7967 10.4034 11.6202 10.2269 11.4019 10.2269H0.784899V0.394772C0.784899 0.176486 0.608413 0 0.394772 0ZM8.2577 2.2293C8.02083 2.2293 7.83506 2.45223 7.84435 2.64265C7.85364 2.85165 8.05334 3.02813 8.2577 3.0142H9.3677L5.87978 6.29313L4.63044 5.24814C4.55149 5.15525 4.42609 5.09952 4.30069 5.10881C4.21245 5.11346 4.12885 5.15061 4.06383 5.21099L1.43976 7.57033C1.28185 7.70502 1.25863 7.97903 1.4026 8.13694C1.54193 8.29485 1.81595 8.30414 1.96457 8.15552L4.33785 6.02375L5.60112 7.07338C5.7358 7.23593 6.01446 7.24987 6.16773 7.10589L9.96218 3.53901V4.71868C9.95754 4.92768 10.148 5.1181 10.357 5.1181C10.5613 5.1181 10.7517 4.92768 10.7471 4.71868V2.62407C10.7471 2.41507 10.5613 2.2293 10.357 2.2293H8.2577Z"
        fill={color}
      />
    </svg>
  );
};

export default InsightsIcon;
