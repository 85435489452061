import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Breakpoint, styled } from "@mui/material/styles";
import React from "react";
import CustomDialogHeader from "./customDialogHeader";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
}));

interface Props {
  open: boolean;
  onClose?: () => void;
  title?: string;
  subTitle?: string;
  children: React.ReactNode;
  maxWidth?: Breakpoint | false;
  showCloseButton?: boolean;
}

const CustomDialog: React.FC<Props> = ({
  open,
  onClose,
  title,
  subTitle,
  children,
  maxWidth,
  showCloseButton,
}) => {
  return (
    <BootstrapDialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth={maxWidth}
    >
      {title ? (
        <Box sx={{ m: 0, px: 3, py: 1 }} className="tw-shadow-sm">
          <CustomDialogHeader
            onClose={onClose}
            title={title}
            subTitle={subTitle}
          />
        </Box>
      ) : (
        <></>
      )}
      {!title && showCloseButton ? (
        <Box className="tw-absolute tw-right-[20px] tw-top-[20px]">
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      ) : (
        <></>
      )}

      <DialogContent>{children}</DialogContent>
    </BootstrapDialog>
  );
};

export default CustomDialog;
