import ReportLayout from "component/manage-account/report";
import MainContainer from "parts/mainContainer";

const Report = () => {
  return (
    <MainContainer>
      <ReportLayout />
    </MainContainer>
  );
};

export default Report;
