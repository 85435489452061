import InventoryManagementLayout from "component/sim-management";
import MainContainer from "parts/mainContainer";

const InventoryManagement = () => {
  return (
    <MainContainer>
      <InventoryManagementLayout />
    </MainContainer>
  );
};

export default InventoryManagement;
