interface MsisdnManagementFilterModelProps {
  status?: string;
  isFixed?: string;
  type?: string;
}

const defaultMsisdnManagementFilterModelProps: MsisdnManagementFilterModelProps =
  {
    status: "",
    isFixed: "",
    type: "",
  };

export class MsisdnManagementFilterModel {
  status?: string;
  isFixed?: string;
  type?: string;

  constructor(
    data: MsisdnManagementFilterModelProps = defaultMsisdnManagementFilterModelProps
  ) {
    this.status = data.status || "";
    this.isFixed = data.isFixed || "";
    this.type = data.type || "";
  }
}
