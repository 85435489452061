import { ForgotPasswordModel } from "_models/data/auth/data.forgot-password.model";
import { OtpVerificationModel } from "_models/data/auth/data.otpVerification.model";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import {
  ChangePasswordRequest,
  ResendPasswordRequestOtp,
  VerifyOtpPasswordRequest,
} from "functions/http-requests/auth";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import ChangePasswordForm from "../change-password/changePasswordForm";
import OtpVerificationLayout from "../otpVerification";
import ForgotPasswordForm from "./forgotPasswordForm";
import axios from "axios";

const ForgotPasswordLayout = () => {
  const Dispatch = useDispatch();

  const [isProgress, setIsProgress] = useState<boolean>(false);

  const [steps, setSteps] = useState<{
    needVerification: "both" | "email" | "sms" | "none";
    otpVerified: boolean;
  }>({
    needVerification: "none",
    otpVerified: false,
  });

  const [forgotPasswordState, setForgotPasswordState] =
    useState<ForgotPasswordModel>(new ForgotPasswordModel());
  const [otpState, setOtpState] = useState<OtpVerificationModel>(
    new OtpVerificationModel()
  );

  const handleChangePasswordRequest = () => {
    setIsProgress(true);

    ChangePasswordRequest({
      DATA: {
        phone: forgotPasswordState.phone || "",
      },
    })
      .then((res) => {
        if (res?.data?.statusCode === AppStatusCode.api_success) {
          const data = res?.data?.data;
          if (data) {
            setSteps((prev) => ({
              ...prev,
              needVerification: data?.type,
            }));
            setOtpState(() => ({
              OTP: "",
              token: data?.token,
              type: data?.type,
              otpSent: true,
            }));
          }
          Dispatch(
            setAlert({
              type: res?.data?.level || "success",
              message: res?.data?.message,
            })
          );
        } else {
          Dispatch(
            setAlert({
              type: res?.data?.level || "error",
              message: res?.data?.message,
            })
          );
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => setIsProgress(false));
  };

  const handleOtpVerification = () => {
    if (otpState?.token && otpState?.OTP && otpState?.type) {
      setIsProgress(true);

      VerifyOtpPasswordRequest({
        DATA: {
          token: otpState?.token,
          otp: otpState?.OTP,
          type: otpState?.type,
        },
      })
        .then((res) => {
          if (res?.data?.statusCode === AppStatusCode.api_success) {
            Dispatch(
              setAlert({
                type: res?.data?.level || "success",
                message: res?.data?.message,
              })
            );
            setSteps({
              needVerification: "none",
              otpVerified: true,
            });
          } else {
            setOtpState((prev) => ({ ...prev, OTP: "" }));
            Dispatch(
              setAlert({
                type: res?.data?.level || "error",
                message: res?.data?.message,
              })
            );
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => setIsProgress(false));
    }
  };

  const handleResendOtp = () => {
    if (otpState?.token && otpState.type) {
      Dispatch(toggleLoading(true));
      ResendPasswordRequestOtp({
        DATA: {
          token: otpState.token,
          type: otpState.type,
        },
      })
        .then((res) => {
          const data = res?.data?.data;
          if (data) {
            setSteps((prev) => ({
              ...prev,
              needVerification: data?.type,
            }));
            setOtpState((prev) => ({
              ...prev,
              OTP: "",
              otpSent: true,
            }));
          }
          Dispatch(
            setAlert({
              type: res?.data?.level || "success",
              message: res?.data?.message,
            })
          );
        })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => Dispatch(toggleLoading(false)));
    } else {
      Dispatch(
        setAlert({
          type: "error",
          message: `Mandatory fields missing!`,
        })
      );
    }
  };

  return (
    <>
      {steps?.needVerification === "none" && !steps.otpVerified ? (
        <ForgotPasswordForm
          isProgress={isProgress}
          handleChangePasswordRequest={handleChangePasswordRequest}
          state={forgotPasswordState}
          setState={setForgotPasswordState}
        />
      ) : steps?.needVerification === "none" &&
        steps.otpVerified &&
        otpState.token ? (
        <>
          <ChangePasswordForm token={otpState.token} />
        </>
      ) : (
        <>
          <OtpVerificationLayout
            otpState={otpState}
            setOtpState={setOtpState}
            isProgress={isProgress}
            handleOtpVerification={handleOtpVerification}
            handleResendOtp={handleResendOtp}
          />
        </>
      )}
    </>
  );
};

export default ForgotPasswordLayout;
