import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";

interface Props {
  statisticsValue: string | number;
  title: string;
  desc?: string;
}

const StatisticsCard: React.FC<Props> = ({ statisticsValue, title, desc }) => {
  return (
    <Box className="tw-bg-white tw-rounded-md tw-p-[10px] tw-shadow">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Typography
            color="primary.main"
            fontSize={36}
            className="tw-font-[700] tw-text-center"
          >
            {typeof statisticsValue === "number" &&
            statisticsValue > 0 &&
            statisticsValue < 10
              ? `0${statisticsValue}`
              : statisticsValue}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Box
            fontSize={16}
            lineHeight={1.3}
            color="black"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {desc ? (
            <Typography fontSize={12} className="tw-mt-[4px]">
              {desc}
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatisticsCard;
