import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import AppVersionTableInterface from "_interfaces/apps";
import SimCardChargesTableInterface from "_interfaces/sim-card-charges/simCardChargesTable";
import moment from "moment";
import CustomDataGrid from "parts/customDataGrid";
import React from "react";

const AppVersionHistoryTable: React.FC<AppVersionTableInterface> = ({
  loading,
  data,
}) => {
  const { palette } = useTheme();

  const columns = [
    {
      field: "currentVersion",
      headerName: "Version Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "releaseDate",
      headerName: "Release Date",
      flex: 1,
      minWidth: 150,
      renderCell: (v: string) => moment(v).format("Do MMM, YYYY"),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 150,
    },
  ];
  return (
    <>
      <Box mt={2}>
        <CustomDataGrid
          loading={loading}
          columns={columns}
          rows={data}
          paginationMode="client"
        />
      </Box>
    </>
  );
};

export default AppVersionHistoryTable;
