import CurrentVersionLayout from "component/apps/current-version";
import MainContainer from "parts/mainContainer";

const CurrentVersion = () => {
    return (
      <MainContainer>
        <CurrentVersionLayout />
      </MainContainer>
    );
  };
  
  export default CurrentVersion;