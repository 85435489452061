import KycLayout from "component/kyc";
import MainContainer from "parts/mainContainer";
import { useNavigate, useParams } from "react-router-dom";

const Kyc = () => {
  const params = useParams();
  const navigate = useNavigate();

  if (!params?.id) {
    navigate(-1);
    return null;
  }

  return (
    <MainContainer>
      <KycLayout id={params.id} />
    </MainContainer>
  );
};

export default Kyc;
