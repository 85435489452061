import ManageAccountNotificationLayout from "component/manage-account/notification";
import MainContainer from "parts/mainContainer";

const ManageAccountNotification = () => {
  return (
    <MainContainer>
      <ManageAccountNotificationLayout />
    </MainContainer>
  );
};

export default ManageAccountNotification;
