import {
  CreateRegionalDistributorProps,
  UpdateRegionalDistributorProps,
  GetRegionalDistributorProps,
} from "_interfaces/functions/http-requests/distributor-channels/regional-distributors";
import {
  CREATE_REGIONAL_DISTRIBUTOR,
  GET_REGIONAL_DISTRIBUTOR,
  GET_REGIONAL_DISTRIBUTOR_BY_ID,
  UPDATE_REGIONAL_DISTRIBUTOR,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const CreateRegionalDistributor = ({
  DATA,
}: CreateRegionalDistributorProps) => {
  return HTTP({
    Method: "POST",
    Url: CREATE_REGIONAL_DISTRIBUTOR,
    Data: DATA,
  });
};

export const UpdateRegionalDistributor = ({
  DATA,
  ID,
}: UpdateRegionalDistributorProps) => {
  return HTTP({
    Method: "PUT",
    Url: UPDATE_REGIONAL_DISTRIBUTOR(ID),
    Data: DATA,
  });
};

export const GetRegionalDistributor = ({
  DATA,
}: GetRegionalDistributorProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_REGIONAL_DISTRIBUTOR,
    Data: DATA,
  });
};

export const GetRegionalDistributorById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_REGIONAL_DISTRIBUTOR_BY_ID(ID),
  });
};
