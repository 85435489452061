export interface UploadCartonModelProps {
  cartonNo?: string;
  batchNo?: string;
  firstICCID?: string;
  lastICCID?: string;
  SIM_type?: string;
  firstIMSI?: string;
  lastIMSI?: string;
  serviceType?: string;
}

const defaultUploadCartonModelProps: UploadCartonModelProps = {
  cartonNo: "",
  batchNo: "",
  firstICCID: "",
  lastICCID: "",
  SIM_type: "",
  firstIMSI: "",
  lastIMSI: "",
  serviceType: "",
};

export class UploadCartonModel {
  cartonNo?: string;
  batchNo?: string;
  firstICCID?: string;
  lastICCID?: string;
  SIM_type?: string;
  firstIMSI?: string;
  lastIMSI?: string;
  serviceType?: string;

  constructor(data: UploadCartonModelProps = defaultUploadCartonModelProps) {
    this.cartonNo = data.cartonNo || "";
    this.batchNo = data.batchNo || "";
    this.firstICCID = data.firstICCID || "";
    this.lastICCID = data.lastICCID || "";
    this.SIM_type = data.SIM_type || "";
    this.firstIMSI = data.firstIMSI || "";
    this.lastIMSI = data.lastIMSI || "";
    this.serviceType = data.serviceType || "";
  }
}
