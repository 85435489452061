import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { camelCaseToFormattedString } from "functions/helper";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import { IsActiveCell } from "parts/table/customCell";
import DetailsBox from "parts/table/detailsBox";
import React from "react";
import { Link } from "react-router-dom";
import CustomerManagementDetailsInterface from "_interfaces/customer-management/details";

interface Props {
  data: CustomerManagementDetailsInterface["customerInfo"] | null;
  fullWidth?: boolean;
  noPadding?: boolean;
  noBorder?: boolean;
}

const CustomerInformationTable: React.FC<Props> = ({
  data,
  fullWidth,
  noPadding,
  noBorder,
}) => {
  const { palette } = useTheme();

  const rows: {
    field: keyof NonNullable<
      CustomerManagementDetailsInterface["customerInfo"]
    >;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
    { field: "nameAsDocument", headerName: "Name as Document" },
    {
      field: "MSISDN",
      headerName: "MSISDN",
      renderCell: (v: string) => (
        <Link
          to={`${RouteConstant.MSISDN_MANAGEMENT}/${data?.MSISDN_id}`}
          target="_blank"
          style={{ color: palette.primary.main }}
        >
          {v}
        </Link>
      ),
    },
    { field: "ICCID", headerName: "ICCID" },
    {
      field: "SIM_type",
      headerName: "SIM Type",
      renderCell: (v: string) => camelCaseToFormattedString(v),
    },
    { field: "serviceType", headerName: "Service Type" },
    { field: "numberCategoryType", headerName: "Number Type" },
    { field: "identityType", headerName: "Identity Type" },
    { field: "customerType", headerName: "Customer Type" },
    { field: "customerID", headerName: "Customer ID" },
    {
      field: "emailAddress",
      headerName: "Email Address",
      renderCell: (v: string) => (
        <Box sx={{ textTransform: "lowercase" }}>{v}</Box>
      ),
    },
    { field: "alterNativeNumber", headerName: "AlterNative Number" },
    { field: "gender", headerName: "Gender" },
    {
      field: "dateOfBirth",
      headerName: "Date of Birth",
      renderCell: (v: string) => moment(v).format("Do MMM, YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (v) => <IsActiveCell status={v} />,
    },
  ];

  return (
    <>
      {data?.id ? (
        <DetailsBox
          data={data}
          rows={rows}
          fullWidth={fullWidth}
          noPadding={noPadding}
          noBorder={noBorder}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomerInformationTable;
