import { ServiceUserActivationProps, UserActivationProps } from "_interfaces/functions/http-requests/user";
import { BLOCK_UNBLOCK_SERVICE_USER, SERVICE_USER_GET_BY_ID, USER_ACTIVATION, USER_GET_BY_ID } from "config/endpoint";
import { HTTP } from "functions/http";

export const ChangeUserActivationStatus = ({ DATA }: UserActivationProps) => {
    return HTTP({
        Method: "POST",
        Url: USER_ACTIVATION,
        Data: DATA,
    });
};

export const GetByUserId = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: USER_GET_BY_ID(ID),
    });
};
export const GetByServiceUserId = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: SERVICE_USER_GET_BY_ID(ID),
    });
};

export const BlockUnblockServiceUser = ({ DATA }: ServiceUserActivationProps) => {
    return HTTP({
        Method: "POST",
        Url: BLOCK_UNBLOCK_SERVICE_USER,
        Data: DATA,
    });
};