import {
  GetAllMsisdnProps,
  MsisdnStatusUpdateProps,
  MsisdnTypeUpdateProps,
  UploadMsisdnProps,
} from "_interfaces/functions/http-requests/msisdnManagement";
import {
  GET_ALL_MSISDN,
  GET_ALL_MSISDN_COUNT,
  GET_MSISDN_BY_ID,
  GET_MSISDN_BY_MSISDN,
  MSISDN_STATUS_UPDATE,
  MSISDN_TYPE_UPDATE,
  UPLOAD_MSISDN,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const UploadMsisdn = ({ DATA }: UploadMsisdnProps) => {
  return HTTP({
    Method: "POST",
    Url: UPLOAD_MSISDN,
    Data: DATA,
  });
};

export const GetAllMsisdn = ({ DATA }: GetAllMsisdnProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_ALL_MSISDN,
    Data: DATA,
  });
};

export const GetMsisdnById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_MSISDN_BY_ID(ID),
  });
};

export const GetMsisdnByMsisdn = (MSISDN: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_MSISDN_BY_MSISDN(MSISDN),
  });
};

export const GetAllMsisdnCount = () => {
  return HTTP({
    Method: "GET",
    Url: GET_ALL_MSISDN_COUNT,
  });
};

export const MsisdnTypeUpdate = ({ DATA, ID }: MsisdnTypeUpdateProps) => {
  return HTTP({
    Method: "PUT",
    Url: MSISDN_TYPE_UPDATE(ID),
    Data: DATA,
  });
};
export const MsisdnStatusUpdate = ({ DATA, ID }: MsisdnStatusUpdateProps) => {
  return HTTP({
    Method: "PUT",
    Url: MSISDN_STATUS_UPDATE(ID),
    Data: DATA,
  });
};
