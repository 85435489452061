import React from "react";
import { IconsInterface } from "_interfaces/_common/icons";

const UserSearchIcon: React.FC<IconsInterface> = ({ width, height, color }) => {
  return (
    <svg
      width={width || "1104"}
      height={height || "12"}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99994 7V11H1.99994C1.99994 8.79085 3.7908 7 5.99994 7ZM5.99994 6.5C4.34244 6.5 2.99994 5.1575 2.99994 3.5C2.99994 1.8425 4.34244 0.5 5.99994 0.5C7.65744 0.5 8.99994 1.8425 8.99994 3.5C8.99994 5.1575 7.65744 6.5 5.99994 6.5ZM10.723 10.016L11.4748 10.7677L10.7677 11.4748L10.0159 10.7231C9.71819 10.899 9.37084 11 8.99994 11C7.89539 11 6.99994 10.1046 6.99994 9C6.99994 7.89545 7.89539 7 8.99994 7C10.1045 7 10.9999 7.89545 10.9999 9C10.9999 9.3709 10.899 9.71825 10.723 10.016ZM8.99994 10C9.55224 10 9.99994 9.5523 9.99994 9C9.99994 8.4477 9.55224 8 8.99994 8C8.44764 8 7.99994 8.4477 7.99994 9C7.99994 9.5523 8.44764 10 8.99994 10Z"
        fill={color}
      />
    </svg>
  );
};

export default UserSearchIcon;
