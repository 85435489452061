interface ForgotPasswordErrorModelInterface {
  phone?: string;
}
export class ForgotPasswordErrorModel {
  phone?: string;

  constructor(data: ForgotPasswordErrorModelInterface = {}) {
    this.phone = data.phone || "";
  }
}
