import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import { RiseTicketModel } from "_models/data/rise-ticket/data.rise-ticket.model";
import { RiseTicketErrorModel } from "_models/error/rise-ticket/error.rise-ticket.model";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import Text from "component/_common/forms/text";
import SectionTitle from "parts/sectionTitle";
import React, { Fragment, useState } from "react";

interface InputListProps extends FormInputProps {
  name: "reason" | "message";
}

const inputList: InputListProps[] = [
  {
    type: "autoComplete",
    name: "reason",
    label: "Reason",
    placeholder: "Choose Reason",
    options: [{ id: "other", value: "other", title: "Other", isActive: true }],
  },
  {
    type: "textArea",
    name: "message",
    label: "Additional Message",
    placeholder: "Additional Message",
    minRows: 5,
  },
];

const RiseTicketLayout = () => {
  const [state, setState] = useState<RiseTicketModel>(new RiseTicketModel());
  const [errors, setErrors] = useState<RiseTicketErrorModel>(
    new RiseTicketErrorModel()
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev: any) => ({ ...prev, [name]: "" }));
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
  };

  return (
    <>
      <Container maxWidth="xs">
        <Box pt={5} className="tw-text-center">
          <SectionTitle title="Ticket" />
        </Box>

        <Box pt={5}>
          <Box component="form" name="rise_ticket_form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {inputList.map(
                (
                  {
                    type,
                    name,
                    label,
                    placeholder,
                    autoComplete,
                    disabled,
                    options,
                    multiple,
                    minRows,
                  },
                  index
                ) => (
                  <Fragment key={index}>
                    <>
                      {type === "textArea" ? (
                        <Grid item xs={12}>
                          <Text
                            type={type}
                            name={name}
                            label={label}
                            placeholder={placeholder}
                            autoComplete={autoComplete || "off"}
                            value={state[name as keyof RiseTicketModel]}
                            errorText={
                              errors[name as keyof RiseTicketErrorModel]
                            }
                            onChange={handleChange}
                            onFocus={handleFocus}
                            disabled={disabled}
                            minRows={minRows}
                          />
                        </Grid>
                      ) : type === "autoComplete" ? (
                        <Grid item xs={12}>
                          <InputAutoComplete
                            name={name}
                            options={options || []}
                            label={label}
                            placeholder={placeholder}
                            onChange={(e, v, m) =>
                              handleAutoComplete(e, v, name, m)
                            }
                            value={
                              options &&
                              options?.length &&
                              state?.[name as keyof RiseTicketModel]
                            }
                            errorText={
                              errors[name as keyof RiseTicketErrorModel]
                            }
                            onFocus={handleFocus}
                            multiple={multiple}
                          />
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </>
                  </Fragment>
                )
              )}

              <Grid item xs={12} mt={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disableElevation
                  className="tw-capitalize tw-py-[12px]"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default RiseTicketLayout;
