import Grid from "@mui/material/Grid";
import StatisticsCard from "component/_common/cards/statisticsCard";

interface Props {
  data?: any;
}

const TopStatistics: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <StatisticsCard
            statisticsValue={data?.total || 0}
            title="Total SIM"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <StatisticsCard statisticsValue={data?.idle || 0} title="In Stock" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <StatisticsCard
            statisticsValue={data?.inprogress || 0}
            title="In-progress"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <StatisticsCard
            statisticsValue={data?.active || 0}
            title="Active SIMs"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <StatisticsCard
            statisticsValue={data?.notActive || 0}
            title="In-active SIMs"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <StatisticsCard
            statisticsValue={data?.blocked || 0}
            title="Blocked SIMs"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TopStatistics;
