import Container from "@mui/material/Container";
import React from "react";

interface Props {
  children: React.ReactNode;
  disableVerticalPadding?: boolean;
}

const MainContainer: React.FC<Props> = ({
  children,
  disableVerticalPadding,
}) => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        paddingTop: disableVerticalPadding ? 0 : 2,
        paddingBottom: disableVerticalPadding ? 0 : 2,
      }}
    >
      {children}
    </Container>
  );
};

export default MainContainer;
