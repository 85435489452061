import { Card, Typography, Grid, Box } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid";
import CustomerManagementTableInterface from "_interfaces/customer-management/customerManagementTable";
import { GetAllCustomersByHandlerProps } from "_interfaces/functions/http-requests/handlers";
import { CustomerManagementFilterModel } from "_models/data/customer-management/data.customer-management-filter.model";
import AgentAnalytics from "component/agents/details/agentAnalytics";
import CustomerManagementFilter from "component/customer-management/customerManagementFilter";
import CustomerManagementTable from "component/customer-management/customerManagementTable";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { CountByHandler, GetCustomersByHandler, GetHandlersById } from "functions/http-requests/handlers";
import moment from "moment";
import FilterButton from "parts/filterButton";
import SectionSearch from "parts/sectionSearch";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import HandlerDetailsTable from "./handlerDetailsTable";
import { HandlerDetailsInterface } from "_interfaces/handler";

interface Props {
    ID: string
}

const HandlerDeailsLayout: React.FC<Props> = ({ ID }) => {
    const isInitialRender = useRef(true);
    const Dispatch = useDispatch();
    const [analyticsData, setAnalyticsData] = useState([]);
    const [handlerDetails, setHandlerDetails] = useState<HandlerDetailsInterface | null>(null);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [customerPaginationModel, setCustomerPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [data, setData] = useState<CustomerManagementTableInterface["data"]>(
        []
    );
    const [totalCustomercount, setTotalCustomerCount] = useState<number>(0);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [customerFilters, setCustomerFilters] = useState<CustomerManagementFilterModel>(
        new CustomerManagementFilterModel()
    );
    const [refresh, setRefresh] = useState<boolean>(false);
    const [searchKey, setSearchKey] = useState<string>("");
    const [userId, setUserId] = useState<string | null>(null);
    const handleRefresh = () => setRefresh(!refresh);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKey(value);
    };

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    };

    const handleClearSearch = () => setSearchKey("");

    const handleSubmitSearch = () => {
        isInitialRender.current = false;
        setCustomerPaginationModel((prev) => ({ ...prev, page: 0 }));
        handleRefresh();
    };

    const fetchAgentAnalytics = () => {
        Dispatch(toggleLoading(true));
        CountByHandler(ID)
            .then(res => setAnalyticsData(res?.data?.data))
            .catch(err => setAnalyticsData([]))
            .finally(() => Dispatch(toggleLoading(false)))
    }

    const fetchHandlerDetails = () => {
        Dispatch(toggleLoading(true));
        GetHandlersById(ID)
            .then((res) => {
                const data = res?.data;
                if (data?.statusCode === AppStatusCode.api_success) {
                    let DATA: any = data?.data;
                    console.log("idddddddddd",DATA?.userDetails?._id)
                    setUserId(DATA?.userDetails?._id);
                    setHandlerDetails({
                        id: DATA?._id || undefined,
                        email: DATA?.userDetails?.email,
                        name: DATA?.userDetails?.name,
                        phone: DATA?.userDetails?.phone,
                        isActive: DATA?.isActive,
                        isApprove: DATA?.isApproved,
                        msisdn: DATA?.agentDetails?.agentMSISDN,
                        date: DATA?.createdAt,
                        agentName: DATA?.agentDetails.firstName + " " + DATA?.agentDetails.middleName + " " + DATA?.agentDetails.lastName,
                        agentId: DATA?.agentDetails?._id,
                        role: DATA?.userDetails?.role
                    })
                }
            })
            .catch(() => {
                setHandlerDetails(null)
            })
            .finally(() => Dispatch(toggleLoading(false)));
    }

    useEffect(() => {
        let fetchList: (() => void) | null = () => {
            setDataLoading(true);
            let PAYLOAD_DATA: GetAllCustomersByHandlerProps["DATA"] = {
                searchKey: searchKey,
                handlerId: ID,
                kycStatus: customerFilters?.kycStatus || undefined,
                serviceStatus: customerFilters?.serviceStatus || undefined,
                customerType: customerFilters?.customerType || undefined,
                identityType: customerFilters?.identityType || undefined,
                fromDate: customerFilters?.fromDate
                    ? moment(customerFilters.fromDate).format("YYYY/MM/DD")
                    : undefined,
                toDate: customerFilters?.toDate
                    ? moment(customerFilters.toDate).format("YYYY/MM/DD")
                    : undefined,
                pageNumber: customerPaginationModel.page + 1,
                pageSize: customerPaginationModel.pageSize,
            };

            let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

            GetCustomersByHandler({
                DATA: FILTERED_PAYLOAD_DATA,
            })
                .then((res) => {
                    const data = res?.data;

                    if (data?.statusCode === AppStatusCode.api_success) {
                        let count = res?.data?.meta?.count;
                        if (count && count > 1) {
                            if (count > 1) setTotalCustomerCount(count);
                        } else setTotalCustomerCount(0);

                        let DATA: any = res?.data?.data;
                        DATA = DATA?.map((item: any) => ({
                            id: item?._id,
                            MSISDN: item?.MSISDN || "",
                            nameAsDocument: item?.nameAsDocument || "",
                            identityType: item?.identityType || "",
                            kyc_Details: item?.kyc_Details,
                            MSISDN_details: item?.MSISDN_details,
                            createdAt: item?.createdAt || "",
                        }));
                        setData(DATA);
                    } else {
                        setData([]);
                        setTotalCustomerCount(0);
                    }
                })
                .catch(() => {
                    setData([]);
                    setTotalCustomerCount(0);
                })
                .finally(() => {
                    setDataLoading(false);
                });
        };
        fetchList();
        return () => {
            fetchList = null;
        };
    }, [customerPaginationModel, refresh, customerFilters, searchKey]);

    useEffect(() => {
        fetchAgentAnalytics();
        fetchHandlerDetails();
    }, [])


    return (
        <>
            <AgentAnalytics Data={analyticsData} />

            {handlerDetails && <HandlerDetailsTable DATA={handlerDetails} UserId={userId} />}

            {/* //CUSTOMERS TABLE// */}
            <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
                <Typography color="text.primary" className="tw-text-[18px]" sx={{ fontWeight: "bold" }}>
                    {"Customers List"}
                </Typography>
                <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
                    <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
                        <Box sx={{ flex: "1 1 auto" }}>
                            <SectionSearch
                                name="search_sim"
                                value={searchKey}
                                onChange={handleSearch}
                                onClear={handleClearSearch}
                                onSubmit={handleSubmitSearch}
                            />
                        </Box>
                        <FilterButton onClick={() => setOpenFilter(true)} />
                    </Grid>
                </Grid>
                <CustomerManagementTable data={data}
                    loading={dataLoading}
                    count={totalCustomercount}
                    paginationModel={customerPaginationModel}
                    setPaginationModel={setCustomerPaginationModel} />
                <CustomerManagementFilter
                    open={openFilter}
                    onClose={toggleFilter}
                    filters={customerFilters}
                    setFilters={setCustomerFilters}
                />
            </Card>
        </>
    )
}

export default HandlerDeailsLayout;