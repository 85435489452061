export interface RegionalDistributorsFilterModelProps {
  status?: string;
  sortBy?: "name" | "email" | "phone" | "date" | "";
  sortType?: "asc" | "desc" | "";
}

const defaultRegionalDistributorsFilterModelProps: RegionalDistributorsFilterModelProps =
  {
    status: "",
    sortBy: "",
    sortType: "",
  };

export class RegionalDistributorsFilterModel {
  status?: string;
  sortBy?: "name" | "email" | "phone" | "date" | "";
  sortType?: "asc" | "desc" | "";

  constructor(
    data: RegionalDistributorsFilterModelProps = defaultRegionalDistributorsFilterModelProps
  ) {
    this.status = data.status || "";
    this.sortBy = data.sortBy || "";
    this.sortType = data.sortType || "";
  }
}
