import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BreadCrumbsLinksProps } from "_interfaces/_common/breadcrumbs";
import { AllRoutes } from "navigation/routes";
import CustomBreadCrumbs from "parts/customBreadcrumbs";
import MainContainer from "parts/mainContainer";
import Notifications from "parts/notifications";
import SectionTitle from "parts/sectionTitle";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import NavbarLogo from "../navbarLogo";
import NavbarMenuItems from "../navbarMenuItems";
import SignOut from "../signOut";

const TopNavbar = () => {
  const { breakpoints } = useTheme();
  const mdDevice = useMediaQuery(breakpoints.up("md"));
  const location = useLocation();
  const params = useParams();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openNotifications, setOpenNotifications] = useState<boolean>(false);

  const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);

  const [pageTitle, setPageTitle] = useState<string>("");
  const [breadCrumbsLinks, setBreadCrumbsLinks] = useState<
    BreadCrumbsLinksProps[]
  >([]);

  const handleClickNotifications = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenNotifications(true);
  };

  const handleCloseNotifications = () => {
    setAnchorEl(null);
    setOpenNotifications(false);
  };

  const toggleMobileMenu = () => {
    setOpenMobileMenu(!openMobileMenu);
  };

  const id = openNotifications ? "notifications-popover" : undefined;

  useEffect(() => {
    if (location.pathname) {
      const path = AllRoutes.find((route) => {
        if (!route.exact && params?.id) {
          return route.path.includes(
            location.pathname.toString().replace(params?.id, "")
          );
        } else {
          return location.pathname === route.path;
        }
      });
      if (path?.pageTitle) setPageTitle(path?.pageTitle);
      else setPageTitle("");

      if (path?.breadCrumbsLinks) setBreadCrumbsLinks(path?.breadCrumbsLinks);
      else setBreadCrumbsLinks([]);
    }
  }, [location.pathname, params]);

  const TopPageTitleAndBreadCrumbs = () => {
    return (
      <Box>
        {pageTitle ? <SectionTitle title={pageTitle} fontSize={20} /> : <></>}
        {breadCrumbsLinks?.length ? (
          <CustomBreadCrumbs links={breadCrumbsLinks} />
        ) : (
          <></>
        )}
      </Box>
    );
  };

  return (
    <>
      <Box
        position="static"
        // top={8}
        zIndex={99}
        className="tw-bg-white tw-shadow tw-rounded-2xl "
        // className="tw-drop-shadow-xl tw-rounded-2xl"
        // sx={{ borderBottom: `1px solid ${alpha(palette.secondary.main, 0.3)}` }}
      >
        <MainContainer disableVerticalPadding>
          <Box py={1} className="tw-flex tw-justify-between tw-items-center">
            {!mdDevice ? (
              <Box>
                <NavbarLogo />
              </Box>
            ) : (
              <></>
            )}

            {mdDevice ? <TopPageTitleAndBreadCrumbs /> : <></>}

            <Box
              className="tw-flex tw-justify-between tw-items-center tw-flex-auto"
              position="relative"
            >
              {/* {mdDevice ? (
                <Box className="tw-px-[40px]">
                  <NavbarMenuItems />
                </Box>
              ) : (
                <></>
              )} */}
              <Box
                className="tw-flex tw-justify-end tw-items-center tw-flex-auto"
                position="relative"
              >
                <Box>
                  <CustomIconButton
                    Icon={<NotificationsOutlinedIcon />}
                    onClick={handleClickNotifications}
                  />

                  <Notifications
                    id={id}
                    anchorEl={anchorEl}
                    open={openNotifications}
                    onClose={handleCloseNotifications}
                  />
                </Box>

                <Box className="tw-ml-[10px]">
                  <CustomIconButton
                    Icon={<AccountCircleOutlinedIcon />}
                    // onClick={() => navigate(RouteConstant.PROFILE)}
                  />
                </Box>

                {mdDevice ? (
                  <>
                    <Box className="tw-ml-[10px]">
                      <SignOut />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box className="tw-ml-[10px]">
                      <CustomIconButton
                        onClick={toggleMobileMenu}
                        Icon={<MenuOutlinedIcon />}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </MainContainer>
      </Box>

      {!mdDevice ? (
        <>
          {pageTitle || breadCrumbsLinks?.length ? (
            <Box pt={2} px={2}>
              <Box p={2} className="tw-bg-white tw-rounded-lg">
                <TopPageTitleAndBreadCrumbs />
              </Box>
            </Box>
          ) : (
            <></>
          )}
          <Drawer
            anchor="left"
            open={openMobileMenu}
            onClose={toggleMobileMenu}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{ ".MuiPaper-root": { minWidth: 250 } }}
          >
            <NavbarMenuItems />
          </Drawer>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default TopNavbar;

interface CustomIconButtonProps {
  Icon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
}
const CustomIconButton: React.FC<CustomIconButtonProps> = ({
  Icon,
  onClick,
  children,
}) => {
  const { palette } = useTheme();
  return (
    <IconButton
      onClick={onClick}
      sx={{
        border: `1px solid ${alpha(palette.secondary.main, 0.2)}`,
        backgroundColor: alpha(palette.secondary.main, 0.05),
        color: palette.common.black,
        "&:hover,:focus": {
          backgroundColor: alpha(palette.secondary.main, 0.05),
        },
      }}
      className="tw-w-[40px] tw-h-[40px] tw-rounded-full tw-p-0 tw-overflow-hidden"
    >
      {Icon ? Icon : <></>}
      {children}
    </IconButton>
  );
};
