import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  AutoCompleteOptionsProps,
  FormInputProps,
} from "_interfaces/_common/forms";
import { CreateReportModel } from "_models/data/manage-account/data.create-report.model";
import { CreateReportErrorModel } from "_models/error/manage-account/error.create-report.model";
import DatePicker from "component/_common/forms/date";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import React, { Fragment, useMemo, useState } from "react";

interface InputListProps extends FormInputProps {
  grid?: number;
  options?: AutoCompleteOptionsProps[];
  name: "reportType" | "startDate" | "endDate";
}

const CreateReportForm = () => {
  const [state, setState] = useState<CreateReportModel>(
    new CreateReportModel()
  );
  const [errors, setErrors] = useState<CreateReportErrorModel>(
    new CreateReportErrorModel()
  );

  const handleAutoComplete = (
    e: React.SyntheticEvent,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
  };

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "reportType",
        label: "Report Type",
        placeholder: "Report Type",
        multiple: false,
        options: [{ id: "1", value: "1", title: "Manager", isActive: true }],
        grid: 12,
      },
      {
        type: "date",
        name: "startDate",
        label: "Start Date",
        placeholder: "Start Date",
        grid: 6,
      },
      {
        type: "date",
        name: "endDate",
        label: "End Date",
        placeholder: "End Date",
        grid: 6,
      },
    ],
    []
  );

  return (
    <>
      <Box
        component="form"
        name="manage_account_report_form"
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                options,
                helperText,
                grid,
                autoComplete,
                disabled,
                multiple,
              },
              index
            ) => (
              <Fragment key={index}>
                <>
                  {type === "showTopLabel" ? (
                    <>
                      <Grid item xs={12}>
                        <Typography className="tw-uppercase">
                          {label}
                        </Typography>
                      </Grid>
                    </>
                  ) : type === "date" ? (
                    <Grid item xs={12} md={6} lg={grid || 6}>
                      <DatePicker
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        value={state[name as keyof CreateReportModel]}
                        errorText={errors[name as keyof CreateReportErrorModel]}
                        onChange={(value) => {
                          setState((prev) => ({
                            ...prev,
                            [name]: value,
                          }));
                          setErrors((prev) => ({
                            ...prev,
                            [name]: "",
                          }));
                        }}
                      />
                    </Grid>
                  ) : type === "autoComplete" ? (
                    <Grid item xs={12} lg={grid || 6}>
                      <InputAutoComplete
                        name={name}
                        options={options || []}
                        label={label}
                        placeholder={placeholder}
                        onChange={(e, v, m) =>
                          handleAutoComplete(e, v, name, m)
                        }
                        value={
                          options &&
                          options.length &&
                          state?.[name as keyof CreateReportModel]
                        }
                        errorText={errors[name as keyof CreateReportErrorModel]}
                        onFocus={handleFocus}
                        multiple={multiple}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              </Fragment>
            )
          )}

          <Grid item xs={12} className="tw-pt-[40px]">
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{
                minWidth: 150,
              }}
            >
              Get Report
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateReportForm;
