import { AssignedLocationProps } from "_interfaces/functions/http-requests/distributor-channels/regional-distributors";

interface RegionalDistributorsModelProps {
  id?: string;
  name?: string;
  email?: string;
  phone?: string;
  region?: string;
  districts?: string[];
  assignedLocation?: AssignedLocationProps[];
  password?: string;
  confirmPassword?: string;
}

const defaultRegionalDistributorsModelProps: RegionalDistributorsModelProps = {
  id: "",
  name: "",
  email: "",
  phone: "",
  region: "",
  districts: [],
  assignedLocation: [],
  password: "",
  confirmPassword: "",
};

export class RegionalDistributorsModel {
  id?: string;
  name?: string;
  email?: string;
  phone?: string;
  region?: string;
  districts?: string[];
  assignedLocation?: AssignedLocationProps[];
  password?: string;
  confirmPassword?: string;

  constructor(
    data: RegionalDistributorsModelProps = defaultRegionalDistributorsModelProps
  ) {
    this.id = data.id || "";
    this.name = data.name || "";
    this.email = data.email || "";
    this.phone = data.phone || "";
    this.region = data.region || "";
    this.districts = data.districts || [];
    this.assignedLocation = data.assignedLocation || [];
    this.password = data.password || "";
    this.confirmPassword = data.confirmPassword || "";
  }
}
