import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import BlockIcon from "component/_common/icons/block";
import SectionTitle from "parts/sectionTitle";
import React from "react";

interface Props {
  onClose: () => void;
}
const BlockSimCard: React.FC<Props> = ({ onClose }) => {
  const { palette } = useTheme();
  return (
    <>
      <Box
        p={{ md: 5 }}
        className="tw-flex tw-flex-col tw-items-center  tw-max-w-[450px] tw-mx-auto tw-text-center"
      >
        <Box py={3}>
          <BlockIcon color={palette.error.main} width={48} height={48} />
        </Box>
        <SectionTitle
          title="Are your sure. You want to Block SIM ?"
          fontSize={20}
          fontWeight={700}
        />
        <Typography fontSize={12} color="black" pt={0.5}>
          you can unblock sim anytime by contacting the admin
        </Typography>

        <Box pt={3} className="tw-flex">
          <Button
            variant="contained"
            color="error"
            className="tw-mx-[4px] tw-px-[30px]"
          >
            Block SIM
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            className="tw-mx-[4px] tw-px-[30px]"
            onClick={onClose}
          >
            Discard
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default BlockSimCard;
