import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { Fragment } from "react";

interface Rows {
  field: string;
  headerName: string;
  renderCell?: (v: any) => void;
}

interface Props {
  data: any;
  fullWidth?: boolean;
  noPadding?: boolean;
  noBorder?: boolean;
  rows: Rows[];
}

const DetailsBox: React.FC<Props> = ({
  data,
  fullWidth,
  noPadding,
  noBorder,
  rows,
}) => {
  return (
    <>
      <Box
        fontSize={14}
        sx={{
          border: noBorder ? 0 : "1px solid",
          borderColor: "inherit",
        }}
      >
        {/* Reverse the padding as items don't have padding */}
        <Grid container columnSpacing={noPadding ? 2 : 0}>
          {rows
            ?.filter(
              (row) =>
                row?.field &&
                data[row.field] !== null &&
                data[row.field] !== undefined
            )
            ?.map((row, i, arr) => (
              <Fragment key={i}>
                <Grid item xs={12} md={fullWidth ? 12 : 6}>
                  <Box
                    height="100%"
                    display="flex"
                    alignItems="center"
                    px={noPadding ? 0 : 2}
                    py={1}
                    sx={{
                      borderBottom: {
                        xs: noBorder
                          ? 0
                          : i + 1 !== arr.length
                          ? "1px solid"
                          : 0,
                        md: noBorder
                          ? 0
                          : fullWidth
                          ? i + 1 !== arr.length
                            ? "1px solid"
                            : 0
                          : arr?.length % 2 === 0
                          ? i + 1 !== arr.length && i + 1 !== arr.length - 1
                            ? "1px solid"
                            : 0
                          : i + 1 !== arr.length
                          ? "1px solid"
                          : 0,
                      },
                      borderRight: {
                        xs: 0,
                        md:
                          noBorder || fullWidth
                            ? 0
                            : (i + 1) % 2 !== 0
                            ? "1px solid"
                            : 0,
                      },
                      borderColor: {
                        xs: "inherit",
                        md: "inherit",
                      },
                      height: "100%",
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={5}>
                        <Box className="tw-flex tw-justify-between tw-items-center">
                          <Typography
                            fontSize="inherit"
                            sx={{
                              fontWeight: 500,
                            }}
                          >
                            {row.headerName}
                          </Typography>
                          <Box
                            component="span"
                            fontSize="inherit"
                            sx={{
                              fontWeight: 500,
                            }}
                          >
                            :
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={7}>
                        <Box
                          sx={{
                            // textTransform: "capitalize",
                            textWrap: "wrap",
                            wordWrap: "break-word",
                          }}
                        >
                          <>
                            {row?.renderCell
                              ? row.renderCell(data[row.field])
                              : data[row.field]}
                          </>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Fragment>
            ))}
        </Grid>
      </Box>
    </>
  );
};

export default DetailsBox;
