import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SectionSearch from "parts/sectionSearch";
import SectionTitle from "parts/sectionTitle";
import SearchResultCard from "./searchResultCard";

const SearchInformationLayout = () => {
  return (
    <>
      <Container maxWidth="xs">
        <Box pt={5} className="tw-text-center">
          <SectionTitle title="Global Search" />
        </Box>

        <Box pt={5}>
          <SectionSearch />
          <Box pt={3}>
            <Typography fontSize={14}>Recent Search</Typography>

            <Box pt={1} maxWidth="90%" className="tw-mx-auto">
              <SearchResultCard />
              <SearchResultCard />
              <SearchResultCard />
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default SearchInformationLayout;
