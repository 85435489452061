import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CustomDateRangePicker from "component/_common/forms/customDateRangePicker";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import { generateAutocompleteOptions } from "functions/helper";
import moment, { Moment } from "moment";
import SideDrawer from "parts/sideDrawer";
import React, { Fragment, useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { CustomerManagementFilterModel } from "_models/data/customer-management/data.customer-management-filter.model";

interface InputListProps extends FormInputProps {
  name:
  | "kycStatus"
  | "serviceStatus"
  | "customerType"
  | "identityType"
  | "dateRange";
}
interface Props extends SideDrawerProps {
  filters: CustomerManagementFilterModel;
  setFilters: React.Dispatch<
    React.SetStateAction<CustomerManagementFilterModel>
  >;
}

const AgentDetailsFilter: React.FC<Props> = ({
  open,
  onClose,
  filters,
  setFilters,
}) => {
  const handleAutoComplete = (
    e: React.SyntheticEvent,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setFilters((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
          (e: { value: string | number; id: string | number }) =>
            e?.value || e?.id
        )
        : value?.value || value?.id,
    }));
  };

  const inputList: any[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "type",
        label: "Filter by agent type",
        placeholder: "Select agent type",
        options: generateAutocompleteOptions([
          "dealer",
          "super_agent",
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "activeStatus",
        label: "Filter by Active status",
        placeholder: "Select Active status",
        options: generateAutocompleteOptions([
          "inActive",
          "active",
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "approveStatus",
        label: "Filter by Approve status",
        placeholder: "Select Approve status",
        options: generateAutocompleteOptions([
          "approve",
          "notApprove",
        ]),
        multiple: false,
      },
    ],
    []
  );

  return (
    <>
      <SideDrawer open={open} onClose={onClose}>
        <Box id="filter_customer_management">
          <Grid container rowSpacing={3} columnSpacing={2}>
            {inputList.map(
              (
                { type, name, label, placeholder, options, multiple },
                index
              ) => (
                <Fragment key={index}>
                  <>
                    {type === "autoComplete" && options?.length ? (
                      <Grid item xs={12}>
                        <InputAutoComplete
                          name={name}
                          options={options}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options?.length &&
                            filters?.[
                            name as keyof CustomerManagementFilterModel
                            ]
                          }
                          multiple={multiple}
                        />
                      </Grid>
                    ) : type === "dateRange" ? (
                      <Grid item xs={12}>
                        <CustomDateRangePicker
                          label={label}
                          value={[
                            filters?.fromDate
                              ? moment(filters.fromDate).toDate()
                              : null,
                            filters.toDate
                              ? moment(filters.toDate).toDate()
                              : null,
                          ]}
                          onChange={(newValue: [Date | null, Date | null]) => {
                            setFilters(
                              (prev: CustomerManagementFilterModel) => ({
                                ...prev,
                                fromDate: newValue[0]
                                  ? moment(newValue[0]).toDate()
                                  : null,
                                toDate: newValue[1]
                                  ? moment(newValue[1]).toDate()
                                  : null,
                              })
                            );
                          }}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                </Fragment>
              )
            )}
          </Grid>
        </Box>
      </SideDrawer>
    </>
  );
};

export default AgentDetailsFilter;
