interface OtpVerificationErrorModelInterface {
  logId?: string;
  OTP?: string;
  type?: string;
  token?: string;
}
export class OtpVerificationErrorModel {
  logId?: string;
  OTP?: string;
  type?: string;
  token?: string;

  constructor(data: OtpVerificationErrorModelInterface = {}) {
    this.logId = data.logId || "";
    this.OTP = data.OTP || "";
    this.type = data.type || "";
    this.token = data.token || "";
  }
}
