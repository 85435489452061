import CustomerManagementLayout from "component/customer-management";
import MainContainer from "parts/mainContainer";

const CustomerManagement = () => {
  return (
    <MainContainer>
      <CustomerManagementLayout />
    </MainContainer>
  );
};

export default CustomerManagement;
