import { LoginProps } from "_interfaces/functions/http-requests/auth";
import { LoginModel } from "_models/data/auth/data.login.model";
import { OtpVerificationModel } from "_models/data/auth/data.otpVerification.model";
import { AppStatusCode } from "config/appStatusCode";
import role from "config/role";
import { HTTP_ERROR } from "functions/http";
import {
  AuthLogin,
  AuthLoginOtpVerification,
  AuthResendLoginOtp,
} from "functions/http-requests/auth";
import { RouteConstant } from "navigation/constant";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import { setUser } from "state/reducers/user";
import OtpVerificationLayout from "../otpVerification";
import LoginForm from "./loginForm";
import axios from "axios";

const LoginLayout = () => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();

  const [isProgress, setIsProgress] = useState<boolean>(false);

  const [loginSteps, setLoginSteps] = useState<{
    needVerification: "both" | "email" | "sms" | "none";
  }>({
    needVerification: "none",
  });

  const [loginState, setLoginState] = useState<LoginModel>(new LoginModel());
  const [otpState, setOtpState] = useState<OtpVerificationModel>(
    new OtpVerificationModel()
  );

  const handleLoginComplete = (DATA: any) => {
    if (
      DATA.role === role.ADMIN ||
      DATA.role === role.MANAGER ||
      DATA.role === role.STAFF
    ) {
      localStorage.setItem("token", DATA.token);
      localStorage.setItem("signature", DATA.signature);
      localStorage.setItem("userId", DATA._id);
      Dispatch(setUser(DATA));

      navigate(RouteConstant.DASHBOARD);
    } else {
      setLoginSteps((prev) => ({ ...prev, needVerification: "none" }));
      setLoginState((prev) => ({ ...prev, phone: "", password: "" }));
      setOtpState(new OtpVerificationModel());
      Dispatch(
        setAlert({
          type: "error",
          message: `Invalid role`,
        })
      );
    }
  };

  const handleAuthLogin = (DATA: LoginProps["DATA"]) => {
    if (DATA) {
      setIsProgress(true);

      AuthLogin({
        DATA: { ...DATA, signature: localStorage.getItem("signature") || "" },
      })
        .then((res) => {
          if (res?.data?.statusCode === AppStatusCode.api_success) {
            const data = res?.data?.data;
            if (data) {
              if (data?.token && data?.signature) {
                handleLoginComplete(data);
              } else {
                setLoginSteps((prev) => ({
                  ...prev,
                  needVerification: data?.needVerification,
                }));
                setOtpState(() => ({
                  OTP: "",
                  logId: data?.logId,
                  type: data?.otpSentVia,
                  otpSent: true,
                }));
                Dispatch(
                  setAlert({
                    type: res?.data?.level || "success",
                    message: res?.data?.message,
                  })
                );
              }
            }
          } else {
            Dispatch(
              setAlert({
                type: res?.data?.level || "error",
                message: res?.data?.message,
              })
            );
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => setIsProgress(false));
    }
  };

  const handleOtpVerification = () => {
    if (otpState?.logId && otpState?.OTP && otpState?.type) {
      setIsProgress(true);

      AuthLoginOtpVerification({
        DATA: {
          logId: otpState?.logId,
          OTP: otpState?.OTP,
          type: otpState?.type,
        },
      })
        .then((res) => {
          if (res?.data?.statusCode === AppStatusCode.api_success) {
            const data = res?.data?.data;
            if (data) {
              if (data?.token && data?.signature) {
                handleLoginComplete(data);
              } else {
                setLoginSteps((prev) => ({
                  ...prev,
                  needVerification: data?.needVerification,
                }));
                setOtpState(() => ({
                  OTP: "",
                  logId: data?.logId,
                  type: data?.otpSentVia,
                  otpSent: true,
                }));
                Dispatch(
                  setAlert({
                    type: res?.data?.level || "success",
                    message: res?.data?.message,
                  })
                );
              }
            }
          } else {
            Dispatch(
              setAlert({
                type: res?.data?.level || "error",
                message: res?.data?.message,
              })
            );
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => setIsProgress(false));
    }
  };

  const handleResendOtp = () => {
    if (otpState?.logId) {
      Dispatch(toggleLoading(true));
      AuthResendLoginOtp({
        DATA: {
          logId: otpState.logId,
        },
      })
        .then((res) => {
          const data = res?.data?.data;
          if (data) {
            setLoginSteps((prev) => ({
              ...prev,
              needVerification: data?.needVerification,
            }));
            setOtpState(() => ({
              OTP: "",
              logId: data?.logId,
              type: data?.otpSentVia,
              otpSent: true,
            }));
          }
          Dispatch(
            setAlert({
              type: res?.data?.level || "success",
              message: res?.data?.message,
            })
          );
        })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => Dispatch(toggleLoading(false)));
    } else {
      Dispatch(
        setAlert({
          type: "error",
          message: `Mandatory fields missing!`,
        })
      );
    }
  };

  return (
    <>
      {loginSteps?.needVerification === "none" ? (
        <LoginForm
          handleLogin={handleAuthLogin}
          isProgress={isProgress}
          state={loginState}
          setState={setLoginState}
        />
      ) : (
        <>
          <OtpVerificationLayout
            otpState={otpState}
            setOtpState={setOtpState}
            isProgress={isProgress}
            handleOtpVerification={handleOtpVerification}
            handleResendOtp={handleResendOtp}
          />
        </>
      )}
    </>
  );
};

export default LoginLayout;
