import { AlertColor } from "@mui/material/Alert";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type AlertState = {
  showAlert?: boolean;
  type: AlertColor;
  message: string;
};

type SetAlertPayload = {
  type: AlertColor;
  message: string;
};

const initialState: AlertState = {
  showAlert: false,
  type: "error",
  message: "",
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert(
      state,
      { payload }: PayloadAction<SetAlertPayload | null | undefined>
    ) {
      if (payload) {
        state.showAlert = true;
        state.type = payload.type;
        state.message = payload.message;
      } else {
        state.showAlert = initialState.showAlert;
        state.type = initialState.type;
        state.message = initialState.message;
      }
    },
  },
});

export const { setAlert } = alertSlice.actions;

export default alertSlice.reducer;
