import Box from "@mui/material/Box";
import {
  DataGrid,
  GridFeatureMode,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import React from "react";

interface Props {
  loading?: boolean;
  height?: number;
  width?: number;
  columns: any;
  rows: any;
  count?: number;
  paginationMode?: GridFeatureMode;
  paginationModel?: GridPaginationModel;
  setPaginationModel?: React.Dispatch<
    React.SetStateAction<GridPaginationModel>
  >;
  sortModel?: GridSortModel;
  setSortModel?: React.Dispatch<React.SetStateAction<GridSortModel>>;
  hideFooter?: boolean;
}
const CustomDataGrid: React.FC<Props> = ({
  loading,
  width,
  height,
  columns,
  rows,
  count,
  paginationMode,
  paginationModel,
  setPaginationModel,
  sortModel,
  setSortModel,
  hideFooter,
}) => {
  return (
    <>
      <Box
        height={height || 500}
        width={width || "100%"}
        sx={{ display: "grid" }}
      >
        <DataGrid
          hideFooter={hideFooter}
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          disableDensitySelector
          disableRowSelectionOnClick
          loading={loading}
          isRowSelectable={() => false}
          columns={columns}
          rows={rows}
          rowCount={count}
          pagination
          paginationMode={paginationMode || "server"}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationModel={paginationModel}
          onPaginationModelChange={(model) => {
            if (setPaginationModel)
              setPaginationModel((prev) => {
                if (
                  prev.pageSize === model.pageSize &&
                  prev.page !== model.page
                ) {
                  return {
                    ...prev,
                    page: model?.page,
                  };
                }

                if (prev.pageSize !== model.pageSize) {
                  return {
                    page: 0,
                    pageSize: model.pageSize,
                  };
                }

                return prev;
              });
          }}
          sortModel={sortModel}
          onSortModelChange={(model) => {
            if (setSortModel) {
              setSortModel(model);
            }
          }}
          sortingMode={sortModel ? "server" : "client"}
        />
      </Box>
    </>
  );
};

export default CustomDataGrid;
