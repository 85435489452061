import { UploadMsisdnModel } from "_models/data/msisdnManagement/data.upload-msisdn-bulk.model";
import { isValidMsisdn } from "functions/validators";

export const validateUploadMsisdn = (DATA: UploadMsisdnModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.batchNo?.trim()) {
    valid = false;
    errors.push({
      name: "batchNo",
      error: "Batch No is required.",
    });
  }

  if (!DATA.firstMSISDN?.trim()) {
    valid = false;
    errors.push({
      name: "firstMSISDN",
      error: "First MSISDN is required.",
    });
  }

  if (!DATA.lastMSISDN?.trim()) {
    valid = false;
    errors.push({
      name: "lastMSISDN",
      error: "Last MSISDN is required.",
    });
  }

  if (DATA.firstMSISDN?.trim() && !isValidMsisdn(DATA.firstMSISDN)) {
    valid = false;
    errors.push({
      name: "firstMSISDN",
      error: "Invalid MSISDN.",
    });
  }

  if (DATA.lastMSISDN?.trim() && !isValidMsisdn(DATA.lastMSISDN)) {
    valid = false;
    errors.push({
      name: "lastMSISDN",
      error: "Invalid MSISDN.",
    });
  }

  if (!DATA.type?.trim()) {
    valid = false;
    errors.push({
      name: "type",
      error: "Type is required.",
    });
  }

  if (!DATA.isFixed?.trim()) {
    valid = false;
    errors.push({
      name: "isFixed",
      error: "Number type is required.",
    });
  }

  return { valid, errors };
};
