import Visibility from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import MsisdnManagementTableInterface from "_interfaces/msisdn-management/msisdnManagementTable";
import { camelCaseToFormattedString } from "functions/helper";
import { RouteConstant } from "navigation/constant";
import CustomDataGrid from "parts/customDataGrid";
import { IsActiveCell } from "parts/table/customCell";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const MsisdnManagementTable: React.FC<MsisdnManagementTableInterface> = ({
  loading,
  data,
  count,
  paginationModel,
  setPaginationModel,
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "MSISDN",
      headerName: "MSISDN",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => camelCaseToFormattedString(params.value),
      renderCell: (params) => (
        <Box className="tw-capitalize">{params?.formattedValue}</Box>
      ),
    },
    {
      field: "isFixed",
      headerName: "Number Type",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => (params.value ? "fixed" : "normal"),
      renderCell: (params) => (
        <Box className="tw-capitalize">{params?.formattedValue}</Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 80,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <IsActiveCell status={params?.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() => {
                  console.log(params)
                  params?.row?.id &&
                    navigate(
                      `${RouteConstant.MSISDN_MANAGEMENT}/${params?.row?.id}`
                    )
                }}
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="View Details">
                  <Visibility color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];


  return (
    <>
      <CustomDataGrid
        loading={loading}
        columns={columns}
        rows={data || []}
        count={count}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </>
  );
};

export default MsisdnManagementTable;
