type ForgotPasswordProps = {
  phone?: string;
};

export class ForgotPasswordModel {
  phone?: string;

  constructor(data: ForgotPasswordProps = {}) {
    this.phone = data.phone || "";
  }
}
