import { ForgotPasswordModel } from "_models/data/auth/data.forgot-password.model";

export const validateForgotPassword = (DATA: ForgotPasswordModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.phone) {
    valid = false;
    errors.push({
      name: "phone",
      error: "Phone is required.",
    });
  }

  return { valid, errors };
};
