import React from "react";
import { IconsInterface } from "_interfaces/_common/icons";

const MSISDNManagementIcon: React.FC<IconsInterface> = ({
  width,
  height,
  color,
}) => {
  return (
    <svg
      width={width || "12"}
      height={height || "13"}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.499512"
        width="5.33333"
        height="5.33333"
        rx="1.33333"
        fill={color}
        fillOpacity="0.48"
      />
      <rect
        x="6.66669"
        y="0.499512"
        width="5.33333"
        height="5.33333"
        rx="1.33333"
        fill={color}
        fillOpacity="0.48"
      />
      <rect
        y="7.16626"
        width="5.33333"
        height="5.33333"
        rx="1.33333"
        fill={color}
        fillOpacity="0.48"
      />
      <path
        d="M12 11.9663C12 12.2608 11.7612 12.4996 11.4667 12.4996H7.20002C6.90547 12.4996 6.66669 12.2608 6.66669 11.9663V7.69959C6.66669 7.40504 6.90547 7.16626 7.20002 7.16626H9.83854C9.94383 7.16626 10.0468 7.19743 10.1344 7.25583L11.7625 8.34126C11.9109 8.44018 12 8.6067 12 8.78502V11.9663Z"
        fill={color}
      />
    </svg>
  );
};

export default MSISDNManagementIcon;
