import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "@mui/material/Button";
import React from "react";

interface Props {
  onClick: () => void;
}

const FilterButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      disableElevation
      onClick={onClick}
      sx={{
        marginLeft: 1,
        height: "inherit",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
    >
      <FilterListIcon />
    </Button>
  );
};

export default FilterButton;
