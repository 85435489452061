import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ListItemIcon } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DashboardIcon from "component/_common/icons/dashboard";
import InsightsIcon from "component/_common/icons/insights";
import InventoryManagementIcon from "component/_common/icons/inventory-managment";
import MSISDNManagementIcon from "component/_common/icons/msisdn-management";
import RiseTicketIcon from "component/_common/icons/rise-ticket";
import SimManagementIcon from "component/_common/icons/sim-management";
import UserIcon from "component/_common/icons/user";
import UserSearchIcon from "component/_common/icons/user-search";
import role from "config/role";
import { RouteConstant } from "navigation/constant";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { RootState } from "state/store";
import SignOut from "../signOut";
import AppsIcon from '@mui/icons-material/Apps';

interface SingleLevelMenuItemProps {
  title: string;
  path?: string;
  Icon?: React.ElementType;
  enabled?: boolean;
  exact?: boolean;
  isSubMenuItem?: boolean;
}

interface MenuItemProps extends SingleLevelMenuItemProps {
  hasSubMenu?: boolean;
  items?: SingleLevelMenuItemProps[];
}

const NavbarMenuItems = () => {
  const params = useParams();
  const { pathname } = useLocation();
  const { breakpoints, palette } = useTheme();
  const mdDevice = useMediaQuery(breakpoints.up("md"));

  const { userInfo } = useSelector((state: RootState) => state.user);

  const isExact = useCallback(
    (path: string) => {
      if (params?.id) {
        const currentPath = pathname?.split("/")?.slice(0, -1)?.join("/");
        return currentPath === path ? false : true;
      }
      return true;
    },
    [params, pathname]
  );

  const menuList: MenuItemProps[] = [
    {
      title: "Dashboard",
      path: RouteConstant.DASHBOARD,
      enabled: true,
      exact: true,
      Icon: DashboardIcon,
    },
    {
      title: "Customer Management",
      path: RouteConstant.CUSTOMER_MANAGEMENT,
      enabled: true,
      Icon: UserIcon,
      exact: isExact(RouteConstant.CUSTOMER_MANAGEMENT),
    },
    {
      title: "SIM Management",
      path: RouteConstant.SIM_MANAGEMENT,
      enabled: true,
      Icon: SimManagementIcon,
      exact: isExact(RouteConstant.SIM_MANAGEMENT),
    },
    {
      title: "MSISDN Management",
      path: RouteConstant.MSISDN_MANAGEMENT,
      enabled: true,
      exact: false,
      Icon: MSISDNManagementIcon,
    },
    // {
    //   title: "Inventory Management",
    //   path: RouteConstant.INVENTORY_MANAGEMENT,
    //   enabled: true,
    //   exact: true,
    //   Icon: InventoryManagementIcon,
    // },
    {
      title: "Analytics +  Insights",
      path: RouteConstant.ANALYTICS,
      enabled: true,
      exact: false,
      Icon: InsightsIcon,
    },
    {
      title: "User Management",
      path: "",
      enabled: true,
      exact: false,
      Icon: UserIcon,
      hasSubMenu: true,
      items: [
        {
          title: "Inventory Users",
          path: RouteConstant.INVENTORY_USERS,
          enabled: userInfo.role === role.ADMIN,
          exact: isExact(RouteConstant.INVENTORY_USERS),
        },
        {
          title: "Distributors",
          path: RouteConstant.REGIONAL_DISTRIBUTORS,
          enabled: true,
          exact: isExact(RouteConstant.REGIONAL_DISTRIBUTORS),
        },
        {
          title: "Agents",
          path: RouteConstant.AGENTS,
          enabled: true,
          exact: isExact(RouteConstant.AGENTS),
        },
        {
          title: "Handlers",
          path: RouteConstant.HANDLERS,
          enabled: true,
          exact: isExact(RouteConstant.HANDLERS),
        },
      ],
    },
    {
      title: "Charges & Fee",
      path: "",
      enabled: true,
      exact: false,
      Icon: UserIcon,
      hasSubMenu: true,
      items: [
        {
          title: "Inter Switch E-value",
          path: RouteConstant.INTER_SWITCH_E_VALUE,
          enabled: true,
          exact: true,
        },
        {
          title: "Sim Charges",
          path: RouteConstant.SIM_CARD_CHARGES,
          enabled: true,
          exact: true,
        },
        {
          title: "Package Bundle",
          path: RouteConstant.PACKAGE_BUNDLE,
          enabled: true,
          exact: true,
        },
      ],
    },
    {
      title: "Apps",
      path: "",
      enabled: true,
      exact: false,
      Icon: AppsIcon,
      hasSubMenu: true,
      items: [
        {
          title: "Current Version",
          path: RouteConstant.CURRENT_VERSION,
          enabled: true,
          exact: true,
        },
        {
          title: "App Version History",
          path: RouteConstant.APP_VERSION_HISTORY,
          enabled: true,
          exact: true,
        },
      ],
    },

    {
      title: "Search information",
      path: RouteConstant.SEARCH_INFORMATION,
      enabled: true,
      exact: true,
      Icon: UserSearchIcon,
    },
  ];

  const manageAccountMenuList: MenuItemProps[] = [
    {
      title: "Manage account",
      path: RouteConstant.MANAGE_ACCOUNT,
      enabled: true,
      Icon: UserIcon,
      exact: false,
    },
    {
      title: "Rise a ticket",
      path: RouteConstant.RISE_TICKET,
      enabled: true,
      Icon: RiseTicketIcon,
      exact: true,
    },
  ];

  return (
    <>
      <List
        disablePadding
        className={mdDevice ? "" : "tw-pt-[40px]"}
        sx={{
          height: {
            xs: "100%",
            md: "auto",
          },
        }}
      >
        {menuList.map((item, index) => (
          <MenuItem key={index} {...item} />
        ))}
      </List>

      <Box px={1} className="tw-mt-auto">
        <Box
          py={2}
          className="tw-rounded-lg"
          sx={{ backgroundColor: palette.grey[100] }}
        >
          <Box pb={1} mb={1} className="tw-border-b">
            <Button
              color="secondary"
              fullWidth
              endIcon={<ExpandMoreIcon />}
              className="tw-justify-between"
            >
              My Account
            </Button>
          </Box>
          <List
            disablePadding
            className={mdDevice ? "" : "tw-pt-[40px]"}
            sx={{
              height: {
                xs: "100%",
                md: "auto",
              },
            }}
          >
            {manageAccountMenuList.map((item, index) => (
              <MenuItem key={index} {...item} />
            ))}
          </List>
        </Box>
      </Box>

      {!mdDevice ? (
        <Box p={2}>
          <SignOut />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default NavbarMenuItems;

const MenuItem: React.FC<MenuItemProps> = ({
  title,
  path,
  Icon,
  hasSubMenu,
  items,
  enabled,
  exact,
  isSubMenuItem,
}) => {
  const Component = hasSubMenu ? MultiLevel : SingleLevel;
  return (
    <Component
      title={title}
      path={path}
      Icon={Icon}
      hasSubMenu={hasSubMenu}
      items={items}
      enabled={enabled}
      exact={exact}
      isSubMenuItem={isSubMenuItem}
    />
  );
};

const SingleLevel: React.FC<MenuItemProps> = ({
  title,
  path,
  Icon,
  enabled,
  exact,
  isSubMenuItem,
}) => {
  const { palette } = useTheme();

  return (
    <>
      <ListItem
        disablePadding
        className={`${
          isSubMenuItem ? "tw-pl-[30px]" : "tw-pr-[10px]"
        } tw-py-[4px]`}
      >
        {path && enabled ? (
          <NavLink to={path} className="tw-w-full" end={exact}>
            {({ isActive }) => (
              <Button
                fullWidth
                variant="text"
                sx={{
                  backgroundColor: isActive
                    ? isSubMenuItem
                      ? alpha(palette.primary.main, 0.05)
                      : palette.primary.main
                    : "transparent",
                  color: isActive
                    ? isSubMenuItem
                      ? palette.primary.main
                      : palette.primary.contrastText
                    : palette?.text?.secondary,
                  borderRadius: isSubMenuItem ? "8px 0 0 8px" : "0 8px 8px 0",
                  "&:hover, &:focus": {
                    backgroundColor: isActive
                      ? palette.primary.main
                      : alpha(palette.primary.main, 0.05),
                    color: isActive
                      ? palette.primary.contrastText
                      : palette?.text?.secondary,
                  },
                }}
                className="tw-p-[10px] tw-justify-start tw-text-left"
                endIcon={isSubMenuItem ? <KeyboardArrowRightIcon /> : <></>}
              >
                {Icon ? (
                  <ListItemIcon
                    sx={{
                      height: "30px",
                      width: "30px",
                      minWidth: "30px",
                      backgroundColor: isActive
                        ? path === RouteConstant.DASHBOARD
                          ? "transparent"
                          : alpha(palette.primary.contrastText, 0.05)
                        : path === RouteConstant.DASHBOARD
                        ? "transparent"
                        : alpha(palette?.text?.secondary, 0.05),
                    }}
                    className="tw-flex tw-justify-center tw-items-center tw-rounded-md tw-mr-[12px]"
                  >
                    <Icon
                      width={path === RouteConstant.DASHBOARD ? "24px" : "20px"}
                      height={
                        path === RouteConstant.DASHBOARD ? "24px" : "20px"
                      }
                      color={
                        isActive
                          ? palette.primary.contrastText
                          : palette?.text?.secondary
                      }
                    />
                  </ListItemIcon>
                ) : (
                  <></>
                )}
                <Box className="tw-flex tw-justify-between tw-items-center tw-flex-auto">
                  <Typography className="tw-line-clamp-1 tw-text-[14px]">
                    {title}
                  </Typography>
                </Box>
              </Button>
            )}
          </NavLink>
        ) : (
          <></>
        )}
      </ListItem>
    </>
  );
};

const MultiLevel: React.FC<MenuItemProps> = ({
  title,
  path,
  Icon,
  items,
  enabled,
}) => {
  const { palette } = useTheme();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const toggleSubMenu = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    const hasActiveSubmenuItem = items?.some(
      (item) => item?.path === location.pathname
    );

    if (hasActiveSubmenuItem) setOpen(hasActiveSubmenuItem);
  }, [items, location.pathname]);

  return (
    <>
      {/* {enabled ? ( */}
      <ListItem
        disablePadding
        onClick={toggleSubMenu}
        className="tw-relative tw-flex-col"
      >
        <Button
          fullWidth
          sx={{
            color: palette?.text?.secondary,
            borderRadius: 0,
          }}
          className="tw-p-[10px] tw-justify-between"
        >
          {Icon ? (
            <ListItemIcon
              sx={{
                height: "30px",
                width: "30px",
                minWidth: "30px",
                backgroundColor:
                  // isActive
                  //   ? alpha(palette.primary.contrastText, 0.05)
                  // :
                  alpha(palette?.text?.secondary, 0.05),
              }}
              className="tw-flex tw-justify-center tw-items-center tw-rounded-md tw-mr-[12px]"
            >
              <Icon
                width="20px"
                height="20px"
                color={palette?.text?.secondary}
              />
            </ListItemIcon>
          ) : (
            <></>
          )}
          <Box className="tw-flex tw-justify-between tw-items-center tw-flex-auto">
            <Typography className="tw-line-clamp-1 tw-text-[14px]">
              {title}
            </Typography>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        </Button>

        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          // className="tw-absolute tw-top-[100%] tw-left-0 tw-min-w-[280px] tw-shadow tw-bg-white"
          className="tw-w-[100%]"
        >
          <List className="tw-flex tw-flex-col tw-py-0 test">
            {items?.map((subMenuItems, index) => (
              <MenuItem key={index} {...subMenuItems} isSubMenuItem />
            ))}
          </List>
        </Collapse>
      </ListItem>
      {/* ) : (
        <></>
      )} */}
    </>
  );
};
