import HandlerDeailsLayout from "component/handler/details";
import { RouteConstant } from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { Navigate, useParams } from "react-router-dom";

const HandlerDeails = () => {
    const params = useParams();
    if (!params?.id) return <Navigate to={RouteConstant.AGENTS} />;

    return (
        <MainContainer>
            <HandlerDeailsLayout ID={params.id} />
        </MainContainer>
    );
};

export default HandlerDeails;