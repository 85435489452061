import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React from "react";
import CustomerManagementDetailsInterface from "_interfaces/customer-management/details";

interface Props {
  data: CustomerManagementDetailsInterface["documentInfo"] | null;
}

const DocumentInformationTable: React.FC<Props> = ({ data }) => {
  const rows: {
    field: keyof NonNullable<
      CustomerManagementDetailsInterface["documentInfo"]
    >;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
    { field: "nameAsDocument", headerName: "Name as Document" },
    { field: "identityType", headerName: "Identity Type" },
    // Tourist
    { field: "passportNumber", headerName: "Passport Number" },
    {
      field: "passportIssuingDate",
      headerName: "Passport Issue Date",
      renderCell: (v: string) => moment(v).format("Do MMM, YYYY"),
    },
    {
      field: "passportExpiryDate",
      headerName: "Passport Expiry Date",
      renderCell: (v: string) => moment(v).format("Do MMM, YYYY"),
    },
    { field: "visaIdNumber", headerName: "Visa Id Number" },
    {
      field: "visaIssuingDate",
      headerName: "Visa Issue Date",
      renderCell: (v: string) => moment(v).format("Do MMM, YYYY"),
    },
    {
      field: "visaExpiryDate",
      headerName: "Visa Expiry Date",
      renderCell: (v: string) => moment(v).format("Do MMM, YYYY"),
    },

    // Citizen
    { field: "givenName", headerName: "Given Name" },
    { field: "surName", headerName: "Sur-name" },
    { field: "cardNo", headerName: "Car No" },
    { field: "nationalIdIdNumber", headerName: "National ID Number" },
    {
      field: "nationalIdIssuingDate",
      headerName: "National ID Issue Date",
      renderCell: (v: string) => moment(v).format("Do MMM, YYYY"),
    },
    {
      field: "nationalIdExpiryDate",
      headerName: "National ID Expiry Date",
      renderCell: (v: string) => moment(v).format("Do MMM, YYYY"),
    },

    // Refugee
    { field: "refugeeDocumentNumber", headerName: "Refugee Document Number" },
    {
      field: "refugeeDocumentIssuingDate",
      headerName: "Issue Date",
      renderCell: (v: string) => moment(v).format("Do MMM, YYYY"),
    },
    {
      field: "refugeeDocumentExpiryDate",
      headerName: "Expiry Date",
      renderCell: (v: string) => moment(v).format("Do MMM, YYYY"),
    },
  ];

  return (
    <>
      {data?.id ? (
        <>
          <DetailsBox data={data} rows={rows} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default DocumentInformationTable;
