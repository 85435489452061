import AgentDeailsLayout from "component/agents/details";
import DistributorDeailsLayout from "component/regional-distributors/details";
import { RouteConstant } from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { useParams, Navigate } from "react-router-dom";

const AgentDeails = () => {
    const params = useParams();
    if (!params?.id) return <Navigate to={RouteConstant.AGENTS} />;

    return (
        <MainContainer>
            <AgentDeailsLayout ID={params.id} />
        </MainContainer>
    );
};

export default AgentDeails;