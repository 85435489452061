export interface DeactivateSimErrorProps {
  reason: string;
  message: string;
}

const defaultDeactivateSimErrorProps: DeactivateSimErrorProps = {
  reason: "",
  message: "",
};

export class DeactivateSimErrorModel {
  reason: string;
  message: string;

  constructor(data: DeactivateSimErrorProps = defaultDeactivateSimErrorProps) {
    this.reason = data.reason || "";
    this.message = data.message || "";
  }
}
