import {
  CreatePackageBundleProps,
  GetPackageBundleProps,
  UpdatePackageBundleProps,
} from "_interfaces/functions/http-requests/package-bundle";
import {
  CREATE_PACKAGE_BUNDLE,
  GET_PACKAGE_BUNDLE,
  GET_PACKAGE_BUNDLE_BY_ID,
  UPDATE_PACKAGE_BUNDLE,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const CreatePackageBundle = ({ DATA }: CreatePackageBundleProps) => {
  return HTTP({
    Method: "POST",
    Url: CREATE_PACKAGE_BUNDLE,
    Data: DATA,
  });
};

export const UpdatePackageBundle = ({ DATA }: UpdatePackageBundleProps) => {
  return HTTP({
    Method: "POST",
    Url: UPDATE_PACKAGE_BUNDLE,
    Data: DATA,
  });
};

export const GetPackageBundle = ({ DATA }: GetPackageBundleProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_PACKAGE_BUNDLE,
    Data: DATA,
  });
};

export const GetPackageBundleById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_PACKAGE_BUNDLE_BY_ID(ID),
  });
};
