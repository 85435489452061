import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { SimManagementFilterModel } from "_models/data/sim-management/data.sim-management-filter.model";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import SideDrawer from "parts/sideDrawer";
import React, { Fragment, useMemo } from "react";

interface InputListProps extends FormInputProps {
  name: "SIM_type" | "serviceType" | "status" | "isReserved";
}
interface Props extends SideDrawerProps {
  filters: SimManagementFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<SimManagementFilterModel>>;
  handleSetSearchParams?: () => void;
  handleClearActiveButton?: () => void;
}

const SimManagementFilter: React.FC<Props> = ({
  open,
  onClose,
  filters,
  setFilters,
  handleSetSearchParams,
  handleClearActiveButton,
}) => {
  const handleAutoComplete = (
    e: React.SyntheticEvent,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    if (name === "SIM_type" && handleSetSearchParams) {
      handleSetSearchParams();
    }
    if (handleClearActiveButton) handleClearActiveButton();
    setFilters((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "SIM_type",
        label: "Filter by SIM type",
        placeholder: "Select SIM type",
        options: [
          {
            id: "physicalSim",
            value: "physicalSim",
            title: "Physical SIM",
            isActive: true,
          },
          { id: "eSim", value: "eSim", title: "E-Sim", isActive: true },
        ],
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "serviceType",
        label: "Filter by Service type",
        placeholder: "Select Service type",
        options: [
          {
            id: "prePaid",
            value: "prePaid",
            title: "Pre Paid",
            isActive: true,
          },
          {
            id: "postPaid",
            value: "postPaid",
            title: "Post Paid",
            isActive: true,
          },
          {
            id: "dataOnly",
            value: "dataOnly",
            title: "Data only",
            isActive: true,
          },
          {
            id: "deviceOnly",
            value: "deviceOnly",
            title: "Device Only",
            isActive: true,
          },
        ],
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "status",
        label: "Filter by Status",
        placeholder: "Select Status",
        options: [
          {
            id: "idle",
            value: "idle",
            title: "Idle",
            isActive: true,
          },
          {
            id: "inprogress",
            value: "inprogress",
            title: "In-progress",
            isActive: true,
          },
          {
            id: "active",
            value: "active",
            title: "Active",
            isActive: true,
          },
          {
            id: "notActive",
            value: "notActive",
            title: "In-active",
            isActive: true,
          },
          {
            id: "blocked",
            value: "blocked",
            title: "Blocked",
            isActive: true,
          },
        ],
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "isReserved",
        label: "Filter by Reserved",
        placeholder: "Select Reserved",
        options: [
          {
            id: "true",
            value: "true",
            title: "Reserved",
            isActive: true,
          },
          {
            id: "false",
            value: "false",
            title: "Not Reserved",
            isActive: true,
          },
        ],
        multiple: false,
      },
    ],
    []
  );

  return (
    <>
      <SideDrawer open={open} onClose={onClose}>
        <Box id="filter_sim_management">
          <Grid container rowSpacing={3} columnSpacing={2}>
            {inputList.map(
              (
                { type, name, label, placeholder, options, multiple },
                index
              ) => (
                <Fragment key={index}>
                  <>
                    {type === "autoComplete" && options?.length ? (
                      <Grid item xs={12}>
                        <InputAutoComplete
                          name={name}
                          options={options}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options?.length &&
                            filters?.[name as keyof SimManagementFilterModel]
                          }
                          multiple={multiple}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                </Fragment>
              )
            )}
          </Grid>
        </Box>
      </SideDrawer>
    </>
  );
};

export default SimManagementFilter;
