import { ChangePasswordModel } from "_models/data/auth/data.change-password.model";
import { isValidPassword } from "functions/validators";

export const validateChangePassword = (DATA: ChangePasswordModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.password?.trim()) {
    valid = false;
    errors.push({
      name: "password",
      error: "Password is required",
    });
  }

  if (!DATA.confirmPassword?.trim()) {
    valid = false;
    errors.push({
      name: "confirmPassword",
      error: "Re-type password",
    });
  }

  if (DATA.password && !isValidPassword(DATA.password)) {
    valid = false;
    errors.push({
      name: "password",
      error:
        "Password must contain at least 8 characters, including at least one lowercase letter, one uppercase letter, one digit, and one special character",
    });
  }

  if (
    DATA.confirmPassword &&
    DATA.password &&
    DATA.confirmPassword !== DATA.password
  ) {
    valid = false;
    errors.push({
      name: "confirmPassword",
      error: "Password doesn't match",
    });
  }

  return { valid, errors };
};
