import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import CustomDataGrid from "parts/customDataGrid";
import { IsActiveCell } from "parts/table/customCell";
import React, { Fragment, useEffect, useState } from "react";
import { RegionalDistributorsFilterModelProps } from "_models/data/regional-distributors/data.regional-distributors-filter.model";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import AgentsTableInterface from "_interfaces/agent";

const AgentsTable: React.FC<
AgentsTableInterface
> = ({
  loading,
  data,
  count,
  paginationModel,
  setPaginationModel,
}) => {
    const { palette } = useTheme();

    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const navigate = useNavigate();

    // useEffect(() => {

    //   setFilters((prev) => ({
    //     ...prev
    //   }));
    // }, [sortModel]);

    const columns: GridColDef[] = [
      {
        field: "name",
        headerName: "Agent Name",
        flex: 1,
        minWidth: 150,
      },
      { field: "email", headerName: "Email", flex: 1, minWidth: 150 },
      { field: "phone", headerName: "Phone", flex: 1, minWidth: 150 },
      {
        field: "assignedLocation",
        headerName: "Regions",
        flex: 1,
        minWidth: 150,
        sortable: false,
        renderCell: (params) => {
          return (
            <>
              {params?.row?.assignedLocation?.length ? (
                params.row.assignedLocation.map(
                  (location: any, i: number, arr: any) => (
                    <Fragment key={i}>
                      {location?.region}
                      {i < arr.length - 1 && ", "}
                    </Fragment>
                  )
                )
              ) : (
                <></>
              )}
            </>
          );
        },
      },
      {
        field: "date",
        headerName: "Created On",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => moment(params.value).format("MMMM Do YYYY"),
      },
      {
        field: "isActive",
        headerName: "Status",
        minWidth: 80,
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: (params) => <IsActiveCell status={params?.value} />,
      },
      {
        field: "seeDetails",
        headerName: "See Details",
        minWidth: 130,
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: (params) => {
          return (
            <>
              <Box className="tw-flex tw-justify-center tw-items-center">
                <IconButton
                  size="small"
                  onClick={() =>
                    params?.row?.id &&
                    navigate(
                      `${RouteConstant.AGENTS}/${params?.row?.id}`
                    )
                  }
                  sx={{ color: palette.secondary.main }}
                >
                  <Tooltip title="Edit">
                  <Visibility color="inherit" fontSize="small" />
                  </Tooltip>
                </IconButton>
              </Box>
            </>
          );
        },
      },
      // {
      //   field: "action",
      //   headerName: "Action",
      //   minWidth: 130,
      //   align: "center",
      //   headerAlign: "center",
      //   sortable: false,
      //   renderCell: (params) => {
      //     return (
      //       <>
      //         <Box className="tw-flex tw-justify-center tw-items-center">
      //           <IconButton
      //             size="small"
      //             onClick={() => params?.row?.id && setEditId(params?.row?.id)}
      //             sx={{ color: palette.secondary.main }}
      //           >
      //             <Tooltip title="Edit">
      //               <EditIcon color="inherit" fontSize="small" />
      //             </Tooltip>
      //           </IconButton>
      //         </Box>
      //       </>
      //     );
      //   },
      // },
    ];
    return (
      <>
        <Box mt={2}>
          <CustomDataGrid
            loading={loading}
            columns={columns}
            rows={data || []}
            count={count}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            sortModel={sortModel}
            setSortModel={setSortModel}
          />
        </Box>
      </>
    );
  };

export default AgentsTable;
