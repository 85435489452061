import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  AutoCompleteOptionsProps,
  FormInputProps,
} from "_interfaces/_common/forms";
import {
  CreateSimCardChargesProps,
  UpdateSimCardChargesProps,
} from "_interfaces/functions/http-requests/sim-card-charges";
import { SimCardChargesModel } from "_models/data/sim-card-charges/data.sim-card-charges.model";
import { SimCardChargesErrorModel } from "_models/error/sim-card-charges/error.sim-card-charges.model";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import Text from "component/_common/forms/text";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import {
  CreateSimCardCharges,
  GetSimCardChargesById,
  UpdateSimCardCharges,
} from "functions/http-requests/sim-card-charges";
import {
  isValidString,
  renderInvalidStringErrorText,
} from "functions/validators";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import { validateSimCardCharges } from "./formValidator";
import axios from "axios";

interface InputListProps extends FormInputProps {
  grid?: number;
  options?: AutoCompleteOptionsProps[];
  name: "simCharges" | "serviceCharges" | "vatPercent" | "MSISDNCategory";
}

interface SimCardChargesFormInterface {
  editId?: string | null;
  handleRefresh: () => void;
  onClose: () => void;
}

const SimCardChargesForm: React.FC<SimCardChargesFormInterface> = ({
  editId,
  handleRefresh,
  onClose,
}) => {
  const Dispatch = useDispatch();

  const [state, setState] = useState<SimCardChargesModel>(
    new SimCardChargesModel()
  );
  const [errors, setErrors] = useState<SimCardChargesErrorModel>(
    new SimCardChargesErrorModel()
  );

  const [editRefresh, setEditRefresh] = useState<boolean>(false);

  const handleEditRefresh = (REFRESH: boolean) => setEditRefresh(REFRESH);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      if (name === "name" && value) {
        if (!isValidString(value)) {
          return setErrors((prev) => ({
            ...prev,
            [name]: renderInvalidStringErrorText(),
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "",
          }));
        }
      }
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleCreate = ({ DATA }: CreateSimCardChargesProps) => {
    Dispatch(toggleLoading(true));
    CreateSimCardCharges({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_created ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleUpdate = ({ DATA }: UpdateSimCardChargesProps) => {
    Dispatch(toggleLoading(true));
    UpdateSimCardCharges({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_updated ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateSimCardCharges(state);

    if (ValidateStep?.valid) {
      const DATA: CreateSimCardChargesProps["DATA"] = {
        simCharges: state.simCharges,
        serviceCharges: state.serviceCharges,
        vatPercent: state.vatPercent,
        MSISDNCategory: state.MSISDNCategory,
      };

      if (editId) {
        const EDIT_DATA: UpdateSimCardChargesProps["DATA"] = {
          ...DATA,
          id: state?.id,
        };

        handleUpdate({ DATA: EDIT_DATA });
      } else handleCreate({ DATA });
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  // GET BY ID
  const handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetSimCardChargesById(ID)
      .then((res) => {
        const data = res?.data;
        if (data?.statusCode === AppStatusCode.api_success) {
          let DATA: any = data?.data;
          if (editRefresh) {
            setState((prev) => ({
              ...prev,
              isActive: DATA.isActive,
            }));
            handleEditRefresh(false);
          } else {
            setState({
              id: DATA?._id || "",
              simCharges: DATA?.simCharges?.toString() || "",
              serviceCharges: DATA?.serviceCharges?.toString() || "",
              vatPercent: DATA?.vatPercent?.toString(),
              MSISDNCategory: DATA?.MSISDNCategory || "",
            });
          }
        } else {
          Dispatch(setAlert({ type: "error", message: data?.message }));
          setState(new SimCardChargesModel());
          onClose();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  useEffect(() => {
    if (editId) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(editId);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [editId]);

  useEffect(() => {
    if (editId && editRefresh) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(editId);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [editId, editRefresh]);

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "number",
        name: "simCharges",
        label: "Sim Charges",
        placeholder: "Enter Sim Charges",
      },
      {
        type: "number",
        name: "serviceCharges",
        label: "Service Charges",
        placeholder: "Enter Service Charges",
      },
      {
        type: "number",
        name: "vatPercent",
        label: "VAT Percent",
        placeholder: "Enter VAT Percent",
      },
      {
        type: "autoComplete",
        name: "MSISDNCategory",
        label: "MSISDN Category",
        placeholder: "Select MSISDN Category",
        options: [
          { id: "normal", value: "normal", title: "Normal", isActive: true },
          { id: "premium", value: "premium", title: "Premium", isActive: true },
        ],
        multiple: false,
      },
    ],
    []
  );

  return (
    <>
      <Box
        component="form"
        id="crete_edit_sim_card_charges_form"
        onSubmit={handleSubmit}
      >
        <Grid container rowSpacing={3} columnSpacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                grid,
                autoComplete,
                disabled,
                options,
                multiple,
              },
              index
            ) => (
              <Fragment key={index}>
                <>
                  {type === "text" || type === "email" || type === "number" ? (
                    <Grid item xs={12}>
                      <Text
                        type={type}
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        autoComplete={autoComplete || "off"}
                        value={state[name as keyof SimCardChargesModel]}
                        errorText={
                          errors[name as keyof SimCardChargesErrorModel]
                        }
                        onChange={handleChange}
                        onFocus={handleFocus}
                        disabled={disabled}
                      />
                    </Grid>
                  ) : type === "autoComplete" ? (
                    <Grid item xs={12}>
                      <InputAutoComplete
                        name={name}
                        options={options || []}
                        label={label}
                        placeholder={placeholder}
                        onChange={(e, v, m) =>
                          handleAutoComplete(e, v, name, m)
                        }
                        value={
                          options &&
                          options.length &&
                          state?.[name as keyof SimCardChargesModel]
                        }
                        errorText={
                          errors[name as keyof SimCardChargesErrorModel]
                        }
                        onFocus={handleFocus}
                        multiple={multiple}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              </Fragment>
            )
          )}

          <Grid item xs={12} order={7}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
            >
              {editId ? "Save Changes" : "Add New Charges"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SimCardChargesForm;
