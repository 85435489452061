interface SimCardChargesModelProps {
  id?: string;
  simCharges?: string;
  serviceCharges?: string;
  vatPercent?: string;
  MSISDNCategory?: string;
}

export class SimCardChargesModel {
  id?: string;
  simCharges?: string;
  serviceCharges?: string;
  vatPercent?: string;
  MSISDNCategory?: string;

  constructor(data: SimCardChargesModelProps = {}) {
    this.id = data.id || "";
    this.simCharges = data.simCharges || "";
    this.serviceCharges = data.serviceCharges || "";
    this.vatPercent = data.vatPercent || "";
    this.MSISDNCategory = data.MSISDNCategory || "";
  }
}
