import RegionalDistributorsLayout from "component/regional-distributors";
import MainContainer from "parts/mainContainer";

const RegionalDistributors = () => {
  return (
    <MainContainer>
      <RegionalDistributorsLayout />
    </MainContainer>
  );
};

export default RegionalDistributors;
