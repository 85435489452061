import AnalyticsLayout from "component/analytics";
import MainContainer from "parts/mainContainer";

const Analytics = () => {
  return (
    <MainContainer>
      <AnalyticsLayout />
    </MainContainer>
  );
};

export default Analytics;
