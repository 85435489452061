import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import { Channel, ClearDataOnLogout } from "functions/helper";
import { HTTP_ERROR } from "functions/http";
import { AuthLogOut } from "functions/http-requests/auth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import { setLogout } from "state/reducers/user";

const SignOut = () => {
  const Dispatch = useDispatch();
  const { palette, breakpoints } = useTheme();
  const mdDevice = useMediaQuery(breakpoints.up("md"));

  const handleLogout = () => {
    const signature = localStorage.getItem("signature");

    if (signature) {
      Dispatch(toggleLoading(true));

      AuthLogOut({
        DATA: {
          signature,
        },
      })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => {
          Dispatch(toggleLoading(false));
          Dispatch(setLogout());
          Channel.postMessage("logout");
          ClearDataOnLogout();
        });
    }
  };

  useEffect(() => {
    Channel.onmessage = () => {
      ClearDataOnLogout();
      Channel.close();
    };
  }, []);

  return (
    <>
      {mdDevice ? (
        <>
          <IconButton
            onClick={handleLogout}
            sx={{
              border: `1px solid ${alpha(palette.secondary.main, 0.2)}`,
              backgroundColor: alpha(palette.secondary.main, 0.05),
              color: palette.common.black,
              "&:hover,:focus": {
                backgroundColor: alpha(palette.secondary.main, 0.05),
              },
            }}
            className="tw-w-[40px] tw-h-[40px] tw-rounded-full tw-p-0 tw-overflow-hidden"
          >
            <LogoutIcon fontSize="small" />
          </IconButton>
        </>
      ) : (
        <>
          <Button
            onClick={handleLogout}
            variant="outlined"
            fullWidth
            endIcon={<></>}
            className="tw-capitalize"
          >
            Sign Out
          </Button>
        </>
      )}
    </>
  );
};

export default SignOut;
