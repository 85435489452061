import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FormInputProps } from "_interfaces/_common/forms";
import { DeactivateSimModel } from "_models/data/sim-management/data.deactivate-sim.model";
import { DeactivateSimErrorModel } from "_models/error/sim-management/error.deactivate-sim.model";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import Text from "component/_common/forms/text";
import SectionTitle from "parts/sectionTitle";
import React, { Fragment, useState } from "react";

interface InputListProps extends FormInputProps {
  name: "reason" | "message";
}

const inputList: InputListProps[] = [
  {
    type: "autoComplete",
    name: "reason",
    label: "Reason",
    placeholder: "Select reason",
    options: [
      {
        id: "inventoryManager",
        value: "inventoryManager",
        title: "Inventory Manager",
        isActive: true,
      },
    ],
  },
  {
    type: "textArea",
    name: "message",
    label: "Message",
    placeholder: "Message",
    minRows: 3,
  },
];

interface Props {
  onClose: () => void;
}
const DeactivateSimCard: React.FC<Props> = ({ onClose }) => {
  const [state, setState] = useState<DeactivateSimModel>(
    new DeactivateSimModel()
  );
  const [errors, setErrors] = useState<DeactivateSimErrorModel>(
    new DeactivateSimErrorModel()
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev: any) => ({ ...prev, [name]: "" }));
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
  };

  return (
    <>
      <Box
        p={{ md: 5 }}
        className="tw-flex tw-flex-col tw-items-center tw-max-w-[450px] tw-mx-auto tw-text-center"
      >
        <Box pb={3}>
          <CancelOutlinedIcon color="error" sx={{ width: 48, height: 48 }} />
        </Box>
        <SectionTitle
          title="Do you want to DeActivate SIM ?"
          fontSize={20}
          fontWeight={700}
        />
        <Typography fontSize={12} color="black" pt={0.5}>
          Once SIM is Deactivate, this SIM will be no longer be in used for 48
          Months
        </Typography>
        <Box
          component="form"
          name="deactivate_sim_form"
          onSubmit={handleSubmit}
          className="tw-text-left tw-pt-[20px] tw-w-[100%]"
        >
          <Grid container spacing={2}>
            {inputList.map(
              (
                {
                  type,
                  name,
                  label,
                  placeholder,
                  autoComplete,
                  disabled,
                  options,
                  multiple,
                  minRows,
                  grid,
                },
                index
              ) => (
                <Fragment key={index}>
                  <>
                    {type === "text" || type === "textArea" ? (
                      <Grid item xs={12} md={grid || 12}>
                        <Text
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof DeactivateSimModel]}
                          errorText={
                            errors[name as keyof DeactivateSimErrorModel]
                          }
                          onChange={handleChange}
                          onFocus={handleFocus}
                          disabled={disabled}
                          minRows={minRows}
                        />
                      </Grid>
                    ) : type === "autoComplete" ? (
                      <Grid item xs={12} md={grid || 12}>
                        <InputAutoComplete
                          name={name}
                          options={options || []}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options?.length &&
                            state?.[name as keyof DeactivateSimModel]
                          }
                          errorText={
                            errors[name as keyof DeactivateSimErrorModel]
                          }
                          onFocus={handleFocus}
                          multiple={multiple}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                </Fragment>
              )
            )}
          </Grid>

          <Box pt={3} className="tw-flex tw-justify-center">
            <Button
              variant="contained"
              color="error"
              className="tw-mx-[4px] tw-px-[30px]"
            >
              DeActivate
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className="tw-mx-[4px] tw-px-[30px]"
              onClick={onClose}
            >
              Discard
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DeactivateSimCard;
