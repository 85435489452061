import Visibility from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { GridColDef } from "@mui/x-data-grid";
import { camelCaseToFormattedString } from "functions/helper";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import CustomDataGrid from "parts/customDataGrid";
import { IsActiveCell } from "parts/table/customCell";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomerManagementTableInterface from "_interfaces/customer-management/customerManagementTable";

const CustomerManagementTable: React.FC<CustomerManagementTableInterface> = ({
  loading,
  data,
  count,
  paginationModel,
  setPaginationModel,
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "MSISDN",
      headerName: "MSISDN",
      flex: 1,
      minWidth: 150,
      renderCell: (params) =>
      (<Box
        sx={{ textDecoration: "underline", color: "#0808ce", cursor: "pointer" }}
        onClick={() => {
          params?.row?.id &&
            navigate(
              `${RouteConstant.CUSTOMER_MANAGEMENT}/${params?.row?.id}`
            )
        }} className="tw-capitalize">{params?.row?.MSISDN}</Box>
      ),
    },
    {
      field: "nameAsDocument",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => camelCaseToFormattedString(params.value),
      renderCell: (params) => (
        <Box className="tw-capitalize">{params?.formattedValue}</Box>
      ),
    },
    {
      field: "identityType",
      headerName: "Citizen Type",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => camelCaseToFormattedString(params.value),
      renderCell: (params) => (
        <Box className="tw-capitalize">{params?.formattedValue}</Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Registration Date",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => moment(params.value).format("Do MMM, YYYY"),
      renderCell: (params) => (
        <Box className="tw-capitalize">{params?.formattedValue}</Box>
      ),
    },
    {
      field: "kyc_Details",
      headerName: "KYC Status",
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <IsActiveCell status={params?.row?.kyc_Details?.status} />
      ),
    },
    {
      field: "MSISDN_details",
      headerName: "MSISDN Status",
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <IsActiveCell status={params?.row?.MSISDN_details?.status} />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() =>
                  params?.row?.id &&
                  navigate(
                    `${RouteConstant.CUSTOMER_MANAGEMENT}/${params?.row?.id}`
                  )
                }
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="View Details">
                  <Visibility color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];
  return (
    <>
      <CustomDataGrid
        loading={loading}
        columns={columns}
        rows={data || []}
        count={count}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </>
  );
};

export default CustomerManagementTable;
