import { useTheme } from "@mui/material/styles";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { ChartStatusInterface } from "_interfaces/_common/charts";

interface Props extends ChartStatusInterface {
  data?: object[];
}

const CustomPieChart: React.FC<Props> = ({ data }) => {
  const { palette } = useTheme();

  return (
    <>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            cx={"50%"}
            cy={"50%"}
            // innerRadius={65}
            outerRadius={80}
            paddingAngle={5}
            dataKey="value"
          >
            {data?.map((item: any, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  item?.color
                    ? item.color
                    : item?._id === "success"
                    ? palette.success.main
                    : palette.error.main
                }
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default CustomPieChart;
