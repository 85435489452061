import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FormInputProps } from "_interfaces/_common/forms";
import { ChangePasswordModel } from "_models/data/auth/data.change-password.model";
import { ChangePasswordErrorModel } from "_models/error/auth/error.change-password.model";
import Password from "component/_common/forms/password";
import SectionTitle from "parts/sectionTitle";
import React, { Fragment, useState } from "react";
import { validateChangePassword } from "./formValidator";
import CircularProgress from "@mui/material/CircularProgress";
import { ChangePassword } from "functions/http-requests/auth";
import { AppStatusCode } from "config/appStatusCode";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { HTTP_ERROR } from "functions/http";
import { PasswordChangeSuccess } from "./changePasswordMessageBoxes";
import { alpha } from "@mui/material";
import axios from "axios";

interface InputListProps extends FormInputProps {
  name: "password" | "confirmPassword";
  link?: string;
}

const inputList: InputListProps[] = [
  {
    type: "password",
    name: "password",
    label: "New Password",
    placeholder: "Enter new password",
  },
  {
    type: "password",
    name: "confirmPassword",
    label: "Re-type password",
    placeholder: "Re-type password",
  },
];

interface ChangePasswordFormProps {
  token: string;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ token }) => {
  const Dispatch = useDispatch();

  const [isProgress, setIsProgress] = useState<boolean>(false);
  const [isPasswordChangeSuccess, setIsPasswordChangeSuccess] =
    useState<boolean>(false);

  const [state, setState] = useState<ChangePasswordModel>(
    new ChangePasswordModel()
  );
  const [errors, setErrors] = useState<ChangePasswordErrorModel>(
    new ChangePasswordErrorModel()
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleChangePassword = () => {
    setIsProgress(true);
    if (token && state.password) {
      ChangePassword({
        DATA: { token, password: state.password },
      })
        .then((res) => {
          const data = res?.data;
          if (data?.statusCode === AppStatusCode.api_success) {
            setIsPasswordChangeSuccess(true);
          } else {
            setIsPasswordChangeSuccess(false);
            setState(new ChangePasswordModel());
          }
          Dispatch(setAlert({ type: data?.level, message: data?.message }));
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
            setState(new ChangePasswordModel());
          }
        })
        .finally(() => {
          setIsProgress(true);
        });
    }
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateChangePassword(state);

    if (ValidateStep?.valid) {
      handleChangePassword();
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  return (
    <>
      {isPasswordChangeSuccess ? (
        <>
          <PasswordChangeSuccess />
        </>
      ) : (
        <>
          <SectionTitle title="Change Password" fontSize={36} color="white" />
          <Typography className="tw-text-[14px] tw-font-[400] tw-mt-[10px]">
            Enter new password to change your password
          </Typography>

          <Box
            pt={5}
            sx={{
              color: ({ palette }) => alpha(palette.common.white, 0.6),
              "input, .MuiIconButton-root": {
                color: ({ palette }) => alpha(palette.common.white, 0.6),
              },
            }}
          >
            <Box
              component="form"
              name="auth_change_password_form"
              onSubmit={handleSubmit}
            >
              <Grid container spacing={3}>
                {inputList.map(
                  (
                    {
                      type,
                      name,
                      label,
                      placeholder,
                      autoComplete,
                      disabled,
                      link,
                    },
                    index
                  ) => (
                    <Fragment key={index}>
                      <>
                        {type === "password" ? (
                          <Grid item xs={12}>
                            <Password
                              type={type}
                              name={name}
                              label={label}
                              placeholder={placeholder}
                              autoComplete={autoComplete || "off"}
                              value={state[name as keyof ChangePasswordModel]}
                              errorText={
                                errors[name as keyof ChangePasswordErrorModel]
                              }
                              onChange={handleChange}
                              onFocus={handleFocus}
                              disabled={disabled}
                              isHideShow
                            />
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </>
                    </Fragment>
                  )
                )}

                <Grid item xs={12} mt={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disableElevation
                    className="tw-capitalize tw-py-[12px]"
                  >
                    {isProgress ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      "Change Password"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default ChangePasswordForm;
