import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MsisdnManagementDetailsInterface from "_interfaces/msisdn-management/details";
import { camelCaseToFormattedString } from "functions/helper";
import { IsActiveCell } from "parts/table/customCell";
import DetailsTable from "parts/table/detailsTable";
import React from "react";

interface Props {
  data: MsisdnManagementDetailsInterface | null;
}

const MsisdnSimInformationTable: React.FC<Props> = ({ data }) => {
  return (
    <>
      {data?.simInfo?.id ? (
        <DetailsTable>
          <TableRow>
            <TableCell>Carton</TableCell>
            <TableCell>{data?.simInfo?.carton}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ICCID</TableCell>
            <TableCell>{data?.simInfo?.ICCID}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>MSISDN</TableCell>
            <TableCell>{data?.simInfo?.MSISDN}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SIM Type</TableCell>
            <TableCell>
              <Box className="tw-capitalize">
                {data?.simInfo?.simType
                  ? camelCaseToFormattedString(data?.simInfo?.simType)
                  : ""}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Service Type</TableCell>
            <TableCell>
              <Box className="tw-capitalize">
                {data?.simInfo?.serviceType
                  ? camelCaseToFormattedString(data?.simInfo?.serviceType)
                  : ""}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Reserved / Available</TableCell>
            <TableCell>
              {data?.simInfo?.isReserved ? "Reserved" : "Available"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              <IsActiveCell status={data?.simInfo?.status} />
            </TableCell>
          </TableRow>
        </DetailsTable>
      ) : (
        <></>
      )}
    </>
  );
};

export default MsisdnSimInformationTable;
