import AppVersionHistoryLayout from "component/apps/app-version-history";
import MainContainer from "parts/mainContainer";


const AppVersionHistory = () => {
    return (
      <MainContainer>
        <AppVersionHistoryLayout />
      </MainContainer>
    );
  };
  
  export default AppVersionHistory;