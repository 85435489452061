import DoneAllIcon from "@mui/icons-material/DoneAll";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { alpha, useTheme } from "@mui/material/styles";
import { RouteConstant } from "navigation/constant";
import SectionTitle from "parts/sectionTitle";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  title?: string;
  message?: string;
}
const DefaultBox: React.FC<Props> = ({ title, message }) => {
  return (
    <>
      <Box className="tw-text-center">
        {title ? (
          <SectionTitle title={title} fontSize={36} color="white" />
        ) : (
          <></>
        )}
        {message ? (
          <Box dangerouslySetInnerHTML={{ __html: message }} />
        ) : (
          <></>
        )}

        <Box pt={5}>
          <Link to={RouteConstant.LOG_IN}>
            <Button variant="text">Back To Login</Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export const TokenNotFound = () => {
  return (
    <>
      <DefaultBox title="Oops!" message="We couldn't find Verification Token" />
    </>
  );
};

export const TokenNotVerified = () => {
  return (
    <>
      <DefaultBox
        title="Oops!"
        message="We couldn't verify token. <br /> Token is invalid or has been expired."
      />
    </>
  );
};

export const PasswordChangeSuccess = () => {
  const { palette } = useTheme();
  return (
    <>
      <Box className="tw-flex tw-flex-col tw-justify-center tw-items-center">
        <Box
          p={5}
          className="tw-w-[80px] tw-h-[80px] tw-rounded-full tw-flex tw-justify-center tw-items-center"
          sx={{
            backgroundColor: alpha(palette.success.main, 0.2),
            color: palette.success.main,
          }}
        >
          <DoneAllIcon sx={{ fontSize: 48 }} />
        </Box>
        <Box pt={2}>
          <DefaultBox
            title="Password Changed"
            message="Password successfully changed."
          />
        </Box>
      </Box>
    </>
  );
};
