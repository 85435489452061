export interface TwoWayAuthProps {
  email: string;
}

const defaultTwoWayAuthProps: TwoWayAuthProps = {
  email: "",
};

export class TwoWayAuthModel {
  email: string;

  constructor(data: TwoWayAuthProps = defaultTwoWayAuthProps) {
    this.email = data.email || "";
  }
}
