import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
// import AddUserIcon from "component/icons/add-user";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  isNew?: boolean;
}

const NotificationCard: React.FC<Props> = ({ isNew }) => {
  const { palette } = useTheme();
  return (
    <Link to="/" className="tw-block">
      <Button
        sx={{
          backgroundColor: isNew
            ? palette.common.white
            : palette.secondary.light,
          color: palette.text.secondary,
          "&:hover, :focus": {
            backgroundColor: isNew
              ? palette.common.white
              : palette.secondary.light,
          },
        }}
        className={`tw-capitalize tw-text-start tw-my-[4px] tw-p-[10px] ${
          isNew ? "tw-drop-shadow-md" : ""
        }`}
      >
        <Box className="tw-flex">
          <IconButton
            sx={{
              backgroundColor: palette.primary.main,
              color: palette.common.white,
              "&:hover, :focus": {
                backgroundColor: palette.primary.main,
              },
            }}
            className="tw-w-[48px] tw-h-[48px] tw-rounded-full "
          >
            {/* <AddUserIcon color={palette.common.white} /> */}
          </IconButton>
          <Box className="tw-flex tw-justify-between tw-items-center">
            <Box ml={1} minWidth={250}>
              <Typography
                variant="h6"
                fontSize={16}
                fontWeight="medium"
                color="black"
                className="tw-capitalize"
              >
                Approval Request
              </Typography>
              <Typography fontSize={12} color="black">
                New User Request For Approval{" "}
              </Typography>
            </Box>
            <Typography fontSize={12} color={palette.secondary.main}>
              2 Hr Ago
            </Typography>
          </Box>
        </Box>
      </Button>
    </Link>
  );
};

export default NotificationCard;
