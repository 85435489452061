export interface TwoWayAuthErrorProps {
  email: string;
}

const defaultTwoWayAuthErrorProps: TwoWayAuthErrorProps = {
  email: "",
};

export class TwoWayAuthErrorModel {
  email: string;

  constructor(data: TwoWayAuthErrorProps = defaultTwoWayAuthErrorProps) {
    this.email = data.email || "";
  }
}
