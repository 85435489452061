import React from "react";
import { IconsInterface } from "_interfaces/_common/icons";

const UserIcon: React.FC<IconsInterface> = ({ width, height, color }) => {
  return (
    <svg
      width={width || "18"}
      height={height || "21"}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4151 7.66126C9.25901 7.66126 10.7538 6.16648 10.7538 4.32257C10.7538 2.47867 9.25901 0.983887 7.4151 0.983887C5.5712 0.983887 4.07642 2.47867 4.07642 4.32257C4.07642 6.16648 5.5712 7.66126 7.4151 7.66126Z"
        fill={color}
      />
      <path
        d="M13.8464 13.0722C13.8464 10.0838 10.967 7.66113 7.41511 7.66113C3.8632 7.66113 0.983826 10.0838 0.983826 13.0722C0.983826 15.3047 13.8464 15.3047 13.8464 13.0722Z"
        fill={color}
      />
    </svg>
  );
};

export default UserIcon;
