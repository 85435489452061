export interface RiseTicketErrorProps {
  reason: string;
  message: string;
}

const defaultRiseTicketErrorProps: RiseTicketErrorProps = {
  reason: "",
  message: "",
};

export class RiseTicketErrorModel {
  reason: string;
  message: string;

  constructor(data: RiseTicketErrorProps = defaultRiseTicketErrorProps) {
    this.reason = data.reason || "";
    this.message = data.message || "";
  }
}
