import { Link } from "react-router-dom";
import LogoImage from "assets/images/logo-normal.png";

const NavbarLogo = () => {
  return (
    <Link to="/" className="tw-block">
      <img
        src={LogoImage}
        alt="Talkio Mobile"
        className="tw-w-[100px] tw-mx-auto"
      />
    </Link>
  );
};

export default NavbarLogo;
