import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import SectionTitle from "parts/sectionTitle";
import React from "react";

interface Props extends SideDrawerProps {
  children: React.ReactNode;
}

const SideDrawer: React.FC<Props> = ({ open, onClose, children }) => {
  return (
    <>
      <Drawer anchor="left" open={open} onClose={onClose}>
        <Box width={280}>
          <Box
            p={2}
            className="tw-border-b-[1px] tw-flex tw-justify-between tw-items-center"
          >
            <SectionTitle title="Filters" fontWeight={500} fontSize={20} />
            <IconButton onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box p={2}>{children}</Box>
        </Box>
      </Drawer>
    </>
  );
};

export default SideDrawer;
