import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { PackageBundleFilterModel } from "_models/data/package-bundle/data.package-bundle.model";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import SideDrawer from "parts/sideDrawer";
import React, { Fragment, useMemo } from "react";
import { generateAutocompleteOptions } from "functions/helper";
import {
  ALLOWED_PACKAGE_BUNDLE_FIELDS_category,
  ALLOWED_PACKAGE_BUNDLE_FIELDS_packageFor,
  ALLOWED_PACKAGE_BUNDLE_FIELDS_packageType,
} from "_interfaces/functions/http-requests/package-bundle";

interface InputListProps extends FormInputProps {
  name:
    | "isActive"
    | "category"
    | "packageType"
    | "packageFor"
    | "packageValidity";
}
interface Props extends SideDrawerProps {
  filters: PackageBundleFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<PackageBundleFilterModel>>;
}

const PackageBundleFilter: React.FC<Props> = ({
  open,
  onClose,
  filters,
  setFilters,
}) => {
  const handleAutoComplete = (
    e: React.SyntheticEvent,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setFilters((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "isActive",
        label: "Filter Status",
        placeholder: "Select Status",
        options: [
          { id: "true", value: "true", title: "Active", isActive: true },
          { id: "false", value: "false", title: "Inactive", isActive: true },
        ],
        grid: 12,
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "category",
        label: "Category",
        placeholder: "Select Category",
        options: generateAutocompleteOptions(
          ALLOWED_PACKAGE_BUNDLE_FIELDS_category
        ),
        multiple: false,
        grid: 12,
      },
      {
        type: "autoComplete",
        name: "packageType",
        label: "Package Type",
        placeholder: "Select Package Type",
        options: generateAutocompleteOptions(
          ALLOWED_PACKAGE_BUNDLE_FIELDS_packageType
        ),
        multiple: false,
        grid: 12,
      },
      {
        type: "autoComplete",
        name: "packageFor",
        label: "Package For",
        placeholder: "Select Package For",
        options: generateAutocompleteOptions(
          ALLOWED_PACKAGE_BUNDLE_FIELDS_packageFor
        ),
        multiple: false,
        grid: 12,
      },
      {
        type: "autoComplete",
        name: "packageValidity",
        label: "Package Validity",
        placeholder: "Select Validity",
        options: [
          {
            id: "7",
            value: "7",
            title: "Weekly",
            isActive: true,
          },
          {
            id: "30",
            value: "30",
            title: "Monthly",
            isActive: true,
          },
          {
            id: "365",
            value: "365",
            title: "Yearly",
            isActive: true,
          },
        ],
        grid: 12,
        multiple: false,
      },
    ],
    []
  );

  return (
    <>
      <SideDrawer open={open} onClose={onClose}>
        <Box id="filter_package_bundle">
          <Grid container rowSpacing={3} columnSpacing={2}>
            {inputList.map(
              (
                { type, name, label, placeholder, options, grid, multiple },
                index
              ) => (
                <Fragment key={index}>
                  <>
                    {type === "autoComplete" && options?.length ? (
                      <Grid item xs={12}>
                        <InputAutoComplete
                          name={name}
                          options={options}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options?.length &&
                            filters?.[name as keyof PackageBundleFilterModel]
                          }
                          multiple={multiple}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                </Fragment>
              )
            )}
          </Grid>
        </Box>
      </SideDrawer>
    </>
  );
};

export default PackageBundleFilter;
