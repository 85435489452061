import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import { ManageAccountNotificationModel } from "_models/data/manage-account/data.manage-account-notification.model";
import InputCheckbox from "component/_common/forms/inputCheckbox";
import React, { Fragment, useState } from "react";

interface InputListProps extends FormInputProps {
  name: "notification";
}

const inputList: InputListProps[] = [
  {
    type: "checkbox",
    name: "notification",
    options: [
      {
        id: "all",
        value: "all",
        title:
          "Enable All notification (stock alert, distributor, request etc)",
        isActive: true,
      },
      {
        id: "onlyAlert",
        value: "onlyAlert",
        title: "Only Alerts",
        isActive: true,
      },
      {
        id: "requestStocks",
        value: "requestStocks",
        title: "Only Requests & Stock",
        isActive: true,
      },
      {
        id: "stockUpdates",
        value: "stockUpdates",
        title: "Only Stock Updates",
        isActive: true,
      },
    ],
  },
];

const ManageAccountNotificationLayout = () => {
  const [state, setState] = useState<ManageAccountNotificationModel>(
    new ManageAccountNotificationModel()
  );

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
  };

  return (
    <>
      <Box
        component="form"
        name="manage_account_notification_form"
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          {inputList.map(({ type, name, options }, index) => (
            <Fragment key={index}>
              <>
                {type === "checkbox" && name === "notification" ? (
                  <Grid item xs={12}>
                    {options?.map((option, optionIndex) => (
                      <Box key={optionIndex}>
                        <InputCheckbox
                          name={name}
                          label={option.title}
                          value={state?.notification?.includes(
                            option?.id || option.value
                          )}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                          onChange={(e) => {
                            const { name, checked } = e.target;

                            if (checked) {
                              setState((prev) => ({
                                ...prev,
                                [name]: [
                                  option.id || option.value,
                                  ...prev[
                                    name as keyof ManageAccountNotificationModel
                                  ],
                                ],
                              }));
                            } else {
                              const filteredList = state[
                                name as keyof ManageAccountNotificationModel
                              ].filter(
                                (v) => v !== option.id || v !== option.value
                              );
                              setState((prev) => ({
                                ...prev,
                                [name]: filteredList,
                              }));
                            }
                          }}
                        />
                      </Box>
                    ))}
                  </Grid>
                ) : (
                  <></>
                )}
              </>
            </Fragment>
          ))}

          <Grid item xs={12}>
            <Box pt={5}>
              <Button
                type="submit"
                variant="contained"
                disableElevation
                className="tw-min-w-[120px]"
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ManageAccountNotificationLayout;
