import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import { UploadSimCartonProps } from "_interfaces/functions/http-requests/simManagement";
import { UploadCartonModel } from "_models/data/sim-management/data.upload-carton.model";
import { UploadCartonErrorModel } from "_models/error/sim-management/error.upload-carton.model";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import Text from "component/_common/forms/text";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import { UploadSimCarton } from "functions/http-requests/simManagement";
import {
  isValidString,
  renderInvalidStringErrorText,
} from "functions/validators";
import { Fragment, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import { validateSimCarton } from "./formValidator";
import axios from "axios";

interface InputListProps extends FormInputProps {
  name:
    | "cartonNo"
    | "batchNo"
    | "firstICCID"
    | "lastICCID"
    | "boxNo"
    | "SIM_type"
    | "firstIMSI"
    | "lastIMSI"
    | "serviceType";
}

interface Props {
  editId?: string;
  handleRefresh?: () => void;
  onClose?: () => void;
}

const UploadCartonForm: React.FC<Props> = ({
  editId,
  handleRefresh,
  onClose,
}) => {
  const Dispatch = useDispatch();

  const [state, setState] = useState<UploadCartonModel>(
    new UploadCartonModel()
  );
  const [errors, setErrors] = useState<UploadCartonErrorModel>(
    new UploadCartonErrorModel()
  );

  const [editRefresh, setEditRefresh] = useState<boolean>(false);

  const handleEditRefresh = (REFRESH: boolean) => setEditRefresh(REFRESH);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      if (name === "name" && value) {
        if (!isValidString(value)) {
          return setErrors((prev) => ({
            ...prev,
            [name]: renderInvalidStringErrorText(),
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "",
          }));
        }
      }
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleCreate = ({ DATA }: UploadSimCartonProps) => {
    Dispatch(toggleLoading(true));
    UploadSimCarton({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_created ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          if (onClose) onClose();
          if (handleRefresh) handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateSimCarton(state);

    if (ValidateStep?.valid) {
      handleCreate({
        DATA: state,
      });
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "number",
        name: "cartonNo",
        label: "Carton No",
        placeholder: "Enter Carton No",
        grid: 6,
        enabled: true,
      },
      {
        type: "text",
        name: "batchNo",
        label: "Batch No",
        placeholder: "Enter Batch No",
        grid: 6,
        enabled: true,
      },
      {
        type: "number",
        name: "firstICCID",
        label: "First ICCID",
        placeholder: "Enter First ICCID",
        grid: 6,
        enabled: true,
      },
      {
        type: "number",
        name: "lastICCID",
        label: "Last ICCID",
        placeholder: "Enter Last ICCID",
        grid: 6,
        enabled: true,
      },
      {
        type: "number",
        name: "boxNo",
        label: "Box No",
        placeholder: "Enter Box No",
        grid: 6,
        enabled: true,
      },
      {
        type: "autoComplete",
        name: "SIM_type",
        label: "SIM Type",
        placeholder: "Select SIM Type",
        multiple: false,
        grid: 6,
        enabled: true,
        options: [
          {
            id: "physicalSim",
            value: "physicalSim",
            title: "Physical SIM",
            isActive: true,
          },
          {
            id: "eSim",
            value: "eSim",
            title: "E-Sim",
            isActive: true,
          },
        ],
      },
      {
        type: "number",
        name: "firstIMSI",
        label: "First IMSI",
        placeholder: "Enter First IMSI",
        grid: 6,
        enabled: true,
      },
      {
        type: "number",
        name: "lastIMSI",
        label: "Last IMSI",
        placeholder: "Enter Last IMSI",
        grid: 6,
        enabled: true,
      },
      {
        type: "autoComplete",
        name: "serviceType",
        label: "Service Type",
        placeholder: "Select Service Type",
        multiple: false,
        grid: 6,
        enabled: true,
        options: [
          {
            id: "prePaid",
            value: "prePaid",
            title: "Pre Paid",
            isActive: true,
          },
          {
            id: "postPaid",
            value: "postPaid",
            title: "Post Paid",
            isActive: true,
          },
          {
            id: "dataOnly",
            value: "dataOnly",
            title: "Data Only",
            isActive: true,
          },
          {
            id: "deviceOnly",
            value: "deviceOnly",
            title: "Device Only",
            isActive: true,
          },
        ],
      },
    ],
    [editId]
  );

  return (
    <>
      <Box id="upload_sim_carton_form" component="form" onSubmit={handleSubmit}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                grid,
                autoComplete,
                disabled,
                enabled,
                options,
                multiple,
              },
              index
            ) => (
              <Fragment key={index}>
                {enabled ? (
                  <>
                    {type === "text" ||
                    type === "email" ||
                    type === "number" ? (
                      <Grid item xs={12} md={6} lg={grid || 6}>
                        <Text
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof UploadCartonModel]}
                          errorText={
                            errors[name as keyof UploadCartonErrorModel]
                          }
                          onChange={handleChange}
                          onFocus={handleFocus}
                          disabled={disabled}
                        />
                      </Grid>
                    ) : type === "autoComplete" ? (
                      <Grid item xs={12} md={6} lg={grid || 6}>
                        <InputAutoComplete
                          name={name}
                          options={options || []}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options.length &&
                            state?.[name as keyof UploadCartonModel]
                          }
                          errorText={
                            errors[name as keyof UploadCartonErrorModel]
                          }
                          onFocus={handleFocus}
                          multiple={multiple}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Fragment>
            )
          )}

          <Grid item xs={12} order={7}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
            >
              Upload Carton
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UploadCartonForm;
