import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import SimManagementDetailsInterface from "_interfaces/sim-management/details";
import { camelCaseToFormattedString } from "functions/helper";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import { IsActiveCell } from "parts/table/customCell";
import DetailsTable from "parts/table/detailsTable";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  data: SimManagementDetailsInterface | null;
}

const SimInformationTable: React.FC<Props> = ({ data }) => {
  const { palette } = useTheme();

  return (
    <>
      {data?.id ? (
        <DetailsTable>
          <TableRow>
            <TableCell>SIM Type</TableCell>
            <TableCell>
              <Box className="tw-capitalize">
                {data?.simInfo?.simType
                  ? camelCaseToFormattedString(data?.simInfo?.simType)
                  : ""}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Service Type</TableCell>
            <TableCell>
              <Box className="tw-capitalize">
                {data?.simInfo?.serviceType
                  ? camelCaseToFormattedString(data?.simInfo?.serviceType)
                  : ""}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Carton</TableCell>
            <TableCell>{data?.simInfo?.carton}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ICCID</TableCell>
            <TableCell>{data?.simInfo?.ICCID}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>MSISDN</TableCell>
            <TableCell>
              {data?.simInfo?.MSISDN ? (
                <Link
                  to={`${RouteConstant.MSISDN_MANAGEMENT}/${data?.simInfo?.MSISDN_id}`}
                >
                  <Box component="span" color="primary.main">
                    {data?.simInfo?.MSISDN}
                  </Box>
                </Link>
              ) : (
                <></>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Assigned Date</TableCell>
            <TableCell>
              {data?.simInfo?.assignedDate
                ? moment(data?.simInfo?.assignedDate).format("Do MMM YY")
                : ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Is Reserved</TableCell>
            <TableCell>
              <Box
                component="span"
                color={
                  data?.simInfo?.isReserved
                    ? palette.success.main
                    : palette.secondary.main
                }
              >
                {data?.simInfo?.isReserved ? "Reserved" : "Available"}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              <IsActiveCell status={data?.simInfo?.status} />
            </TableCell>
          </TableRow>
        </DetailsTable>
      ) : (
        <></>
      )}
    </>
  );
};

export default SimInformationTable;
