import AgentsLayout from "component/agents";
import MainContainer from "parts/mainContainer";

const Agents = () => {
    return (
      <MainContainer>
        <AgentsLayout />
      </MainContainer>
    );
  };
  
  export default Agents;
  