import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import { TwoWayAuthModel } from "_models/data/manage-account/data.two-way-auth.model";
import { TwoWayAuthErrorModel } from "_models/error/manage-account/error.two-way-auth.model";
import Text from "component/_common/forms/text";
import React, { Fragment, useState } from "react";

interface InputListProps extends FormInputProps {
  name: "email";
}

const inputList: InputListProps[] = [
  {
    type: "email",
    name: "email",
    label: "Registered Email ID",
    placeholder: "Registered Email ID",
    minRows: 5,
  },
];

interface TwoWayAuthFormProps {
  isProgress?: boolean;
  state: TwoWayAuthModel;
  setState: React.Dispatch<React.SetStateAction<TwoWayAuthModel>>;
}

const TwoWayAuthForm: React.FC<TwoWayAuthFormProps> = ({
  isProgress,
  state,
  setState,
}) => {
  const [errors, setErrors] = useState<TwoWayAuthErrorModel>(
    new TwoWayAuthErrorModel()
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev: any) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
  };

  return (
    <>
      <Box
        component="form"
        name="manage_account_two_wau_auth_form"
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                autoComplete,
                disabled,
                options,
                multiple,
                minRows,
              },
              index
            ) => (
              <Fragment key={index}>
                <>
                  {type === "email" ? (
                    <Grid item xs={12}>
                      <Text
                        type={type}
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        autoComplete={autoComplete || "off"}
                        value={state[name as keyof TwoWayAuthModel]}
                        errorText={errors[name as keyof TwoWayAuthErrorModel]}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        disabled={disabled}
                        minRows={minRows}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              </Fragment>
            )
          )}

          <Grid item xs={12} mt={2}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TwoWayAuthForm;
