type AppStatusCodeType =
  | "0000"
  | "0100"
  | "0200"
  | "0300"
  | "0400"
  | "0500"
  | "0600"
  | "0700"
  | "0800"
  | "0900"
  | "1000";

export class AppStatusCode {
  // API result status response code
  static api_success: AppStatusCodeType = "0000";
  static api_created: AppStatusCodeType = "0100";
  static api_updated: AppStatusCodeType = "0200";
  static api_data_not_found: AppStatusCodeType = "0300";
  static api_invalid_input: AppStatusCodeType = "0400";
  static api_server_error: AppStatusCodeType = "0500";
  static api_empty_data: AppStatusCodeType = "0600";
  static api_duplicate: AppStatusCodeType = "0700";
  static api_not_authorized: AppStatusCodeType = "0800";
  static api_too_many_request: AppStatusCodeType = "0900";
  static api_failed: AppStatusCodeType = "1000";
}
