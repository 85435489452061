import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import NavbarLogo from "parts/navbar/navbarLogo";
import NavbarMenuItems from "../navbarMenuItems";

const SideNavbar = () => {
  return (
    <>
      <AppBar
        elevation={0}
        position="sticky"
        sx={{
          height: "calc(100dvh - 16px)",
          background: "#fff",
          width: 250,
          top: "8px",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.05)",
        }}
        className="tw-drop-shadow-xl tw-rounded-2xl"
      >
        <Box className="tw-p-3">
          <NavbarLogo />
        </Box>
        <Box
          py={2}
          className="tw-h-[100%] tw-flex tw-flex-col"
          sx={{
            overflow: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <NavbarMenuItems />
        </Box>
      </AppBar>
    </>
  );
};

export default SideNavbar;
