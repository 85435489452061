import TwoWayAuthLayout from "component/manage-account/two-way-auth";
import MainContainer from "parts/mainContainer";

const TwoWayAuth = () => {
  return (
    <MainContainer>
      <TwoWayAuthLayout />
    </MainContainer>
  );
};

export default TwoWayAuth;
