import { Box, Card, Typography } from "@mui/material";
import { AgentDetailsInterface } from "_interfaces/agent";
import { AssignedLocationProps } from "_interfaces/functions/http-requests/agents";
import { RegionalDistributorsTableRowsInterface } from "_interfaces/regional-distributors/regionalDistributorsTable";
import UserActivation from "component/_common/userActivation";
import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React from "react";

interface Props {
  DATA: AgentDetailsInterface,
  UserId: string | null,
}

const AgentDetailsTable: React.FC<Props> = ({ DATA, UserId }) => {
  const rows: {
    field: keyof NonNullable<AgentDetailsInterface>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
      { field: "name", headerName: "Agent Name" },
      { field: "email", headerName: "Email" },
      { field: "phone", headerName: "Phone" },
      { field: "alternativePhoneNumber", headerName: "Alternative Phone" },
      { field: "role", headerName: "Role" },
      { field: "county", headerName: "County" },
      { field: "msisdn", headerName: "MSISDN" },
      { field: "preferredLanguage", headerName: "Language" },
      { field: "subCounty", headerName: "Sub County" },
      { field: "type", headerName: "Type" },
      { field: "assignedLocation", headerName: "Regions", renderCell: (v: AssignedLocationProps[]) => (v[0]?.region), },
      { field: "assignedLocation", headerName: "Districts", renderCell: (v: AssignedLocationProps[]) => (v[0]?.districts?.map(val => val)), },
      { field: "date", headerName: "Created On", renderCell: (v: string) => moment(v).format("Do MMM, YYYY"), },
      { field: "isActive", headerName: "Status", renderCell: (v: string) => (<p>{v ? "Active" : "InActive"}</p>), },
      { field: "isApprove", headerName: "Approve Status", renderCell: (v: string) => (<p>{v ? "Approved" : "NotApproved"}</p>), },
    ];

  return (
    <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
      <Box className="tw-flex tw-justify-between tw-items-center">
        <Typography color="text.primary" className="tw-text-[18px]" sx={{ fontWeight: "bold" }}>
          {"Agent Details"}
        </Typography>
        {UserId && <UserActivation ID={UserId} />}
      </Box>
      <DetailsBox
        data={DATA}
        rows={rows}
      />
    </Card>
  )
}

export default AgentDetailsTable;
