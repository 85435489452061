import HandlersLayout from "component/handler";
import MainContainer from "parts/mainContainer";

const Handlers = () => {
    return (
      <MainContainer>
        <HandlersLayout />
      </MainContainer>
    );
  };
  
  export default Handlers;
  