import ManageAccountLayout from "component/manage-account";
import MainContainer from "parts/mainContainer";

const ManageAccount = () => {
  return (
    <MainContainer>
      <ManageAccountLayout />
    </MainContainer>
  );
};

export default ManageAccount;
