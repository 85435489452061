import ArrowBack from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

interface Props {
  onClick?: () => void;
}

const NavigateBackButton: React.FC<Props> = ({ onClick }) => {
  const navigate = useNavigate();

  // if (!location.key || location.key === "default") return <Box />;

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => (onClick ? onClick() : navigate(-1))}
        className="tw-normal-case"
        startIcon={<ArrowBack />}
      >
        Go to back
      </Button>
    </>
  );
};

export default NavigateBackButton;
