interface InventoryUsersFilterModelProps {
  isActive?: string;
}

const defaultInventoryUsersFilterModelProps: InventoryUsersFilterModelProps = {
  isActive: "",
};

export class InventoryUsersFilterModel {
  isActive?: string;

  constructor(
    data: InventoryUsersFilterModelProps = defaultInventoryUsersFilterModelProps
  ) {
    this.isActive = data.isActive || "";
  }
}
