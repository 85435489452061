import { IAddBalance, IBlockWallet } from "_interfaces/inter-switch-e-value/InterSwitchEValueCard";
import { ADD_BALANCE_INTER_SWITCH_E_VALUE, BLOCK_UNBLOCK_INTER_SWITCH_E_VALUE, GET_INTER_SWITCH_E_VALUE } from "config/endpoint";
import { HTTP } from "functions/http";

export const GetInterSwitchEValue = () => {
    return HTTP({
      Method: "GET",
      Url: GET_INTER_SWITCH_E_VALUE,
    });
  };
export const ADDBALANCEINTERSWITCHEVALUE = ({ DATA }: IAddBalance) => {
    return HTTP({
      Method: "POST",
      Url: ADD_BALANCE_INTER_SWITCH_E_VALUE,
      Data: DATA,
    });
  };
export const BLOCKUNBLOCKINTERSWITCHEVALUE = ({ DATA }: IBlockWallet) => {
    return HTTP({
      Method: "POST",
      Url: BLOCK_UNBLOCK_INTER_SWITCH_E_VALUE,
      Data: DATA,
    });
  };