import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import Text from "component/_common/forms/text";
import { ADDBALANCEINTERSWITCHEVALUE } from "functions/http-requests/inter-switch-e-value";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";

interface IAddBalanceDialog {
    open: boolean;
    onClose: () => void
    fetchAllData: () => void
}

const AddBalanceDialog: React.FC<IAddBalanceDialog> = ({ open, onClose, fetchAllData }) => {
    const [amount, setAmount] = useState<number>(0);
    const Dispatch = useDispatch();
    const [errorText, setErrorText] = useState<string | null>(null)

    const handleError = (amount: number) => {
        let err;
        if (amount > 1000000) {
            err = "Please provide valid amount"
            setErrorText(err);
            return err;
        }

        if (!amount) {
            err = "Amount required";
            setErrorText(err);
            return err;
        }

        setErrorText(null);
        return null;
    }

    const handleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(parseInt(e.target.value));
    }

    const ADDAMOUNT = () => {
        const err = handleError(amount)
        if (err) return;

        Dispatch(toggleLoading(true));
        ADDBALANCEINTERSWITCHEVALUE({ DATA: { amount: amount } })
            .then(res => {
                if (res.data.level !== "success") {
                    Dispatch(setAlert({ type: "error", message: res.data.message }));
                    return;
                }
                Dispatch(setAlert({ type: "success", message: res.data.message }))
            })
            .catch(err => Dispatch(setAlert({ type: "error", message: err.response.data.message }))).
            finally(() =>
                Dispatch(toggleLoading(false)));
        setAmount(0);
        fetchAllData();
        onClose();
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Please Provide Amount To Add Balance"}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        type="number"
                        placeholder="Enter the amount"
                        onChange={handleAmount}
                        sx={{ width: "22.2rem", }}
                        error={errorText ? true : false}
                        helperText={
                            errorText ? (
                                <Box
                                    component="span"
                                    dangerouslySetInnerHTML={{ __html: errorText }}
                                />
                            ) : (
                                <></>
                            )
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setErrorText(null);
                        onClose();
                    }}>Cancel</Button>
                    <Button onClick={ADDAMOUNT} autoFocus>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddBalanceDialog;