import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import InputCheckbox from "component/_common/forms/inputCheckbox";
import Password from "component/_common/forms/password";
import Text from "component/_common/forms/text";
import { getClientTimeZone, getLocation } from "functions/helper";
import { HTTP_ERROR } from "functions/http";
import { RouteConstant } from "navigation/constant";
import SectionTitle from "parts/sectionTitle";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setAlert } from "state/reducers/alert";
import { LoginFormProps } from "_interfaces/auth";
import { FormInputProps } from "_interfaces/_common/forms";
import { LoginModel } from "_models/data/auth/data.login.model";
import { LoginErrorModel } from "_models/error/auth/error.login.model";
import { validateLogin } from "./formValidator";

interface InputListProps extends FormInputProps {
  name: "loginAs" | "phone" | "password" | "stayLogin";
}

const inputList: InputListProps[] = [
  // {
  //   type: "autoComplete",
  //   name: "loginAs",
  //   label: "Login as",
  //   placeholder: "Login as",
  //   options: [
  //     { id: "agent", title: "Agent", value: "agent", isActive: true },
  //     {
  //       id: "regionalDistributor",
  //       title: "Regional Distributor",
  //       value: "regionalDistributor",
  //       isActive: true,
  //     },
  //   ],
  //   multiple: false,
  // },
  {
    type: "number",
    name: "phone",
    label: "Phone",
    placeholder: "Enter Phone",
  },
  {
    type: "password",
    name: "password",
    label: "Password",
    placeholder: "Enter Password",
  },
  {
    type: "checkbox",
    name: "stayLogin",
    label: "Remember me?",
  },
];

const LoginForm: React.FC<LoginFormProps> = ({
  handleLogin,
  isProgress,
  state,
  setState,
}) => {
  const Dispatch = useDispatch();

  const [errors, setErrors] = useState<LoginErrorModel>(new LoginErrorModel());

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateLogin(state);

    if (!ValidateStep?.valid) {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
      return;
    }

    if (!state.location) {
      getLocation()
        .then((res: GeolocationPosition) => {
          if (res.coords.latitude && res.coords.longitude) {
            let DATA = { ...state };
            DATA.location = `${res.coords.latitude},${res.coords.longitude}`;
            handleLogin(DATA);
          }
        })
        .catch((er) => {
          Dispatch(
            setAlert({
              type: "error",
              message: `${HTTP_ERROR(er)}. Location is must to Login.`,
            })
          );
        });
    } else handleLogin(state);
  };

  useEffect(() => {
    getLocation()
      .then((res: GeolocationPosition) => {
        if (res.coords.latitude && res.coords.longitude) {
          setState((prev) => ({
            ...prev,
            location: `${res.coords.latitude},${res.coords.longitude}`,
          }));
        }
      })
      .catch((er) => {
        Dispatch(
          setAlert({
            type: "error",
            message: `${HTTP_ERROR(er)}. Location is must to Login.`,
          })
        );
      });
  }, []);

  useEffect(() => {
    if (navigator && navigator.userAgent) {
      setState((prev) => ({ ...prev, userAgent: navigator.userAgent }));
    }
  }, []);

  useEffect(() => {
    const timeZone = getClientTimeZone();
    if (timeZone) setState((prev) => ({ ...prev, timeZone }));
  }, []);

  return (
    <>
      <SectionTitle title="Login" fontSize={36} color="white" />
      <Typography>Welcome to Talkio</Typography>

      <Box
        pt={5}
        sx={{
          color: ({ palette }) => alpha(palette.common.white, 0.6),
          "input, .MuiIconButton-root": {
            color: ({ palette }) => alpha(palette.common.white, 0.6),
          },
        }}
      >
        <Box component="form" name="auth_login_form" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {inputList.map(
              (
                {
                  type,
                  name,
                  label,
                  placeholder,
                  autoComplete,
                  disabled,
                  options,
                  multiple,
                },
                index
              ) => (
                <Fragment key={index}>
                  <>
                    {type === "number" ? (
                      <Grid item xs={12}>
                        <Text
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof LoginModel]}
                          errorText={errors[name as keyof LoginErrorModel]}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          disabled={disabled}
                        />
                      </Grid>
                    ) : type === "password" ? (
                      <Grid item xs={12}>
                        <Password
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof LoginModel]}
                          errorText={errors[name as keyof LoginErrorModel]}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          disabled={disabled}
                          isHideShow
                        />
                      </Grid>
                    ) : type === "autoComplete" ? (
                      <Grid item xs={12}>
                        <InputAutoComplete
                          name={name}
                          options={options || []}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options.length &&
                            state?.[name as keyof LoginModel]
                          }
                          errorText={errors[name as keyof LoginErrorModel]}
                          onFocus={handleFocus}
                          multiple={multiple}
                        />
                      </Grid>
                    ) : type === "checkbox" && name === "stayLogin" ? (
                      <Grid item xs={12}>
                        <Box className="tw-flex tw-justify-between">
                          <InputCheckbox
                            name={name}
                            label={label}
                            value={state?.stayLogin}
                            onChange={handleChange}
                          />

                          <Typography className="tw-flex tw-items-center">
                            <Link to={RouteConstant.FORGOT_PASSWORD}>
                              Forgot Password ?
                            </Link>
                          </Typography>
                        </Box>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                </Fragment>
              )
            )}

            <Grid item xs={12} mt={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                className="tw-capitalize tw-py-[12px]"
              >
                {isProgress ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Login"
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default LoginForm;
