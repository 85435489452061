import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { ForgotPasswordFormProps } from "_interfaces/auth";
import { FormInputProps } from "_interfaces/_common/forms";
import { ForgotPasswordModel } from "_models/data/auth/data.forgot-password.model";
import { ForgotPasswordErrorModel } from "_models/error/auth/error.forgot-password.model";
import Password from "component/_common/forms/password";
import Text from "component/_common/forms/text";
import { RouteConstant } from "navigation/constant";
import SectionTitle from "parts/sectionTitle";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { validateForgotPassword } from "./formValidator";

interface InputListProps extends FormInputProps {
  name: "phone";
  link?: string;
}

const inputList: InputListProps[] = [
  {
    type: "number",
    name: "phone",
    label: "Phone",
    placeholder: "Enter Phone",
  },
];

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  isProgress,
  handleChangePasswordRequest,
  state,
  setState,
}) => {
  const [errors, setErrors] = useState<ForgotPasswordErrorModel>(
    new ForgotPasswordErrorModel()
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateForgotPassword(state);

    if (ValidateStep?.valid) {
      handleChangePasswordRequest();
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  return (
    <>
      <SectionTitle title="Forgot Password" fontSize={36} color="white" />
      <Typography className="tw-text-[14px] tw-font-[400] tw-mt-[10px]">
        Don’t worry we’ll get your covered, Request for changing password
      </Typography>

      <Box
        pt={5}
        sx={{
          color: ({ palette }) => alpha(palette.common.white, 0.6),
          "input, .MuiIconButton-root": {
            color: ({ palette }) => alpha(palette.common.white, 0.6),
          },
        }}
      >
        <Box
          component="form"
          name="auth_forgot_password_form"
          onSubmit={handleSubmit}
        >
          <Grid container spacing={3}>
            {inputList.map(
              (
                {
                  type,
                  name,
                  label,
                  placeholder,
                  autoComplete,
                  disabled,
                  link,
                },
                index
              ) => (
                <Fragment key={index}>
                  <>
                    {type === "text" ||
                    type === "email" ||
                    type === "number" ? (
                      <Grid item xs={12}>
                        <Text
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof ForgotPasswordModel]}
                          errorText={
                            errors[name as keyof ForgotPasswordErrorModel]
                          }
                          onChange={handleChange}
                          onFocus={handleFocus}
                          disabled={disabled}
                        />
                      </Grid>
                    ) : type === "password" ? (
                      <Grid item xs={12}>
                        <Password
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof ForgotPasswordModel]}
                          errorText={
                            errors[name as keyof ForgotPasswordErrorModel]
                          }
                          onChange={handleChange}
                          onFocus={handleFocus}
                          disabled={disabled}
                          isHideShow
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                </Fragment>
              )
            )}

            <Grid item xs={12} mt={2}>
              <Box color="black">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disableElevation
                  className="tw-capitalize tw-py-[12px]"
                >
                  {isProgress ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box pt={5} className="tw-text-center">
            <Link to={RouteConstant.LOG_IN}>
              <Button variant="text">Back To Login</Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPasswordForm;
