import {
  CreateInventoryUsersProps,
  GetInventoryUsersProps,
  InventoryUserActivationProps,
  UpdateInventoryUsersProps,
} from "_interfaces/functions/http-requests/inventory-users";
import {
  CREATE_INVENTORY_USERS,
  GET_INVENTORY_USERS,
  GET_INVENTORY_USERS_BY_ID,
  INVENTORY_USER_ACTIVATION,
  UPDATE_INVENTORY_USERS,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const CreateInventoryUsers = ({ DATA }: CreateInventoryUsersProps) => {
  return HTTP({
    Method: "POST",
    Url: CREATE_INVENTORY_USERS,
    Data: DATA,
  });
};

export const UpdateInventoryUsers = ({ DATA }: UpdateInventoryUsersProps) => {
  return HTTP({
    Method: "POST",
    Url: UPDATE_INVENTORY_USERS,
    Data: DATA,
  });
};

export const GetInventoryUsers = ({ DATA }: GetInventoryUsersProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_INVENTORY_USERS,
    Data: DATA,
  });
};

export const GetInventoryUsersById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_INVENTORY_USERS_BY_ID(ID),
  });
};

export const InventoryUserActivation = ({
  DATA,
}: InventoryUserActivationProps) => {
  return HTTP({
    Method: "POST",
    Url: INVENTORY_USER_ACTIVATION,
    Data: DATA,
  });
};
