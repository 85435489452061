import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import StatisticsCard from "component/_common/cards/statisticsCard";
import { RouteConstant } from "navigation/constant";
import CustomBarChart from "parts/charts/barChart";
import SimChart from "parts/charts/simChart";
import { Link } from "react-router-dom";
import TopStatistics from "./topStatistics";
import { AppStatusCode } from "config/appStatusCode";
import { GetSimStockCount } from "functions/http-requests/simManagement";
import { toggleLoading } from "state/reducers/loading";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SimStockCountInterface } from "_interfaces/sim-management/details";

const AnalyticsLayout = () => {
  const [simStatusCount, setSimStatusCount] = useState<SimStockCountInterface>({
    registerCount: 0,
    distributorCount: 0,
    inStockCount: 0
  });
  const Dispatch = useDispatch();
  const { palette } = useTheme();

  const fetchSimCount = () => {
    Dispatch(toggleLoading(true));
    GetSimStockCount()
      .then((res) => {
        if (res?.data?.statusCode === AppStatusCode.api_success) {
          const DATA = res?.data?.data;
          setSimStatusCount(DATA[0])
        }
      })
      .catch(() => { })
      .finally(() => Dispatch(toggleLoading(false)));
  }

  useEffect(() => {
    fetchSimCount();
  }, [])

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {/* <SectionSearch /> */}
          </Grid>
          <Grid item xs={12} sm={6} className="tw-flex tw-justify-end">
            <Link to={RouteConstant.ANALYTICS_REPORT}>
              <Button variant="contained">Download Report</Button>
            </Link>
          </Grid>
        </Grid>
      </Box>

      <Box pt={3}>
        <TopStatistics />
      </Box>
      <Box pt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <StatisticsCard statisticsValue="400" title="Total Agents" />
            </Box>
            <Box pt={2}>
              <StatisticsCard
                statisticsValue="1300"
                title="Total Distributors"
              />
            </Box>
            <Box pt={2}>
              <StatisticsCard statisticsValue="40+" title="Total Region" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4.5}>
            <Box
              p={2}
              className="tw-border tw-rounded-md tw-h-[100%] tw-flex tw-flex-col tw-justify-between"
            >
              <Box className="tw-flex tw-justify-end">
                <Box
                  sx={{
                    position: "relative",
                    paddingBottom: "8px",
                    "&::before": {
                      content: `""`,
                      position: "absolute",
                      left: 0,
                      bottom: 0,
                      width: "30px",
                      height: "6px",
                      borderRadius: "20px",
                      backgroundColor: palette.primary.main,
                    },
                  }}
                >
                  <Typography fontSize={14}>Available Stock</Typography>
                </Box>
              </Box>
              <Box className="tw-h-[250px]">
                <CustomBarChart />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4.5}>
            <SimChart DATA={simStatusCount} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AnalyticsLayout;
