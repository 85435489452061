import { RegionalDistributorsModel } from "_models/data/regional-distributors/data.regional-distributors.model";
import {
  isValidEmail,
  isValidPassword,
  isValidPhone,
} from "functions/validators";

export const validateRegionalDistributor = (
  DATA: RegionalDistributorsModel,
  editId?: string | number | null
) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.name) {
    valid = false;
    errors.push({
      name: "name",
      error: "Distributor name is required.",
    });
  }

  if (!DATA?.email) {
    valid = false;
    errors.push({
      name: "email",
      error: "Distributor email is required.",
    });
  }

  if (DATA?.email && !isValidEmail(DATA?.email)) {
    valid = false;
    errors.push({
      name: "email",
      error: "Invalid email.",
    });
  }

  if (!DATA?.phone) {
    valid = false;
    errors.push({
      name: "phone",
      error: "Distributor phone is required.",
    });
  }

  if (DATA?.phone && !isValidPhone(DATA?.phone)) {
    valid = false;
    errors.push({
      name: "phone",
      error: "Invalid Phone.",
    });
  }

  if (!DATA?.assignedLocation?.length) {
    valid = false;
    errors.push({
      name: "assignedLocation",
      error: "Region & Districts are required.",
    });
  }

  if (DATA?.assignedLocation?.length) {
    if (
      !DATA?.assignedLocation?.length ||
      !DATA.assignedLocation[0]?.region ||
      !DATA.assignedLocation[0]?.districts?.length
    ) {
      valid = false;
      errors.push({
        name: "assignedLocation",
        error: "Select atleast one region & one district.",
      });
    }
  }

  if (!editId) {
    if (!DATA?.password) {
      valid = false;
      errors.push({
        name: "password",
        error: "Password is required.",
      });
    }

    if (!DATA?.confirmPassword) {
      valid = false;
      errors.push({
        name: "confirmPassword",
        error: "Enter password again",
      });
    }
  }

  if (DATA?.password && !isValidPassword(DATA.password)) {
    valid = false;
    errors.push({
      name: "password",
      error:
        "Password must contain a minimum of eight letters containing at least one small letter, capital letter, number and special character.",
    });
  }

  if (DATA?.password && !DATA?.confirmPassword) {
    valid = false;
    errors.push({
      name: "confirmPassword",
      error: "Enter password again",
    });
  }

  if (DATA?.confirmPassword && DATA.password !== DATA.confirmPassword) {
    valid = false;
    errors.push({
      name: "confirmPassword",
      error: "Password not matched.",
    });
  }

  return { valid, errors };
};
