import { Grid } from "@mui/material";
import { DistributorDetailsAnalytics } from "_interfaces/regional-distributors/details/regionalDistributorDetailsTable";
import StatisticsCard from "component/_common/cards/statisticsCard";

interface Props {
    Data: DistributorDetailsAnalytics[]
}

const DistributorAnalytics: React.FC<Props> = ({ Data }) => {
    const analyticsData: DistributorDetailsAnalytics = Data[0];

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <StatisticsCard
                        statisticsValue={analyticsData?.allTimeCount[0]?.totalCount || 0}
                        title="Total Registration"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <StatisticsCard
                        statisticsValue={analyticsData?.todayCount[0]?.totalCount || 0}
                        title="Today Registration"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <StatisticsCard
                        statisticsValue={analyticsData?.thisWeekCount[0]?.totalCount || 0}
                        title="Week Registration"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <StatisticsCard
                        statisticsValue={analyticsData?.thisMonthCount[0]?.totalCount || 0}
                        title="Month Registration"
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default DistributorAnalytics;