import {
  AuthCheckProps,
  AuthLogOutProps,
  ChangePasswordProps,
  ChangePasswordRequestProps,
  LoginOtpVerificationProps,
  LoginProps,
  ResendLoginOtpProps,
  ResendPasswordRequestOtpProps,
  VerifyOtpPasswordRequestProps,
} from "_interfaces/functions/http-requests/auth";
import axios from "axios";
import {
  AUTH_CHECK,
  AUTH_LOGIN,
  AUTH_LOGIN_OTP_VERIFICATION,
  AUTH_LOGOUT,
  AUTH_RESEND_LOGIN_OTP,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_REQUEST,
  GET_IP_INFO,
  RESEND_PASSWORD_REQUEST_OTP,
  VERIFY_OTP_PASSWORD_REQUEST,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const GetIpInfo = () => {
  return axios({
    method: "get",
    url: GET_IP_INFO,
  });
};

export const AuthLogin = ({ DATA }: LoginProps) => {
  return HTTP({
    Method: "POST",
    Url: AUTH_LOGIN,
    Data: DATA,
  });
};

export const AuthLoginOtpVerification = ({
  DATA,
}: LoginOtpVerificationProps) => {
  return HTTP({
    Method: "POST",
    Url: AUTH_LOGIN_OTP_VERIFICATION,
    Data: DATA,
  });
};

export const AuthResendLoginOtp = ({ DATA }: ResendLoginOtpProps) => {
  return HTTP({
    Method: "POST",
    Url: AUTH_RESEND_LOGIN_OTP,
    Data: DATA,
  });
};

export const AuthCheck = ({ DATA }: AuthCheckProps) => {
  return HTTP({
    Method: "POST",
    Url: AUTH_CHECK,
    Data: DATA,
  });
};

export const AuthLogOut = ({ DATA }: AuthLogOutProps) => {
  return HTTP({
    Method: "POST",
    Url: AUTH_LOGOUT,
    Data: DATA,
  });
};

export const ChangePasswordRequest = ({ DATA }: ChangePasswordRequestProps) => {
  return HTTP({
    Method: "POST",
    Url: CHANGE_PASSWORD_REQUEST,
    Data: DATA,
  });
};

export const ChangePassword = ({ DATA }: ChangePasswordProps) => {
  return HTTP({
    Method: "POST",
    Url: CHANGE_PASSWORD,
    Data: DATA,
  });
};

export const VerifyOtpPasswordRequest = ({
  DATA,
}: VerifyOtpPasswordRequestProps) => {
  return HTTP({
    Method: "POST",
    Url: VERIFY_OTP_PASSWORD_REQUEST,
    Data: DATA,
  });
};

export const ResendPasswordRequestOtp = ({
  DATA,
}: ResendPasswordRequestOtpProps) => {
  return HTTP({
    Method: "POST",
    Url: RESEND_PASSWORD_REQUEST_OTP,
    Data: DATA,
  });
};
