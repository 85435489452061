import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import { useTheme } from "@mui/material/styles";
import {
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment, { Moment } from "moment";
import React from "react";

interface Props {
  type?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  value?: any;
  errorText?: string;
  onChange?:
    | ((
        value: moment.Moment | null,
        context: PickerChangeHandlerContext<DateValidationError>
      ) => void)
    | undefined;
}

const DatePicker: React.FC<Props> = ({
  name,
  label,
  value,
  placeholder,
  errorText,
  onChange,
}) => {
  const { palette } = useTheme();

  return (
    <>
      {label ? (
        <FormLabel
          htmlFor={name}
          className="tw-mb-[6px] tw-block tw-text-[14px]"
          sx={{ color: palette.secondary.main }}
        >
          {label}
        </FormLabel>
      ) : (
        <></>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobileDatePicker
          sx={{ width: "100%" }}
          // label={label}
          value={value ? moment(value) : null}
          format="Do MMM YYYY"
          onChange={onChange}
        />
      </LocalizationProvider>
      {errorText ? (
        <FormHelperText
          className="tw-mt-[3px] tw-mx-[14px]"
          sx={{ color: palette.error.main }}
        >
          {errorText}
        </FormHelperText>
      ) : (
        <></>
      )}
    </>
  );
};

export default DatePicker;
