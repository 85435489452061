import { CreateAppVersionModel } from "_models/data/apps/data.apps.model";


export const validateAppVersion = (
  DATA: CreateAppVersionModel,
) => {
  let valid = true;
  let errors = [];

  if (!DATA.currentVersion) {
    valid = false;
    errors.push({
      name: "currentVersion",
      error: "Current Version is required.",
    });
  }

  if (!DATA.releaseDate) {
    console.log("date ", DATA.releaseDate)
    valid = false;
    errors.push({
      name: "releaseDate",
      error: "Release Date is required.",
    });
  }

  if (DATA?.isUpdateMandatory === undefined || DATA?.isUpdateMandatory === null) {
    valid = false;
    errors.push({
      name: "isUpdateMandatory",
      error: "Update Mandatory is required.",
    });
  }

  if (!DATA?.appName) {
    valid = false;
    errors.push({
      name: "appName",
      error: "App name is required.",
    });
  }

  return { valid, errors };
};
