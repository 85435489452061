import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import useScrollToTop from "functions/hooks/useScrollToTop";
import CustomAlert from "parts/customAlert";
import { useSelector } from "react-redux";
import { Routes } from "react-router-dom";
import { RootState } from "state/store";
import { AllRoutes } from "./routes";
import RoutingFunction from "./routingFunction";

const Navigation = () => {
  useScrollToTop();

  const { isLoading } = useSelector((state: RootState) => state.loading);
  const alert = useSelector((state: RootState) => state.alert);

  return (
    <>
      <Routes>
        {AllRoutes.map((route, index) => RoutingFunction(route, index))}
      </Routes>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 99999 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {alert?.showAlert ? <CustomAlert /> : <></>}
    </>
  );
};

export default Navigation;
